<template>
    <div class="clarity-card-content">
        <div class="body">
            <div class="content-title">
                <slot name="title"></slot>
            </div>
            <div class="content-full">
                <slot name="content-full"></slot>
            </div>
        </div>
        <div class="bottom-slot">
            <slot name="bottom"></slot>
        </div>
    </div>
</template>
<style scoped>
.clarity-card-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: max-content;
    border-radius: 27px;
    box-shadow: 0px 5px 20px #0000000d;
    background: var(--clarity-light-grey);
}
.body {
    border-radius: 27px;
    background: white;
}
.content-full {
    display: flex;
    flex-direction: column;
    padding: 30px;
}
.bottom-slot {
    display: flex;
    border-bottom-left-radius: 27px;
    border-bottom-right-radius: 27px;
}
.content-title:deep(h2) {
  color: var(--clarity-blue);
}
@media only screen and (max-width: 768px)  {
    .content-full {
        padding: 18px;
    }
}
</style>
