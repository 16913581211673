import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

export default {
  state() {
    return {
      assetAllocation: null,
      allocationSummary: null,
      equityBreakdown: null,
      fullAllocation: null,
      assetAllocationTotals: null,
      missingFunds: null
    };
  },
  mutations,
  actions,
  getters,
};
