export default {
    // is this one redundant
    fullAllocation (state) {
        return state.fullAllocation
    },
    totalAllocation (state) {
        return state.totalAllocation
    },
    assetAllocation (state) {
        return state.assetAllocation
    },
    allocationSummary (state) {
        return state.allocationSummary
    },
    equityBreakdown (state) {
        return state.equityBreakdown
    },
    assetAllocationTotals (state) {
        return state.assetAllocationTotals
    },
}
