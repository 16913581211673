import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default {
    state() {
        return {
            dashboardChartData: [],
            dashboardAssets: 0.00,
            dashboardLiabilities: 0.00,
            dashboardSelfManaged: 0.00,
            dashboardClarityAssets: 0.00,
            dashboardBankAccounts: 0.00,
            isSubscribed: null
        }
    },
    mutations,
    actions,
    getters
}
