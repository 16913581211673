import mutations from './mutations'
import actions from './actions'
import getters from './getters'
import dayjs from "dayjs";

export default {
    state() {
        return {
            mailboxLoading: true,
            mailboxType: null,
            messages: [],
            newMessagesLoading: true,
            newMessages: [],
            sentMessages: [],
            messageMeta: {},
            messageCategoryOptions: [
                {
                    label: 'Correspondence'
                },
                {
                    label: 'Meetings'
                },
                {
                    label: 'Annual Review'
                },
                {
                    label: 'IMS'
                },
                {
                    label: 'Feedback'
                },
                {
                    label: 'Investments'
                }
            ],
            dateOptions: [
                {
                    label: 'All'
                },
                {
                    label: 'Last Week'
                },
                {
                    label: 'Last Month'
                },
                {
                    label: 'Last Year'
                },
                {
                    label: 'Select Date Range'
                }
            ],
            senderOptions: {
                inbound: [],
                outbound: []
            },
            mailDateRangeFrom: dayjs().subtract(1, 'year').toDate(),
            mailDateRangeTo: dayjs().toDate(),
            correspondentFilter: [],
            categoryFilter: [],
            currentMessage: null,
            recipientOptions: [
                {
                    name: "Admin Team",
                    uuid: "c_admin",
                    email: "adminteam@clarityglobal.com",
                    image: "https://i.pravatar.cc/300"
                },
            ],
            additionalRecipients: [],
            recipientList: [],
            senderList: [],
            globalTriggerMessage: {},
            messageRecovery: false
        }
    },
    mutations,
    actions,
    getters
}
