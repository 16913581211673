import getters from "./getters"
import mutations from "./mutations";
import actions from "./actions";

export default {
    state() {
        return {
           wealthplan: {
               notes: null
           }
        }
    },
    mutations,
    actions,
    getters
}