export default class NewPerformanceCalc {
    constructor({
                    performance,
                    openingBalance,
                    closingBalance,
                    moneyIn,
                    moneyOut
                }) {
        this.performance = performance;

        // Scaling factor to ensure goalseek works
        this.scalingFactor = 1.0;

        this.openingBalance = openingBalance;
        this.closingBalance = closingBalance;

        // Monthly performance introduced in revised calc
        this.timeframePerformance = 0;

        this.moneyIn = moneyIn;
        this.moneyOut = moneyOut;
        this.calcLength = Object.keys(this.moneyIn).length;

    }

    getWeighted(array) {
        let weighted = [];
        for (let i = 0; i <= this.calcLength - 1; i++) {
            let adjusted_idx = i + 1
            // console.log('i => ', adjusted_idx, 'cl =>', this.calcLength - adjusted_idx)
            weighted.push(parseFloat((array[i] * ((this.calcLength - adjusted_idx) / this.calcLength)).toFixed(2)))
        }

        return weighted.reduce((previousValue, currentValue) => previousValue + currentValue, 0.00);
    }

    performanceValue() {
        //    calc top line
        let moniesOut = this.closingBalance + this.moneyOut.reduce((previousValue, currentValue) => previousValue + currentValue, 0.00);
        let moniesIn = this.openingBalance + this.moneyIn.reduce((previousValue, currentValue) => previousValue + currentValue, 0.00);
        let topLine = moniesOut - moniesIn

        //    calc weighted values
        let weightedIn = this.getWeighted(this.moneyIn)
        let weightedOut = this.getWeighted(this.moneyOut)

        //    calc bottom line
        let bottomLine = (this.openingBalance + weightedIn) - weightedOut
        //    total mwrr =  topline/bottomline
        let total_mwrr = (topLine/bottomLine)

        //    annualised irr = divide by number of years
        let years = this.calcLength/12
        let finalValue = Math.pow( (total_mwrr+1), (1/years) ) -1

        return parseFloat((finalValue*100).toFixed(2))
    }
}