import { createApp, reactive } from 'vue'
import {formatter} from "@/core/services/formatter"
import numberFormatter from '@/core/plugins/numberFormatter'
import caseFormatter from '@/core/plugins/caseFormatter'
import dateFormatter from './core/plugins/dateFormatter'
import currencyFormatter from '@/core/plugins/currencyFormatter'
import App from './App.vue'
import router from './router'
import store from './store'
import bus from '@/core/services/bus'
import PrimeVue from 'primevue/config';
import Card from 'primevue/card';

import AccordionTab from 'primevue/accordiontab';
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';
import BadgeDirective from 'primevue/badgedirective';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import Carousel from 'primevue/carousel';
import Checkbox from 'primevue/checkbox';
import Chip from 'primevue/chip';
import Divider from 'primevue/divider';
import FileUpload from 'primevue/fileupload';
import Knob from 'primevue/knob';
import Sidebar from 'primevue/sidebar';
import StyleClass from 'primevue/styleclass';
import TabMenu from 'primevue/tabmenu';
import Tag from 'primevue/tag';

import AutoComplete from 'primevue/autocomplete';
import Accordion from 'primevue/accordion';
import BlockUI from "primevue/blockui";
import Chips from 'primevue/chips';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import ContextMenu from 'primevue/contextmenu';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import Fieldset from 'primevue/fieldset';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import Listbox from 'primevue/listbox';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import OverlayPanel from 'primevue/overlaypanel';
import Panel from 'primevue/panel';
import Password from 'primevue/password';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import Rating from 'primevue/rating';
import RadioButton from 'primevue/radiobutton';
import Ripple from 'primevue/ripple';
import Row from 'primevue/row';
import ScrollPanel from 'primevue/scrollpanel';
import SelectButton from 'primevue/selectbutton';
import Slider from 'primevue/slider';
import Skeleton from 'primevue/skeleton';
import SplitButton from 'primevue/splitbutton';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import TieredMenu from 'primevue/tieredmenu';
import Timeline from 'primevue/timeline';
import ToggleButton from 'primevue/togglebutton';
import Tooltip from 'primevue/tooltip';
import InlineMessage from 'primevue/inlinemessage';


import Loading from './components/common/Loading';

import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './assets/theme/theme.css';
import './assets/scss/main.scss';
// Import Clarity Global Styles CSS
import './assets/scss/clarity-global-colours.scss';
import './assets/scss/clarity-global-styles.scss';
import './assets/scss/clarity-global-buttons.scss';
import './assets/scss/clarity-global-icons.scss';
// Import mixins and responsiveness
import './assets/scss/mixins.scss'
// PrimeVue Component Overrides
import './assets/scss/overrides.scss';

import { VueReCaptcha } from 'vue-recaptcha-v3';

import * as Sentry from "@sentry/vue";

const setup = async () => {
    const app = createApp(App);

    formatter.setConfig({
        locale: 'en-GB',
    })
    app.provide('bus', bus)
    app.provide('formatter', formatter)

    app.use(store);
    app.use(router);
    app.use(numberFormatter)
    app.use(caseFormatter)
    app.use(dateFormatter)
    app.use(currencyFormatter)
    app.use(PrimeVue, { ripple: true });
    app.use(ConfirmationService);
    app.use(ToastService);
    // CWI-2809 New reCaptcha Keys
    // app.use(VueReCaptcha, { siteKey:'6LdZr0AlAAAAAMNd_6TZUWKxnaGbww-hC3cWKoyG',loaderOptions: {autoHideBadge:true} });
    app.use(VueReCaptcha, { siteKey:'6LdGM4kpAAAAAFetig1T1xGsX1fcYlN5dPDhB0aa',loaderOptions: {autoHideBadge:true} });

    app.config.globalProperties.$appState = reactive({ theme: 'lara-light-indigo', dark: false });

    app.component('Accordion', Accordion);
    app.component('AccordionTab', AccordionTab);
    app.component('AutoComplete', AutoComplete);
    app.component('Avatar', Avatar);
    app.component('AvatarGroup', AvatarGroup);
    app.component('Button', Button);
    app.component('BlockUI', BlockUI);
    app.component('Calendar', Calendar);
    app.component('Carousel', Carousel);
    app.component('Checkbox', Checkbox);
    app.component('Chip', Chip);
    app.component('Chips', Chips);
    app.component('Column', Column);
    app.component('ColumnGroup', ColumnGroup);
    app.component('ConfirmDialog', ConfirmDialog);
    app.component('ConfirmPopup', ConfirmPopup);
    app.component('ContextMenu', ContextMenu);
    app.component('DataTable', DataTable);
    app.component('Dialog', Dialog);
    app.component('Divider', Divider);
    app.component('Dropdown', Dropdown);
    app.component('FileUpload', FileUpload);
    app.component('Fieldset', Fieldset);
    app.component('InlineMessage', InlineMessage);
    app.component('InputNumber', InputNumber);
    app.component('InputSwitch', InputSwitch);
    app.component('InputText', InputText);
    app.component('Knob', Knob);
    app.component('Listbox', Listbox);
    app.component('Loading', Loading);
    app.component('Menu', Menu);
    app.component('Menubar', Menubar);
    app.component('Message', Message);
    app.component('MultiSelect', MultiSelect);
    app.component('OverlayPanel', OverlayPanel);
    app.component('Panel', Panel);
    app.component('Password', Password);
    app.component('ProgressBar', ProgressBar);
    app.component('ProgressSpinner', ProgressSpinner);
    app.component('RadioButton', RadioButton);
    app.component('Rating', Rating);
    app.component('Row', Row);
    app.component('ScrollPanel', ScrollPanel);
    app.component('SelectButton', SelectButton);
    app.component('Sidebar', Sidebar);
    app.component('Slider', Slider);
    app.component('Skeleton', Skeleton);
    app.component('SplitButton', SplitButton);
    app.component('TabMenu', TabMenu);
    app.component('TabPanel', TabPanel);
    app.component('TabView', TabView);
    app.component('Tag', Tag);
    app.component('Textarea', Textarea);
    app.component('Toast', Toast);
    app.component('ToggleButton', ToggleButton);
    app.component('Timeline', Timeline);
    app.component('TieredMenu', TieredMenu);
    app.component('Card', Card);

    app.directive('badge', BadgeDirective);
    app.directive('tooltip', Tooltip);
    app.directive('ripple', Ripple);
    app.directive('styleclass', StyleClass);


    if(process.env.VUE_APP_SENTRY_REPORTING === "true") {
        // add use of sentry to the app.
        Sentry.init({
            app,
            dsn: process.env.VUE_APP_SENTRY_DSN,
            integrations: [
                new Sentry.BrowserTracing({
                    routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                    tracePropagationTargets: ["localhost", "clarityglobal.com", "dev.d1v1jzkcjp8r9h.amplifyapp.com", "dev-clientportal.clarityglobal.com", /^\//],
                }),
            ],
            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: process.env.VUE_APP_SENTRY_SAMPLERATE,
            environment: process.env.VUE_APP_SENTRY_ENVRIONMENT
        });
    }

    return app;
}

export default setup;
