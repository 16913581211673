export default {
    setActiveModal (state, value) {
        state.currentActiveModal = value
    },
    resetActiveModal(state) {
        state.currentActiveModal = null
    },
    setModalOrigin(state, value) {
        state.activeModalOrigin = value
    },
    resetModalOrigin(state) {
        state.activeModalOrigin = null
    },
    setModalMode(state, value) {
        state.activeModalMode = value
    },
    resetModalMode(state) {
        state.activeModalMode = null
    }
}