import setup from '../src/app'
import { makeServer } from '../tests/e2e/support/mock/server';
import passthrough from "../tests/e2e/support/mock/passthrough"

const useMock = process.env.VUE_APP_MOCK_BACKEND_API

if (process.env.NODE_ENV === 'development' && useMock !== 'false') {
    makeServer({
        passthrough: passthrough(useMock)
    })
}

const mount = async () => {
    const app = await setup();
    app.mount('#app');
}

mount();
