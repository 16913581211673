export default class MessageObj {
    constructor(data) {
        let self = this;

        self.message_id = data.message_id;
        self.recipient = data.recipient;
        self.cc = data.cc;
        self.sender = data.sender;
        self.subject = data.subject;
        self.category = data.category;
        self.previousMessageId = data.previous_message_id;
        self.messagebody = data.messagebody;
        self.lastOpened = data.last_opened;
        self.date_sent = data.date_sent;
        self.openedBy = data.opened_by;
        self.read = data.read;
        self.send = data.send;
        self.draft = data.draft;
        self.archived = data.archived;
        self.attachments = data.attachments;
    }

    getMessageID() {
        return this.message_id
    }

    getRecipient() {
        return this.recipient
    }

    getCC() {
        return this.cc
    }

    getSender() {
        return this.sender
    }

    getSubject() {
        return this.subject
    }

    getCategory() {
        return this.category
    }

    getPreviousMessageId() {
        return this.previousMessageId
    }

    getMessageBody() {
        return this.messagebody
    }

    getLastOpened() {
        return this.lastOpened
    }

    getDateSent() {
        return this.date_sent
    }

    getOpenedBy() {
        return this.openedBy
    }

    getRead() {
        return this.read
    }

    getSent() {
        return this.send
    }

    getDraft() {
        return this.draft
    }

    getArchived() {
        return this.archived
    }

    getAttachments() {
        return this.attachments
    }
}
