import api from '@/core/services/api'
import bus from "@/core/services/bus";
import {toDrupalRequest} from "@/core/helpers";
// likely to be removed on attachments work - move to  outbound message component
import xhrAdapter from '../../../core/services/axiosAdapter';
import axios from 'axios'

const baseURL = process.env.VUE_APP_APIENDPOINT


export default {
    async setMailboxType({commit}, mailboxtype) {
        commit('setMailboxType', mailboxtype)
    },
    async loadMessageWithID({commit}, data) {
        const response = await api.get('/securemessaging/loadMessageWithID', {params: {messageID: data.id}})

        if (response) {
            let msg = response
            commit('setCurrentMessage', msg)
            if (msg['draft'] === 1) {
                bus.emit('messageIsDraft')
            }
            return msg
        }
    },
    async updateMessage({dispatch}, payload = false) {
        if (process.env.VUE_APP_DELAY_BACKEND_API) {
            await new Promise(r => setTimeout(r, 2000));
        }
        let suppress = false;
        if(payload.suppress){
            suppress = true;
            delete payload.suppress
        }

        const response = await api.post('/securemessaging/updateMessage', JSON.stringify(payload))

        if (response && response.success) {
            dispatch('setLoading', {id: 'outboundmessage', state: false})
            bus.emit('closeNewMessage', payload.nav)
            bus.emit('unBusyCursorRead',payload.messageID)
            bus.emit('unBusyCursorArchive',[payload.messageID])
        } else {
            if(!suppress) {
                bus.emit('updateMessageError')
                bus.emit('unBusyCursorRead', payload.messageID)
                bus.emit('unBusyCursorArchive', [payload.messageID])
            }
        }
        return true
    },
    async deleteDraft({dispatch}, payload = false) {
        if (process.env.VUE_APP_DELAY_BACKEND_API) {
            await new Promise(r => setTimeout(r, 2000));
        }

        const response = await api.post('/securemessaging/removeDraftMessage', JSON.stringify(payload))

        if (response && response.success) {
            dispatch('setLoading', {id: 'outboundmessage', state: false})
            bus.emit('closeNewMessage', payload.nav)
            bus.emit('unBusyCursorRead',payload.messageID)
            bus.emit('unBusyCursorArchive',[payload.messageID])
        } else {
            bus.emit('updateMessageError')
            bus.emit('unBusyCursorRead', payload.messageID)
            bus.emit('unBusyCursorArchive', [payload.messageID])
        }
        return true
    },
    async confirmOpenMessage(_, payload) {
        if (process.env.VUE_APP_DELAY_BACKEND_API) {
            await new Promise(r => setTimeout(r, 2000));
        }
        await api.post('/securemessaging/updateMessage', JSON.stringify(payload))
        return true
    },
    async addMessage({dispatch}, payload) {
        if (process.env.VUE_APP_DELAY_BACKEND_API) {
            await new Promise(r => setTimeout(r, 2000));
        }
        const response = await api.post('/securemessaging/addMessage', JSON.stringify(payload))

        if (response.success) {
            dispatch('setLoading', {id: 'outboundmessage', state: false})
            bus.emit('closeNewMessage', payload.nav)
        }
    },
    async removeAttachment({commit, dispatch}, payload) {
        if (process.env.VUE_APP_DELAY_BACKEND_API) {
            await new Promise(r => setTimeout(r, 2000));
        }
        const response = await api.post('/securemessaging/removeAttachment', JSON.stringify(payload))

        if (response.success) {
            commit('removeAttachment', payload)
            dispatch('setLoading', {id: 'removeAttachment', state: false})
            return true;
        }else{
            dispatch('addNotification', {
                severity: 'error',
                summary: 'Remove Attachment',
                detail: `Failed to remove the attachment from the secure message.`
            })
            dispatch('setLoading', {id: 'removeAttachment', state: false})
            return false;
        }
    },
    async refineMessages({commit}, payload) {
        commit('setMailboxLoadingState', true)

        const response = await api.post('/securemessaging/loadMessages', JSON.stringify(payload))

        if (response) {
            commit('setMessages', response.messages)
            commit('setMessageMeta', response.meta)
            commit('setMailboxLoadingState', false)
        }

    },
    async loadNewMessages({commit}) {
        commit('setNewMessagesLoadingState', true)

        const response = await api.get('/securemessaging/loadNewMessages')

        if (response) {
            commit('setNewMessages', response.messages)
            commit('setNewMessagesLoadingState', false)
        }

    },
    async bulkMessageArchive(_, messageArray) {
        const bulkSuccess = await Promise.all(await messageArray.map(async (msg) => {
            const response = await api.post('/securemessaging/updateMessage', JSON.stringify({
                messageID: msg.message_id,
                archived: 1,
                send: false
            }))
            if (!response.success) {
                bus.emit('bulkArchiveError', msg.subject)
            }
            return response
        }))

        bus.emit('unBusyCursorArchive',messageArray.map((msg) => {return msg.message_id}));
        return bulkSuccess
    },
    async getRecipientList({commit}) {
        const response = await api.get(`${toDrupalRequest('/securemessaging/recipients')}`)

        if (response) {
            commit('setRecipientList', response)
        }
    },
    async getRecipientFilterOptions({commit}) {
        const response = await api.get(`${toDrupalRequest('/securemessaging/recipientsList')}`)

        if (response) {
            commit('setRecipientFilterList', response)
        }
    },
    async getSenderList({commit}) {
        const response = await api.get(`${toDrupalRequest('/securemessaging/sendersList')}`)

        if (response) {
            commit('setSenderList', response)
        }
    },
    async getSignedURL(_, payload) {
        const response = await api.post('/securemessaging/uploadAttachment', JSON.stringify(payload))

        if (response) {
            //console.log(response)
            return response
        }
    },
    async uploadFile(_, payload) {
        const ax = axios.create({
            baseURL: baseURL,
            adapter: xhrAdapter
        })

        // set headers
        const response = await ax.put(
            payload.endpoint,
            payload.data,
            {
                headers: {
                    'Content-Type': payload.data.type
                }
            }
        )

        if (response) {
            //console.log('aws upload response', response)
            return response
        }
    },
    setMailDateRangeFrom({commit}, value) {
        commit('setMailDateRangeFrom', value)
        //console.log('setMailDateRangeFrom', value)
    },
    setMailDateRangeTo({commit}, value) {
        commit('setMailDateRangeTo', value)
        //console.log('setMailDateRangeTo', value)
    },
    updateCorrespondentFilter({commit}, payload) {
        commit('updateCorrespondentFilter', payload)
    },
    updateCategoryFilter({commit}, payload) {
        commit('updateCategoryFilter', payload)
    },
    unsetCurrentMessage({commit}) {
        commit('unsetCurrentMessage')
    },
    setAdviser({getters, commit}) {
        const adviser = getters.getAdviserName
        if (adviser) {
            commit('setAdviser', adviser)
        }
    },
    globalMessageTrigger({commit}, payload) {
        commit('globalMessageTrigger', payload)
    },
    resetGlobalMessageTrigger({commit}) {
        commit('resetGlobalMessageTrigger')
    },
    storeMessageRecovery({commit}, payload) {
        commit('setMessageRecovery', payload)
    },
    resetMessageRecovery({commit}) {
        commit('setMessageRecovery', false)
    }
};
