import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default {
    state() {
        return {
            obAccountsLoading: true,
            obAccountsAdding: false,
            obAccountsRenewing: false,
            obAccounts: [],
            obProvidersLoading: true,
            obProviders: []
        }
    },
    mutations,
    actions,
    getters
}
