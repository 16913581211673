import api from '@/core/services/api'
import {toDrupalRequest} from '@/core/helpers';

export default {
    async fetchProductTypes({commit}) {
        const response = await api.get(`${toDrupalRequest('/selfmanaged/producttypes')}`)

        commit('setHoldingProductTypes', response.productTypes)
        commit('setHoldingProductCommonChoices', response)
    },
    async fetchLiabilityTypes({commit}) {
        const response = await api.get(`${toDrupalRequest('/selfmanaged/liabilitytypes')}`)
        commit('setLiabilityProductTypes', response.liabilityTypes)
    },
    async fetchSelfManagedHoldings({commit}) {
        const response = await api.get(`${toDrupalRequest('/selfmanaged/assets')}`)
        commit('setSelfManagedHoldings', response)
    },
    async fetchSelfManagedLiabilities({commit}) {
        const response = await api.get(`${toDrupalRequest('/selfmanaged/liabilities')}`)
        commit('setSelfManagedLiabilities', response)
    },
    async fetchFundLinks({commit}) {
        const response = await api.get(`${toDrupalRequest('/selfmanaged/clarityfundlinks')}`)
        commit('setFundLinks', response.fundlinks)

        // we also want to load fundlinks for the stock price feeds.
        const stockTickersResponse = await api.get(`${toDrupalRequest('/stock-data/symbols')}`)
        const stockFundLinks = [];
        stockTickersResponse.forEach((ticker) => {
           stockFundLinks.push({
                "searchtext": ticker.name+' ('+ticker.symbol+')',
                "value": ticker.name+' ('+ticker.symbol+')',
                "label": ticker.name+' ('+ticker.symbol+')',
                "id": ticker.symbol,
                "price": 'N/A',
                "cash": 0,
                "fixedinterest": 0,
                "property": 0,
                "ukequity": ticker['exchange_code'] == 'LSE' ? 100 : 0,
                "usequity": ticker['exchange_code'] == 'LSE' ? 0 : 100,
                "euroequity": 0,
                "japanequity": 0,
                "fareastequity": 0,
                "otherequity": 0,
                "specialistequity": 0,
                "sector": ticker['exchange_code'] == 'LSE' ? 'UK' : 'LSE',
                "isbuylist": false,
                "ocf": 0
           })
        });

        commit('addFundLinks', stockFundLinks)

    },
    setNewSelfManagedType({commit}, payload) {
        commit('setNewSelfManagedType', payload)
    },
    updateSelectedProduct({commit}, productId) {
        commit('setSelectedProduct', productId)
    },
    resetSelfManagedChoices({commit}) {
        commit('resetSelfManagedChoices')
    },
    setSelfManagedSplit({commit}, payload) {
        commit('setSelfManagedSplit', payload)
    },
    setSMHField({commit}, payload) {
        commit('setSMHField', payload)
    },
    saveHolding({commit}, payload) {
        commit('saveHolding', payload)
    },
    setEditSMHoldingRecord({commit}, index) {
        commit('setEditSMHoldingRecord', index)
    },
    removeSMHoldingRecord({commit}, index) {
        commit('removeSMHoldingRecord', index)
    },
    addSMHAdvancedOptions({commit}) {
        commit('addSMHAdvancedOptions')
    },
    saveSelfManagedHolding({commit}, {finalPayload, index}) {
        commit('saveSelfManagedHolding', {finalPayload, index})
        commit('invalidateValuationData')
    },
    saveSelfManagedLiability({commit}, {finalPayload, index}) {
        commit('saveSelfManagedLiability', {finalPayload, index})
        commit('invalidateValuationData')
    },
    addSelfManagedHolding({commit}, {finalPayload, assetId}) {
        commit('addSelfManagedHolding', {finalPayload, assetId})
        commit('invalidateValuationData')
    },
    addSelfManagedLiability({commit}, {finalPayload, liabilityId}) {
        commit('addSelfManagedLiability', {finalPayload, liabilityId})
        commit('invalidateValuationData')
    },
    editValuationHolding({commit}, value) {
      commit('editValuationHolding', value)
    },
    editValuationHoldingIndex({commit}, value) {
        commit('editValuationHoldingIndex', value)
    },
    deleteHolding({commit}, index) {
        commit('deleteHolding', index)
        commit('invalidateValuationData')
    },
    deleteLiability({commit}, index) {
        commit('deleteLiability', index)
        commit('invalidateValuationData')
    },
    resetSMHStep({commit}, step) {
        if (step === 2) {
            commit('editValuationHolding', null)
            commit('editValuationHoldingIndex', null)
            commit('setHolding', [])
            commit('resetSMHField')
        }
    }
}
