// import bus from "@/core/services/bus";
import getters from "@/store/modules/selfManaged/getters";

export default {
    setHoldingProductCommonChoices(state, payload) {
        let optionsRtn = []
        payload.commonChoices.forEach(choice => {
            // @todo: need to create array of match products for cards
            state.productTypes.map(type => {
                const match = type.products.find(product => product.id === choice)
                if (match) {
                    optionsRtn.push(match)
                }
            })
        })
        state.commonChoices = optionsRtn
    },
    setHoldingProductTypes(state, payload) {
        state.productTypes = payload
    },
    setLiabilityProductTypes(state, payload) {
        state.liabilityTypes = payload
    },
    setSelfManagedHoldings(state, payload) {
        state.selfManaged = payload
    },
    setSelfManagedLiabilities(state, payload) {
        state.selfManagedLiabilities = payload
    },
    setNewSelfManagedType(state, typeId) {
        state.selectedNewSelfManagedType = typeId
    },
    setFundLinks(state, payload) {
        state.fundlinks = payload
    },
    addFundLinks(state, payload) {
        state.fundlinks = state.fundlinks.concat(payload)
    },
    setSelectedProduct(state, productId) {
        state.selectedProduct = [productId]
    },
    resetSelfManagedChoices(state) {
        state.selectedNewSelfManagedType = null
        state.selectedProduct = []
    },
    setSelfManagedSplit(state, split) {
        state.split = split
    },
    setSMHField(state, payload) {
        state.selfManagedDetails[payload.field] = payload.value
    },
    resetSMHField(state) {
        Object.keys(state.selfManagedDetails).forEach(key => {
            state.selfManagedDetails[key] = null
        })
    },
    saveHolding(state, payload) {
        if (payload.index !== null) {
            state.holdings[payload.index] = payload.data
            state.editing = null
        } else {
            state.holdings.push(payload.data)
        }
        const valuationDate = payload.data.valuationDate;
        state.holdings = state.holdings.map((record) => {
            record.valuationDate = valuationDate;
            return record;
        })
    },
    setEditSMHoldingRecord(state, index) {
        state.editing = index
    },
    removeSMHoldingRecord(state, index) {
        state.holdings.splice(index, 1)
    },
    setHolding(state, payload) {
        state.holdings = payload
    },
    addSMHAdvancedOptions(state) {
        let product = getters.selectedProduct(state)

        let mappedData = []

        // eslint-disable-next-line no-unused-vars
        Object.entries(state.selfManagedDetails).forEach(obj => {
            const [id, value] = obj
            if (id !== "name") {
                mappedData.push({
                    id: id,
                    value: value
                })
            }
        })

        /*
        const formatHoldingData = (holdings) => {
            let rtn = []
                holdings.forEach(holding => {
                    rtn.push({
                        name: holding.fund  && typeof holding.fund === 'object' ? holding.fund.label : holding.fund ?? holding.name,
                        usingClarityData: true,
                        code: holding.fund && typeof holding.fund === 'object' ? holding.fund.id : holding.code ?? holding.fundCode,
                        codeType: holding.fund ? holding.fundCodeType.toUpperCase() : holding.codeType,
                        valuationDate: holding.fund ? holding.valuationDate : holding.valuationDate,
                        units: holding.units,
                        unitPrice: holding.price ?? holding.unitPrice,
                        currency: holding.currency,
                        assetAllocation: {
                            cash: holding.cash,
                            fixedInterest: holding.fixedInt,
                            property: holding.property,
                            ukEquity: holding.uk,
                            usEquity: holding.us,
                            euroEquity: holding.euro,
                            fareastEquity: holding.asia,
                            japanEquity: holding.japan,
                            otherEquity: holding.emerging,
                            specialistEquity: holding.special,
                            sector: holding.sector
                        }
                    })
                })

            return rtn
        }
         */

        const productObject = getters.getSelectedProductObject(state);

        // eslint-disable-next-line no-unused-vars
        //let dataNoNulls =  Object.fromEntries(Object.entries(mappedData).filter(([k, v]) => v.value != null ))
        const dataNoNulls = [];
        productObject.extendedDetails.forEach((record) => {
            // check if we have a value set for this record.
            const match = mappedData.find(({ id }) => id === record.id);
            if(match && match.value !== null){
                dataNoNulls.push(match)
            }
        });

        const holdingsArray = [...state.holdings];
        holdingsArray.forEach(function(obj) {
            obj.valuationDate = new Date(obj.valuationDate)
        });

        let finalPayload = {
            "selectedCategory": getters.getParentOfProduct(state)(product[0]).label,
            "selectedInvestmentType": productObject.label,
            "planName": state.selfManagedDetails.planName,
            "currency": getters.currencySelected ? getters.currencySelected : "GBP",
            "ownership": state.split.map(user => {
                return {
                    owner: user.uuid,
                    ownership: user.percentage
                }
            }),
            "linkedLiabilities": [],
            "filteredExtendedDetails": Object.values(dataNoNulls),
            "holdings": holdingsArray
        }

        state.finalPayload = finalPayload;

    },
    addSelfManagedHolding(state, {finalPayload, assetId}) {
        //console.log('adding with assetId', assetId)
        state.selfManaged.push({
            ...finalPayload, "assetId": assetId
        })
    },
    addSelfManagedLiability(state, {finalPayload, liabilityId}) {
        //console.log('adding with liaiblityId', liabilityId)
        state.selfManagedLiabilities.push({
            ...finalPayload, "liabilityId": liabilityId
        })
    },
    saveSelfManagedHolding(state, {finalPayload, index}) {
        state.selfManaged[index] = finalPayload
    },
    saveSelfManagedLiability(state, {finalPayload, index}) {
        state.selfManagedLiabilities[index] = finalPayload
    },
    editValuationHolding(state, value) {
      state.isEditingExistingValuationHolding = value
    },
    editValuationHoldingIndex(state, value) {
        state.isEditingExistingValuationHoldingIndex = value
    },
    deleteHolding(state, index) {
        state.selfManaged.splice(index, 1)
    },
    deleteLiability(state, index) {
        state.selfManagedLiabilities.splice(index, 1)
    },
    setSelfManagedMode(state, mode) {
        state.mode = mode;
    }
}
