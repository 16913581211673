export const getEquity = (data, needle) => {
    let equities = []
    let sum = 0.0

    for (const k in data) {
        if (k.includes(needle)) {
            equities[k] = parseFloat(data[k]).toFixed(2)
            sum+=parseFloat(data[k])
        }
    }

    return {
        'equity': equities,
        'total' : parseFloat(sum).toFixed(2)
    }
}

export const totalChecker = (data) => {
    let sum = 0.0
    for (const k in data) {
        if(k !== 'allocation' && typeof(data[k]) === 'number') {
            sum+=data[k]
        } else if(k !== 'allocation') {
            sum+=parseFloat(data[k])
        }
    }

    return sum === 100
}

export const getVariance = (setA, setB) => {
    const aKeys = Object.keys(setA).sort();
    const bKeys = Object.keys(setB).sort();
    if (JSON.stringify(aKeys) !== JSON.stringify(bKeys)) {
        return {}
    }

    let variation = {}
    for(let i = 0; i < aKeys.length; i++) {
        variation[aKeys[i]] = (parseFloat(setA[aKeys[i]])- parseFloat(setB[aKeys[i]])).toFixed(2)
    }
    variation['allocation'] = 'variance'

    return variation
}

export const sortDataForDisplay = (data, addType = true) => {
    const order = ['pension', 'nonpension', 'all']
    let customSort = []
    for(const v of order) {
        if (addType) {
            customSort.push({...data[v], 'type': v})
        } else {
            customSort.push({...data[v]})
        }
    }

    return customSort
}

export const calculatePolicyTotalsForCollapsedView = (policyPayload) => {
    const toReturn = []

    for (let policy in policyPayload) {
        toReturn[policy] = {
            'value': 0.00,
            "cash": 0.00,
            "cash_percent": 0.00,
            "fixed_interest": 0.00,
            "fixed_interest_percent": 0.00,
            "property": 0,
            "property_percent": 0.00,
            "equity":0.00,
            "uk_equity": 0.00,
            "equity_percent": 0.00,
            "us_equity": 0.00,
            "european_equity": 0.00,
            "fareast_equity": 0.00,
            "japan_equity": 0.00,
            "other_equity": 0.00,
            "specialist_equity": 0.00,
            "uk_equity_percent": 0.00,
            "us_equity_percent": 0.00,
            "european_equity_percent": 0.00,
            "fareast_equity_percent": 0.00,
            "japan_equity_percent": 0.00,
            "other_equity_percent": 0.00,
            "specialist_equity_percent": 0.00
        }

        policyPayload[policy].forEach(fund => {
            toReturn[policy]['value'] += parseFloat(fund.value)
            for(const [key, value] of Object.entries(fund.allocation)) {
                if(key !== 'sector' && value && value !== 'undefined' && value !== null) {
                    switch(typeof value){
                        case 'string':
                            toReturn[policy][key]  =  toReturn[policy][key]+= parseFloat(value)
                            break;
                        case 'number':
                            toReturn[policy][key]  =  toReturn[policy][key]+= value
                            break;
                    }
                }
            }

        });
    }

    return toReturn
}
