<template>
  <Sidebar
      id="menu_mobile" :visible="showMobile" :baseZIndex="10000" position="full"
      class="sidebar" :class="[ hasGroupBar ? 'barHeader':'header']"
  >
    <div class="logo mt-3 -mb-2 align-content-center">
      <img src="@/assets/logos/clarity-logo.png">
    </div>
      <div v-for="item of items" :key="item.label">
        <!-- single item, not subitems  -->
        <router-link v-if="item.to && !item.items" :to="item.to" replace @click="toggleMobileMenu"
                     class="flex item justify-content-start align-items-center wide top">
          <div class="flex align-items-center ml-3">
            <span class="material-icons-round white m-3">{{ item.icon }}</span>
            <span class="white">{{ item.label }}</span>
          </div>
        </router-link>
        <!-- if menu has sub items, toggle menu -> all other menu items -->
        <div v-if="item.items"
             class="flex item sub-item justify-content-start align-items-center wide top"
             :class="{'active' : selectedItem === item.label}" @click="toggleItem(item.label)">
          <div class="flex align-items-center justify-content-between ml-3" style="width: 90%">
            <div class="flex align-items-center">
              <span class="material-icons-round white m-3">{{ item.icon }}</span>
              <span>{{ item.label }}</span>
            </div>
            <div class="flex align-items-center">
              <span class="material-icons-round white" v-if="selectedItem !== item.label">expand_more</span>
              <span class="material-icons-round white" v-if="selectedItem === item.label">expand_less</span>
            </div>
          </div>
        </div>
        <!--Subitem -->
        <div v-if="selectedItem === item.label && item.items" class="flex-column justify-content-start">
          <div v-for="subitem in item.items" :key="subitem.label"
               class="flex flex-column justify-content-start">
            <div v-if="subitem.items">
              <div class="flex item sub-item align-items-center justify-content-between"
                   :class="{'active' : selectedSubItem === subitem.label}"
                   @click="toggleSubItem(subitem.label)">
                <span class="ml-8">{{ subitem.label }}</span>
                <div class="flex align-items-center mr-4" v-if="subitem.items">
                  <span class="material-icons-round white" v-if="selectedSubItem !== subitem.label">expand_more</span>
                  <span class="material-icons-round white" v-if="selectedSubItem === subitem.label">expand_less</span>
                </div>
              </div>
              <!-- second level menu  -->
              <div v-if="selectedSubItem === subitem.label && subitem.items">
                <div v-if="subitem.items">
                  <div v-for="subsubitem in subitem.items" :key="subsubitem.label"
                       class="flex item sub-item pl-4 align-items-center"
                       @click="(item.command) ? item.command():false; goToInternalLink(subsubitem.label, subsubitem);">
                    <span class="ml-8">{{ subsubitem.label }}</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- Subitem, no further subitems -->
            <div v-else>
              <router-link v-if="subitem.to" :to="subitem.to" replace @click="toggleMobileMenu">
                <div
                    :key="subitem.label"
                    class="flex item sub-item align-items-center justify-content-between"
                    :class="{'active' : selectedSubItem === subitem.label}"
                >
                  <span class="ml-8">{{ subitem.label }}</span>
                </div>
              </router-link>
              <div v-if="subitem.url" @click="followExternalLink(subitem.url)">
                <div
                    :key="subitem.label"
                    class="flex item sub-item align-items-center justify-content-between"
                    :class="{'active' : selectedSubItem === subitem.label}"
                >
                  <span class="ml-8">{{ subitem.label }}</span>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
  </Sidebar>
</template>

<script>
import {inject, ref, computed, onBeforeUnmount} from 'vue';
import {useStore} from "vuex";
import {useRouter} from "vue-router";

export default {
  name: "MobileMenu",
  props: {
    groupBar: {
      type: Boolean
    }
  },
  components: {},
  setup(props) {
    const bus = inject('bus')
    const store = useStore()
    const router = useRouter()
    const showMobile = ref(false)
    const selectedItem = ref()
    const selectedSubItem = ref()
    const clarityURL = process.env.VUE_APP_CLARITYURL;


    bus.on('toggleMobileMenu', () => {
      toggleMobileMenu()
    })

    bus.on('tablet', () => {
      showMobile.value = false
      selectedItem.value = false
      selectedSubItem.value = false
    })

    const toggleMobileMenu = () => {
      showMobile.value = !showMobile.value
      selectedItem.value = false
      selectedSubItem.value = false
    }

    const items = ref([
      {
        label: 'Dashboard',
        icon: 'home',
        to: '/',
      },
      {
        label: 'Investments',
        icon: 'bar_chart',
        to: '/investments',
        items: [
          {
            label: 'My investments',
            to: '/investments',
            items: [
              {
                label: 'Valuation',
                to: '/investments/valuation'
              },
              {
                label: 'Asset Allocation',
                to: '/investments/assetallocation'
              },
              {
                label: 'Transactions',
                to: '/investments/transactions'
              },
            ]
          },
          {
            label: 'IMS',
            to: '/ims/imshome',
            items: [
              {
                label: 'IMS Home',
                to: '/ims/imshome'
              },
              {
                label: 'IMS FAQs and Info',
                to: '/ims/imsfaqs'
              }
            ]
          },
          {
              label: 'Add Self Managed Holding',
              to: '/investments/selfmanaged'
          },
          {
            label: 'Add New Holding',
            to: '/investments',
            items: [
              {
                label: 'Invest Now',
                to: '/investments/add'
              },
              {
                label: 'Add Bank Account or Credit Card',
                to: '/investments/openbanking'
              }
            ]
          },
          {
            label: 'Portfolio Management',
            to: '/investments/portfolios'
          },
          {
            label: 'Fund Provider Data Feeds',
            to: '/investments/datafeeds'
          },
          {
            label: 'Permissions',
            to: '/investments/permissions'
          },
        ]
      },
      {
        label: 'Secure Messaging',
        shortlabel: 'Messaging',
        icon: 'mail',
        to: '/securemessaging',
        items: [
          {
            label: 'Inbox',
            to: '/securemessaging/inbox'
          },
          {
            label: 'Drafts',
            to: '/securemessaging/drafts'
          },
          {
            label: 'Sent Items',
            to: '/securemessaging/sent'
          },
          {
            label: 'Archive',
            to: '/securemessaging/archived'
          },
        ]
      },
      {
        label: 'Planning Tools',
        shortlabel: 'Tools',
        icon: 'fact_check',
        to: '/tools',
        items: [
          {
            label: 'Wealthplan',
            to: '/tools/wealthplan'
          },
          {
            label: 'Calculators',
            to: '/tools/calculators'
          }
        ]
      },
      {
        label: 'Research',
        icon: 'search',
        to: '/research',
        items: [
          {
            label: 'Research Library',
            to: '/research/library',
          },
          {
            label: 'Investment Buy List',
            to: '/research/buylist',
          },
          {
            label: 'Fund Focus',
            url: clarityURL+'/newfundfocus'
          },
          {
            label: 'Glossary',
            url: clarityURL+'/newglossary'
          },
          {
            label: 'Videos & Webinars',
            url: clarityURL+'/toolsvideos'
          }
        ]
      },
      {
        label: 'My Personal Info',
        shortlabel: 'My Info',
        icon: 'account_circle',
        to: '/myinfo',
        items: [
          {
            label: 'Personal Details',
            to: '/settings/personal'
          },
          {
            label: 'Website Settings',
            to: '/settings/websettings'
          },
          {
              label: 'Risk Profiler',
              to: '/myinfo/riskprofiler'
          },
          /*
          {
            label: 'PFS',
            to: '/'
          },
          {
            label: 'Digital Legacy',
            to: '/',
            items: [
              {
                label: 'Pass Away Instructions',
                to: '/'
              }
            ]
          }
         */
        ]
      },
    //  @TODO: Add more links section here
      {
        label: 'More Links',
        icon: 'add',
        items: [
          {
            label: 'Welcome tour',
            to: '/'
          },
          {
            label: 'FAQs',
            to: '/'
          },
          {
            label: 'Refer a Friend',
            to: '/securemessaging/message',
            command: () => {
              store.dispatch('globalMessageTrigger', {
                subject: 'Refer a Friend',
                category: 'Correspondence',
                recipient: 'Refer a Friend',
                messagebody: 'Dear Admin Team,\n\n' +
                    'I would like to refer a friend to clarity\n\nPlease confirm your referrals contact details below\n\n' +
                    'Many thanks'
              })
            }
          },
        ]
      }
    ]);

    const toggleItem = (label) => {
      selectedItem.value = label === selectedItem.value ? '' : label
      selectedSubItem.value = false
    }

    const toggleSubItem = (label) => {
      selectedSubItem.value = label === selectedSubItem.value ? '' : label
    }

    const goToInternalLink = (label, item) => {
      toggleMobileMenu()
      const destination = item.to;
      const page = item.to.charAt(0) === '/' ? item.to.substring(1).split('/')[0] : item.to.split('/')[0]
      const header = label.toLowerCase().replace(/\s/g, '')
      store.dispatch('setActiveHeaderFromMenu', {
        page: page,
        header: header
      })
      router.push(destination);
    }

    const goToTobLevelTab = (item) => {
      goToInternalLink(null, item)
    }

    const followExternalLink = (link) => {
      window.open(link, "_blank");
    }

    onBeforeUnmount(() => {
        bus.off('tablet')
        bus.off('toggleMobileMenu')
    });

    return {
      showMobile,
      hasGroupBar: computed(() => props.groupBar),
      items,
      toggleItem,
      selectedItem,
      selectedSubItem,
      goToInternalLink,
      toggleSubItem,
      goToTobLevelTab,
      toggleMobileMenu,
      followExternalLink
    }
  }
}
</script>

<style scoped lang="scss">
.sidebar {
  &.header {
    position: sticky;
    z-index: 100;
  }
  &.barHeader {
    position: sticky;
    z-index: 100;
  }
}

.logo {
  display: flex;
  justify-content: space-between;
  padding: 18px;

  button {
    &.grey-border:hover,
    &.grey-border span:hover {
      color: var(--clarity-blue) !important;
    }
  }
}

.active {
  background-color: rgb(23, 155, 215, 0.3);
}

.item {
  border-top: 1px solid var(--clarity-light-blue);
  border-bottom: 1px solid var(--clarity-light-blue);
  border-right: 1px solid var(--clarity-light-blue);
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  cursor: pointer;
  color: var(--clarity-pure-white);
  margin-bottom: 10px;
}

.sub-item {
  height: 39px;
  width: 80%;
}

.top {
  height: 44px;
  width: 90%;

  &.active {
    &::before {
      content: '';
      height: 20px;
      width: 10px;
      border-radius: 0px 50px 50px 0px;
      background-color: var(--clarity-pure-white);
      margin-right: 20px;
    }

    > div {
      margin-left: -15px !important;
    }
  }
}
</style>
