import { createStore } from 'vuex'
import authModule from './modules/auth'
import allocationModule from './modules/allocation'
import calculatorsModule from './modules/calculators'
import dashboardModule from './modules/dashboard'
import datafeedsModule from './modules/datafeeds'
import errorModule from './modules/error'
import messagesModule from './modules/messages'
import modalModule from './modules/modal'
import performanceModule from './modules/performance'
import preferencesModule from './modules/preferences'
import selectionsModule from './modules/selection'
import tabsModule from './modules/tabs'
import transactionModule from './modules/transactions'
import valuationModule from './modules/valuation'
import imsModule from './modules/ims'
import opwnbankingModule from './modules/openbanking'
import loadingModule from './modules/loading'
import stepperModule from './modules/stepper'
import selfManagedModule from './modules/selfManaged'
import toolsModule from './modules/tools'

const store = createStore({
  modules: {
    auth: authModule,
    allocation: allocationModule,
    calculators: calculatorsModule,
    dashboard: dashboardModule,
    datafeeds: datafeedsModule,
    error: errorModule,
    messages: messagesModule,
    modal: modalModule,
    preferences: preferencesModule,
    performance: performanceModule,
    selections: selectionsModule,
    tabs: tabsModule,
    transactions: transactionModule,
    valuation: valuationModule,
    openbanking: opwnbankingModule,
    ims: imsModule,
    loading: loadingModule,
    stepper: stepperModule,
    selfManaged: selfManagedModule,
    tools: toolsModule
  }
});

export default store
