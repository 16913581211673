export default {
    setActiveHeader({ commit }, payload) {
        const active = payload.headers.find(header => header.active === true)
        commit('setActiveHeader', {page: payload.page, active: active.key })
    },
    setActiveHeaderFromMenu({ commit }, payload) {
        commit('setActiveHeader', { page: payload.page, active: payload.header })
    },
    unsetActiveHeader({ commit }, payload) {
        commit('unsetActiveHeader', payload)
    },
    setTabView({ commit }, payload) {
        commit('setTabView', payload)
    }
}