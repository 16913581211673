export default {
    setValuationSummaryChartData(state, value) {
        state.valuationSummaryChartData = value
    },
    setPolicyChartData(state, value) {
        state.valuationPolicyChartData = value
    },
    setValuationDate(state, value) {
        state.valuationDate = value
    },
    // dateRange
    setDateRangeFrom(state, value) {
        state.dateRangeFrom = value
    },
    setDateRangeTo(state, value) {
        state.dateRangeTo = value
    },
    setPDFGraph1(state, value) {
        state.pdfGraph1 = value
    },
    setPDFGraph2(state, value) {
        state.pdfGraph2 = value
    },
    setCurrentISIN(state, value) {
        state.currentISIN = value
    },
    setFundAllocationDialogData(state, value) {
        state.fundAllocationDialogData = value
    },
    setFundHistoryDialogData(state, value) {
        state.fundHistoryDialogData = value
    },
    setFundInfoDialogData(state, value) {
        state.fundInfoDialogData = value
    },
    resetFundDialogData(state) {
        state.fundAllocationDialogData = []
        state.fundHistoryDialogData = []
        state.fundInfoDialogData = []
    }
}
