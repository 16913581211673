import api from '@/core/services/api';
import { toDrupalRequest } from '@/core/helpers';

export default {
    async getAssetAllocationData({ commit }, filter) {
        const response = await api.get(`${toDrupalRequest('assetallocation/full', filter)}`)

        if (response) {
            commit('setAssetAllocation', response);
            commit('setAllocationSummary', response);
            commit('setEquityBreakdown', response);
            commit('setFullAllocation', response);
            commit('setAssetAllocationTableTotals', response);
        }
    }
}
