export const toDrupalRequest = (endpoint, filter) => {
    if(endpoint.charAt(0) != '/') {
        endpoint = '/' + endpoint
    }

    let prepended;

    if (process.env.VUE_APP_D9ENDPOINT_NEW_PROXY !== 'true') {
        prepended = '/clarity?path=' + endpoint
        if(process.env.VUE_APP_TARGET_OVERRIDE === 'true') {
            prepended = '/clarityadmin?path=' + endpoint
        }
    } else {
        prepended = endpoint;
        if (process.env.VUE_APP_TARGET_OVERRIDE === 'true') {
            prepended = '/_admin' + endpoint;
        }
    }

    // add the filter to the URL as they are now contained there.
    if(filter){
        prepended += '/'+filter.portfolio;
        prepended += '/'+filter.people.join(":");
        if(filter.period){
            prepended += '/'+filter.period;
        }
        if(filter.date){
            prepended += '/'+filter.date;
        }
    }

    const d9BaseURL = process.env.VUE_APP_D9ENDPOINT
    const useMock = process.env.VUE_APP_MOCK_BACKEND_API
    if ((useMock !== 'false' && process.env.NODE_ENV === 'development') || process.env.NODE_ENV === 'test') {
        return prepended;
    }

    return d9BaseURL+prepended;
}

export const toClarityAnonRequest = (endpoint) => {
    if(endpoint.charAt(0) != '/') {
        endpoint = '/' + endpoint
    }

    let prepended;

    if (process.env.VUE_APP_D9ENDPOINT_NEW_PROXY !== 'true') {
        prepended = '/clarityanon?path='+endpoint
    } else {
        prepended = endpoint
    }

    const d9BaseURL = process.env.VUE_APP_D9ENDPOINT
    const useMock = process.env.VUE_APP_MOCK_BACKEND_API
    if ((useMock !== 'false' && process.env.NODE_ENV === 'development') || process.env.NODE_ENV === 'test') {
        return prepended;
    }

    return d9BaseURL+prepended;
}
