'use strict'
import { computed } from 'vue'
import store from '../../../store'
import api from "@/core/services/api";
import {toDrupalRequest} from "@/core/helpers";
import dayjs from "dayjs";

// Helpers to extract common data for the Valuation Export Documents -> should be document agnostic!
export async function getPrimaryClient() {
    const primary = await computed(() => store.state.selections.peopleOptions)

    return primary.value[0].name
}

export async function getSelectedPortfolio() {
    const portfolio = await computed(() => store.getters.fullPortfolioSelected)

    return portfolio.value === 'all' ? 'All Holdings' : portfolio.value.name
}

export async function getPolicies() {
    const policies = await computed(() => store.getters.policySelected)

    return policies.value ? policies.value.label : 'All Policies'
}

export async function getSelectedPeople() {
    const people = await computed(() => store.getters.peopleSelected)

    let peopleString
    let persons = []
    if (people.value != null) {
        people.value.forEach(person => {
            persons.push(person.name)
        })
        peopleString = persons.join(', ')
    } else {
        const ppl = store.state.selections.peopleOptions;
        ppl.forEach(person => {
            persons.push(person.name)
        })
        peopleString = persons.join(', ')
    }

    return peopleString
}

export async function getOwnerFromUuid(owner) {
    let rtn = []
    owner.forEach(person => {
        if (person.name !== '') {
            rtn.push(store.getters.personFromUuid(person).name)
        }
    })

    return rtn.join(", ")
}

export async function getPlans(date) {
    const policies = await computed(() => store.state.selections.policyOptions)
    const portfolio = await computed(() => store.getters.fullPortfolioSelected)
    const policySelected = await computed(() => store.getters.policySelected )

    let plans = []
    if(portfolio.value) {
        for (const policy of portfolio.value.portfolioplans) {
            let pol = policies.value.filter(pol => pol.planstring === policy)[0]
            if (!policySelected.value || (policySelected.value.value === pol.planstring)) {
                const response = await api.get(`${toDrupalRequest('valuation/plan/holdings/' + pol.planstring + '/' + date)}`)
                if (response) {
                    const holdingsLoaded = []
                    if (response.holdings) {
                        response.holdings.forEach((holding) => {
                            holding.value = (holding.price * holding.units).toFixed(2);
                            holdingsLoaded.push(holding);
                        });
                        // sort the holdings by holding name.
                        holdingsLoaded.sort((a, b) => {
                            // Convert both names to lowercase to ensure case-insensitive comparison
                            const nameA = a.name.toLowerCase();
                            const nameB = b.name.toLowerCase();
                            // Compare the names
                            if (nameA < nameB) {
                                return -1;
                            }
                            if (nameA > nameB) {
                                return 1;
                            }
                            // Names are equal
                            return 0;
                        });
                        plans.push([pol.name, holdingsLoaded, await getOwnerFromUuid(pol.ownership)]);
                    } else {
                        console.log('failed to load holdings for ' + pol.planstring, response);
                    }
                }
            }
        }
    }

    return plans
}

export async function getISINDetails(date) {
    const policies = await computed(() => store.state.selections.policyOptions)
    const portfolio = await computed(() => store.getters.fullPortfolioSelected)
    const policySelected = await computed(() => store.getters.policySelected )

    let records = []

    for (const policy of portfolio.value.portfolioplans) {
        let pol = false;
        if(policies.value.length){
            pol = policies.value.filter(pol => pol.planstring === policy)[0]
        }
        if(!policySelected.value || (policySelected.value.value === pol.planstring)) {
            const response = await api.get(`${toDrupalRequest('valuation/plan/holdings/' + policy + '/' + date)}`)
            if (response) {
                if(response.holdings) {
                    response.holdings.forEach((holding) => {
                        const additionalValue = (holding.price * holding.units).toFixed(2);
                        const foundIndex = records.findIndex(x => x["B"] == holding.isin);
                        if (foundIndex !== -1) {
                            records[foundIndex]["C"] += additionalValue*1;
                        } else {
                            records.push({
                                "A": holding.name,
                                "B": holding.isin,
                                "C": additionalValue*1
                            })
                        }

                    });
                }
            }
        }
    }

    return records
}

export async function getTransactions(dates) {
    const policies = await computed(() => store.state.selections.policyOptions)
    const portfolio = await computed(() => store.getters.fullPortfolioSelected)

    let plans = []

    for (const policy of portfolio.value.portfolioplans) {
        let pol = policies.value.filter(pol => pol.planstring === policy)[0]
        const response = await api.get(`${toDrupalRequest('/transaction/plan/alltransactions/'+pol.planstring+'/'+dates.from+'::'+dates.to)}`)
        if (response) {
            const transactionsLoaded = []
            response.transactions.forEach((transaction) => {
                transactionsLoaded.push(transaction);
            });
            plans.push([pol.name, transactionsLoaded, await getOwnerFromUuid(pol.ownership)]);
        }
    }

    return plans
}

export async function getSummarySectionData(date) {
    const policies = await computed(() => store.state.selections.policyOptions)
    const portfolio = await computed(() => store.getters.fullPortfolioSelected)
    const policySelected = await computed(() => store.getters.policySelected )

    let pens = []
    let nonpens = []
    let isa = []
    let valuationTotal = 0.00
    let returnArray = []

    portfolio.value.portfolioplans.forEach(policy => {
        let pol = policies.value.filter(pol => pol.planstring === policy)[0]
        if(!policySelected.value || (policySelected.value.value === pol.planstring)) {
            if (pol.productType === 'pens') {
                pens.push(pol);
            } else if (pol.productType === 'isa') {
                isa.push(pol);
            } else if (pol.productType === 'nonpens') {
                nonpens.push(pol);
            }
        }
    })

    const result = {'Pension Holdings' : pens, 'Non Pension Holding' : nonpens, 'ISA' : isa}

    for (const [key, value] of Object.entries(result)) {
        let totalPlanValue = 0.00

        for(const p  of value){
            const total = await loadPresentValues(p.planstring, date)
            if (total) {
                totalPlanValue+= total
            }
        }

        valuationTotal+=totalPlanValue
        returnArray.push({holding: key, value: totalPlanValue})
    }

    returnArray.push({total: 'Total Valuation', value: valuationTotal})

    return returnArray
}

export async function loadPresentValues(uuid, date){
    const apidate = dayjs(date).format('YYYY-MM-DD')
    const presentResponse = await api.get(`${toDrupalRequest('valuation/plan/summary/'+uuid+'/'+apidate)}`)
    if (presentResponse) {
        return presentResponse.total;
    }

    return 0.00
}
