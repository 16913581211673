export default {
    setActiveHeader(state, header) {
        state.tabHeaders[header.page] = state.tabHeaders[header.page].map(tab => {
            return {...tab, active : header.active === tab.key }
        })
    },
    unsetActiveHeader(state, header) {
        state.tabHeaders[header.page] = state.tabHeaders[header.page].map(tab => {
            return {...tab, active :false }
        })
    },
    setTabView(state, view) {
        state.tabView = view
    }
}