export default {
    // data feeds
    setDataFeeds(state, value) {
        state.dataFeeds = value
    },
    setDataFeedsLoading(state, value) {
        state.dataFeedsLoading = value
    },
    addDataFeed(state, payload) {
        state.dataFeeds.push(payload)
    },
    deleteDataFeed(state, payload) {
        const idx = state.dataFeeds.findIndex((e) => {
            return e.id == payload.id
        })
        if(idx != -1) {
            //console.log('idx found')
            state.dataFeeds.splice(idx, 1)
        }
    },
    updateDataFeed(state, payload) {
        // get index
        const idx = state.dataFeeds.findIndex((e) => {
            if(payload.newid){
                return e.id == payload.newid || e.id == payload.id
            }else {
                return e.id == payload.id
            }
        })
        //console.log('index is '+idx);
        // return copy of original object only updating the name/plans selected
        // uuid and ims flag untouched
        state.dataFeeds[idx] = {
            id: payload.newid,
            feedID: payload.feedID,
            providerRef: payload.providerRef,
            status: payload.status,
            providerID: payload.providerID,
            edit: false
        }
    }
}
