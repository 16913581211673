<template>
  <div class="flex flex-column item sidebar-item justify-content-start align-items-center morelinks"
       :class="{ 'wide' : wide }" @click="toggleMoreLinks">
    <div class="flex justify-content-center align-items-center">
      <i class="pi pi-ellipsis-v mr-2"></i>
      <span v-if="wide" :class="{ 'wide' : wide }">More Links</span>
      <span v-else>More</span>
    </div>
    <div v-if="isOpen" class="submenu" :class="{ 'wide' : wide }">
      <Menu :model="moreLinkItems">
        <template #item="{item}">
          <div v-if="item.link" class="flex align-items-center third-menu-item" @click="goToLink(item.link)">
            <span class="material-icons-round">{{ item.icon }}</span>
            <span class="mx-2">{{ item.label }}</span>
          </div>
          <div v-else class="flex align-items-center third-menu-item py-3" @click="(item.command) ? item.command():false; (item.to) ? $router.push(item.to):false;">
            <span class="material-icons-round">{{ item.icon }}</span>
            <span class="mx-2">{{ item.label }}</span>
          </div>
        </template>
      </Menu>
    </div>
  </div>
</template>
<script>
import {inject, onBeforeUnmount, ref} from 'vue';
import {useStore} from "vuex";

export default {
  props: {
    wide: {
      type: Boolean
    }
  },
  setup(props, context) {
    const store = useStore();
    const isOpen = ref(false)
    const toggleMoreLinks = () => {
      if(!isOpen.value){
          context.emit('setSubmenu', 'false')
      }
      isOpen.value = !isOpen.value
    }
    const bus = inject('bus')
    const clarityURL = process.env.VUE_APP_CLARITYURL;



      const moreLinkItems = ref([
      {
        label: 'Welcome Tour',
        icon: 'play_circle_outline',
        command: () => {
          store.dispatch('setActiveModal', {modal: 'WelcomeTour'})
        }
      },
      {
        label: 'FAQs',
        icon: 'quiz',
        link: clarityURL+'/newfaqs',
      },
      {
        label: 'Refer a Friend',
        icon: 'person_add',
        to: '/securemessaging/message',
        command: () => {
          store.dispatch('globalMessageTrigger', {
            subject: 'Refer a Friend',
            category: 'Correspondence',
            recipient: 'Refer a Friend',
            messagebody: 'Dear Admin Team,\n\n' +
                'I would like to refer a friend to clarity\n\nPlease confirm your referrals contact details below\n\n' +
                'Many thanks'
          })
        }
      }
    ])

    const goToLink = (link) => {
      window.open(link, '_blank')
    }

    bus.on('clearMoreLinks', () => {
        isOpen.value = false;
    })

    onBeforeUnmount(() => {
        bus.off('clearMoreLinks')
    });

    return {
      isOpen,
      moreLinkItems,
      toggleMoreLinks,
      goToLink
    }
  },
}
</script>
<style scoped lang="scss">
.morelinks {
  position: fixed;
  bottom: 35px;
  /* width: 100%; */
  color: var(--clarity-pure-white);
  cursor: pointer;
}

.submenu {
  position: fixed;
  left: 160px;
  bottom: 20px;
}

.submenu :deep(.p-menu) {
  padding: 20px;
  white-space: nowrap;
  width: unset;
}

.submenu.wide {
  left: 485px;
  font-size: 14px;
}

.wide {
  width: unset;
  left: 20px;
}

span.wide {
  font-size: 18px;
  @media only screen and (max-width: 1366px)  {
    font-size: 14px;
  }
}

.third-menu-item {
  padding-top: 14px;
  padding-bottom: 14px;
  cursor: pointer;
}

.third-menu-item:hover {
  color: var(--clarity-blue);
  .material-icons-round {
    color: var(--clarity-blue);
  }
}

@media only screen and (max-width: 1366px)  {
  .third-menu-item {
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .submenu :deep(.p-menu) {
    padding-top: 11px;
    padding-bottom: 11px;
  }
}
</style>
