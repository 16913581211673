<template>
    <div class="flex align-items-center justify-content-center tier-pill" :class="[ colour ? colour : 'clarity-gradient-blue']">
        <i :class="icon" v-if="icon"/>
        <span class="px-3">{{label}}</span>
    </div>
</template>
<script>
export default {
    props: ['label', 'icon', 'colour'],
    setup() {

    },
}
</script>
<style scoped lang="scss">
.tier-pill {
  height: fit-content;
  border-radius: 11px;
  opacity: 1;

  span {
    letter-spacing: var(--clarity-character-spacing-0);
    color: var(--clarity-pure-white);
    text-align: center;
    font: normal normal normal 12px/18px Open Sans;
  }

  i {
    color: var(--clarity-pure-white);
    margin-left: 15px;
    margin-right: -10px;
    font-size: 0.75rem;
  }

}
</style>
