export default {
    getApprovalRecords (state) {
        //console.log('getApprovalRecords');
        return state.approvalRecords;
    },
    getApprovedRecords (state) {
        return state.approvedRecords;
    },
    getRecentApprovals (state) {
        return state.recentApprovals;
    }
}
