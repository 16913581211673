export default {
    //personal details
    getGroup: (state) => {
        return state.group
    },
    getClientName: (state) => {
        return state.firstname + ' ' + state.surname
    },
    getPreferenceName: (state) => {
        return state.clientName
    },
    getEmail: (state) => {
        return state.emailAddress
    },
    getUsername: (state) => {
        return state.username
    },
    getFirstName: (state) => {
        return state.firstname
    },
    getSurname: (state) => {
        return state.surname
    },
    getCheckpointEnabled: (state) => {
        return state.checkpoint ? state.checkpoint['enabledForClient'] : false
    },
    getCheckpointAdditional: (state) => {
        return state.checkpoint ? state.checkpoint['othersIncluded'] : []
    },
    getAdviserName: (state) => {
        return state.adviserName
    },
    getBuyList: (state) => {
        return state.buylist
    },
    getDomicile: (state) => {
        return state.countryOfDomicile
    },
    getResidence: (state) => {
        return state.countryOfResidence
    },
    // preferences
    getCollapsedNav: (state) => {
        return state.collapsedLeftNav
    },
    getClientServices: (state) => {
        return state.clientServices
    },
    getNewNotifications: (state) => {
        return state.newNotification
    },
    getNewMessages: (state) => {
        return state.newSecureMessage
    },
    getProfileAvatar: (state) => {
        return state.clientImageBase64
    },
    getHomepage: (state) => {
        return state.homepage
    },
    getMFAState: (state) => {
        return state.mfaEnabled
    },
    getReportingCurrency: (state) => {
        return state.reportingCurrency
    },
    getMarketingOptions: (state) => {
        return state.marketing
    },
    getMarketingChoice: (state) => {
        return state.marketingChoice
    },
    getImsSendMethod: (state) => {
        return state.method
    },
    getElementVisibility: (state) => (key) => {
        const visibility = state.elementVisibility;
        if(visibility) {
            //check for the key, if not set return true.
            if(visibility[key] !== undefined){
                return visibility[key]
            }else{
                return true;
            }
        }
        return true;
    }
}
