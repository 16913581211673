import MessageObj from "@/core/classes/Message";

export default {
    setMailboxType: (state, mailboxType) => {
        state.mailboxType = mailboxType
    },
    setMessages: (state, messageArray) => {
        const messages = [];
        const senders = []
        if (typeof messageArray === 'object') {
            messageArray.forEach(msg => {
                let obj = new MessageObj(msg);
                messages.push(obj);
                senders.push(obj.sender.name)
            });
        }
        state.messages = messages;
        state.senderOptions['inbound'] = [...new Set(senders)].map(i => {
            return {label: i}
        })

    },
    setNewMessages: (state, messageArray) => {
        const messages = [];
        if (typeof messageArray === 'object') {
            messageArray.forEach(msg => {
                let obj = new MessageObj(msg);
                messages.push(obj);
            });
        }
        state.newMessages = messages;
    },
    setSentMessages: (state, messageArray) => {
        const messages = [];
        const recipients = [];
        if (typeof messageArray === 'object') {
            messageArray.forEach(msg => {
                let obj = new MessageObj(msg);
                messages.push(obj);
                recipients.push(obj.recipient.name)
            });
        }
        state.sentMessages = messages;
        state.senderOptions['outbound'] = [...new Set(recipients)].map(i => {
            return {label: i}
        })
    },
    setCurrentMessage: (state, message) => {
        state.currentMessage = message
    },
    unsetCurrentMessage(state) {
        state.currentMessage = null
    },
    setMailboxLoadingState: (state, status) => {
      state.mailboxLoading = status
    },
    setNewMessagesLoadingState: (state, status) => {
        state.newMessagesLoading = status
    },
    setMessageMeta(state, meta) {
      state.messageMeta = meta
    },
    // dateRange
    setMailDateRangeFrom(state, value) {
        state.mailDateRangeFrom = value
    },
    setMailDateRangeTo(state, value) {
        state.mailDateRangeTo = value
    },
    updateCorrespondentFilter(state, value) {
        state.correspondentFilter = value
    },
    updateCategoryFilter(state, value) {
        state.categoryFilter = value
    },
    setRecipientList(state, list) {
        if (typeof list === 'object' && list.length) {
            state.additionalRecipients = list.map(({id: uuid, ...rest}) => ({uuid: uuid, ...rest}))
        }
    },
    setRecipientFilterList(state, list) {
        if (typeof list === 'object' && list.length) {
            state.recipientList = list.map(({id: uuid, ...rest}) => ({uuid: uuid, ...rest}))
        }
    },
    setSenderList(state, list) {
        if (typeof list === 'object' && list.length) {
            state.senderList = list.map(({id: uuid, ...rest}) => ({uuid: uuid, ...rest}))
        }
    },
    setAdviser(state, adviser) {
        const adviserObj = state.additionalRecipients.find(contact => contact.name === adviser)
        if (adviserObj && !state.recipientOptions.some(contact => contact.name === adviser)) {
            state.recipientOptions.push(adviserObj)
        }
    },
    // global message trigger {
    globalMessageTrigger(state, payload) {
        state.globalTriggerMessage = payload
    },
    resetGlobalMessageTrigger(state) {
        state.globalTriggerMessage = {}
    },
    removeAttachment(state, payload) {
        state.currentMessage.attachments = state.currentMessage.attachments.filter(function(attachment) {
            return attachment.id != payload.attachmentID;
        }).map((attachment) => {
            return attachment;
        })
    },
    setMessageRecovery(state, payload) {
        state.messageRecovery = payload
    },
};
