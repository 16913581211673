import mutations from "./mutations";
import actions from "./actions";

export default {
  state() {
    return {
      newError: false
    };
  },
  mutations,
  actions,
};
