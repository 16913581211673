export default {
    setDashboardAssets(state, value) {
        state.dashboardAssets = value
    },
    setDashboardLiabilities(state, value) {
        state.dashboardLiabilities = value
    },
    setDashboardChartData(state, value) {
        state.dashboardChartData = value
    },
    setDashboardSelfManaged(state, value) {
        state.dashboardSelfManaged = value
    },
    setDashboardClarityAssets(state, value) {
        state.dashboardClarityAssets = value
    },
    setDashboardBankAccounts(state, value) {
        state.dashboardBankAccounts = value
    },
    setNewsletterSubscriptionState(state, bool) {
        state.isSubscribed = bool
    }
}
