export default {
    // currency
    setCurrencyOptions(state, value) {
        state.currencyOptions = value
    },
    setCurrencyLoading(state, value) {
        state.currencyLoading = value
    },
    setCurrencySelected(state, value) {
        state.currencySelected = value
    },
    // portfolio
    setPortfolioOptions(state, value) {
        // check for error.
        if(value.error){
            // error found so leave payload intact.
            state.portfolioOptions = value
        }else {
            //otherwise split it out
            state.portfolioOptions = value.portfolios
            // and update default where provided.
            if(value.default) {
                state.portfolioSelected = value.default
            }
        }
    },
    setPortfolioIMSList(state, value) {
        //console.log('setPortfolioIMSList', value)
        if(!value.error) {
            const ims = []
            const dfm = []
            //console.log('setting ims list', value)
            for (let portfolio of value.portfolios) {
                if (portfolio.ims === true) {
                    ims.push(portfolio.portfolioplans)
                }
                if (portfolio.dfm === true) {
                    dfm.push(portfolio.portfolioplans)
                }
            }
            state.imsPortfolioList = [...new Set(ims.flat())];
            state.dfmPortfolioList = [...new Set(dfm.flat())];
        }
    },
    setPortfolioLoading(state, value) {
        state.portfolioLoading = value
    },
    setPortfolioSelected(state, value) {
        state.portfolioSelected = value
    },
    setEditPortfolioSelected(state, value) {
        state.editPortfolioSelected = value
    },
    addCustomPortfolio(state, payload) {
        state.portfolioOptions.push(payload)
    },
    deleteCustomPortfolio(state, payload) {
        const idx = state.portfolioOptions.findIndex((e) => {
            return e.uuid === payload
        })
        if(idx !== -1) {
            state.portfolioOptions.splice(idx, 1)
        }
    },
    updateCustomPortfolio(state, payload) {
        // get index
        const idx = state.portfolioOptions.findIndex((e) => {
            return e.uuid === payload.uuid
        })
        // update with new args (not uuid!)
        const original = state.portfolioOptions[idx]
        // return copy of original object only updating the name/plans selected
        // uuid and ims flag untouched
        state.portfolioOptions[idx] = {
            ...original,
            name: payload.name,
            portfolioplans: payload.portfolioplans
        }
    },
    // policy
    setPolicyOptions(state, value) {
        state.policyOptions = value;
    },
    setPolicyLoading(state, value) {
        state.policyLoading = value
    },
    setPolicySelected(state, value) {
        state.policySelected = value
    },
    setPerformancePolicySelected(state, value) {
        state.performancePolicySelected = value
    },
    // people
    setPeopleOptions(state, value) {
        state.peopleOptions = value
    },
    setPeopleLoading(state, value) {
        state.peopleLoading = value
    },
    setPeopleSelected(state, value) {
        state.peopleSelected = value
    },
    // Misc
    toggleFilterPanel(state) {
        state.filterPanelOpen = !state.filterPanelOpen
    },
    setPeriodSelected(state, value) {
        state.periodSelected = value
    },
    invalidateValuationData(state) {
        state.policyOptions = [];
        state.policyLoading = true;
        state.portfolioOptions = [];
        state.portfolioLoading = true;
    }
}
