<template>
  <div class="flex flex-column" :id="item.label">
    <!-- if no submenu items navigate to page, i.e. Dashboard -->
    <router-link :to="item.to" replace v-if="!item.items">
      <div v-if="wideDrawer" class="flex item sidebar-item justify-content-start align-items-center wide top"
           :class="{'active' : isActive(item) }" @click="toggleItem(item.label)">
        <div class="flex align-items-center ml-3">
          <span class="material-icons-round white m-3">{{ item.icon }}</span>
          <span>{{ item.label }}</span>
        </div>
      </div>
    </router-link>
    <!-- if menu has sub items, toggle menu -> all other menu items -->
    <div v-if="wideDrawer && item.items"
         class="flex item sidebar-item justify-content-start align-items-center wide top"
         :class="{'active' : isActive(item) }" @click="toggleItem(item.label)">
      <div class="flex align-items-center justify-content-between ml-3" style="width: 90%">
        <div class="flex align-items-center">
          <span class="material-icons-round white m-3">{{ item.icon }}</span>
          <span>{{ item.label }}</span>
        </div>
        <div class="flex align-items-center">
          <span class="material-icons-round white"
                v-if="!isExpanded && selectedItem === item.label || !isExpanded && selectedItem !== item.label || isExpanded && selectedItem !== item.label">expand_more</span>
          <span class="material-icons-round white" v-if="isExpanded && selectedItem === item.label">expand_less</span>
        </div>
      </div>
    </div>
    <div>
      <div v-if="selectedItem === item.label && item.items && wideDrawer && isExpanded">
        <div v-for="subitem in item.items" :key="subitem.label">
          <div v-if="subitem.items">
            <div
                :key="subitem.label"
                class="item subitem flex item sidebar-item justify-content-between align-items-center my-1 wide"
                :class="{'active' : selectedSubItem === subitem.label}"
                @click="toggle(`${formatter.case(subitem.label, 'kebab')}-menu`, $event);"
            >
              <span>{{ subitem.label }}</span>
              <span class="material-icons-round white mr-3" style="text-transform: unset;">chevron_right</span>
              <Menu
                  :model="subitem.items"
                  v-if="nestedMenu === `${formatter.case(subitem.label, 'kebab')}-menu`"
                  class="fixed-menu expanded"
                  :style="{visibility: nestedMenu === `${formatter.case(subitem.label, 'kebab')}-menu` ? 'visible' : 'hidden'}"
              >
                <template #item="{item}">
                  <a class="p-menuitem-link" role="menuitem" @click="goToInternalLink(subitem.label, item); toggle(`${formatter.case(subitem.label, 'kebab')}-menu`, $event);">
                    <span class="p-menuitem-text">{{ item.label }}</span>
                  </a>
                </template>
              </Menu>
            </div>
          </div>
          <div v-else>
            <template v-if="subitem.to">
              <router-link :to="subitem.to" replace>
                <div
                    :key="subitem.label"
                    class="item subitem flex item sidebar-item justify-content-start align-items-center my-1 wide"
                    :class="{'active' : selectedSubItem === subitem.label}"
                    @click="subitem.isTab ?  goToTobLevelTab(subitem) : toggleSubItem(subitem.label)"
                >
                  {{ subitem.label }}
                </div>
              </router-link>
            </template>
            <a v-else :href="subitem.url" target="_blank">
              <div
                  :key="subitem.label"
                  class="item subitem flex item sidebar-item justify-content-start align-items-center my-1 wide"
                  :class="{'active' : selectedSubItem === subitem.label}"
                  @click="subitem.isTab ?  goToTobLevelTab(subitem) : toggleSubItem(subitem.label)"
              >
                {{ subitem.label }}
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!--      COLLAPSED MENU ONLY BELOW THIS POINT      -->
    <div v-if="!wideDrawer" class="flex flex-column align-items-center menu-box" @click="toggleItem(item.label)">
      <Button
          class="p-button-rounded p-button-outlined my-2 menu-item-btn clarity-btn round"
          :class="{'active' : isActive(item)}"
          @click="item.label === 'Dashboard' ? $router.replace(item.to) : null"
      >
        <span class="material-icons-round white">{{ item.icon }}</span>
      </Button>
      <span class="menu-label">{{ item.shortlabel ?? item.label }}</span>
      <div v-if="item.items && submenu === item.label" class="submenu">
        <TieredMenu :model="item.items" :id="`tiered-menu-${formatter.case(item.label, 'kebab')}`">
          <template #item={item}>
            <div v-if="!item.items">
              <template v-if="item.to">
                <router-link :to="item.to">
                  <a class="p-menuitem-link router-link-active router-link-active-exact py-4" role="menuitem"
                     @click="item.isTab? goToTobLevelTab(item) : toggleSubItem(item.label)">
                    <span class="p-menuitem-text" style="text-transform: capitalize;">{{ item.label }}</span>
                  </a>
                </router-link>
              </template>
              <template v-else>
                <a class="p-menuitem-link router-link-active router-link-active-exact py-4" role="menuitem"
                   :href="item.url" target="_blank">
                  <span class="p-menuitem-text" style="text-transform: capitalize;">{{ item.label }}</span>
                </a>
              </template>
            </div>
            <div v-if="item.items" class="flex">
              <div class="flex align-items-center full-width justify-content-between" @click.stop="toggleSecondLevel(`${formatter.case(item.label,'kebab')}`)">
                <a class="p-menuitem-link router-link-active router-link-active-exact py-4" role="menuitem"
                   @click="toggleSubItem(item.label)">
                  <span class="p-menuitem-text" style="text-transform: capitalize;">{{ item.label }}</span>
                </a>
                <span class="material-icons-round grey mr-3"
                      :data-cy="`submenu-toggle-${formatter.case(item.label, 'kebab')}`">chevron_right</span>
              </div>
              <Menu class="fixed-menu" :model="item.items"
                    v-if="`${formatter.case(item.label, 'kebab')}` === showSecondLevel"
              >
                <template #item="{item}">
                  <a class="p-menuitem-link" role="menuitem" @click="navigateFromSecondLevelMenu(item)"
                     :data-cy="`submenu-item-${formatter.case(item.label, 'kebab')}`">
                    <span class="p-menuitem-text">{{ item.label }}</span>
                  </a>
                </template>
              </Menu>
            </div>
          </template>
        </TieredMenu>
      </div>
    </div>
  </div>
</template>
<script>
import {ref, toRef, computed, inject} from 'vue'
import {useRoute, useRouter} from "vue-router";
import {formatter} from "@/core/services/formatter";
import {useStore} from "vuex";

export default {
  name: 'MenuItem',
  emits: ['setActiveItem', 'setSubmenu', 'setActiveSubItem'],
  props: {
    item: {
      type: Object,
      required: true
    },
    wide: {
      type: Boolean,
    },
    selectedItem: {
      type: String
    },
    submenu: {
      type: String
    },
    selectedSubItem: {
      type: String
    }
  },
  setup(props, context) {
    const wideDrawer = toRef(props, 'wide')
    const showMenu = ref(false)
    const nestedMenu = ref();
    const topPosition = ref();
    const secondLevel = ref(false)
    const expandMenuItem = ref()
    const router = useRouter()
    const route = useRoute()

    const store = useStore()
    const bus = inject('bus')

    const toggleItem = (label) => {
      bus.emit('clearMoreLinks')
      // for wide menu
      nestedMenu.value = false
      topPosition.value = 0
      // for collapsed menu
      secondLevel.value = false
      if (props.selectedItem == label) {
        context.emit('setSubmenu', label)
        expandMenuItem.value = !expandMenuItem.value
      } else {
        context.emit('setSubmenu', label)
        context.emit('setActiveItem', label)
        expandMenuItem.value = true
      }

    }

    const toggleSubItem = (label) => {
      bus.emit('clearMoreLinks')
      context.emit('setActiveSubItem', label)
    }

    const toggle = (name, event) => {
      if (event) {
        topPosition.value = event.screenY - 500
      }
      nestedMenu.value === name ? nestedMenu.value = '' : nestedMenu.value = name
    }

    const getYPos = computed(() => {
      return topPosition.value
    })

    const toggleSecondLevel = (menu) => {
      bus.emit('clearMoreLinks')
      secondLevel.value === menu ? secondLevel.value = false : secondLevel.value = menu
    }

    const showSecondLevel = computed(() => {
      return secondLevel.value
    })

    const isExpanded = computed(() => {
      return expandMenuItem.value
    })

    const goToInternalLink = (label, item) => {
      store.dispatch('setActiveHeaderFromMenu', {
        page: item.to.charAt(0) === '/' ? item.to.substring(1).split('/')[0] : item.to.split('/')[0],
        header: item.label.toLowerCase().replace(/\s/g, '')
      })
      if (label) {
        toggle(`${formatter.case(label, 'kebab')}-menu`);
      }
      router.push(item.to);
    }

    const navigateFromSecondLevelMenu = (item) => {
      let label

      props.item.items.forEach(option => {
        if (option.items) {
          option.items.forEach(subitem => {
            item.label === subitem.label ? label = option.label : null
          })
        }
      })

      goToInternalLink(formatter.case(label, 'kebab'), item)
    }

    const goToTobLevelTab = (item) => {
      goToInternalLink(null, item)
    }

    const getValueBetweenFirstAndSecondSlash = (inputString) => {
        // Split the input string by forward slashes
        const parts = inputString.split('/');
        // Check if there are at least two parts
        if (parts.length >= 2) {
            // Return the second part (index 1)
            return parts[1];
        } else {
            // If there are not enough parts, return the input string itself
            return '';
        }
    }

    const isActive = (item) => {
        const path = route.path;
        if(path === item.to){
            return true;
        }
        const baseOfPath = getValueBetweenFirstAndSecondSlash(path)
        switch(baseOfPath) {
            case '':
                return item.label === 'Dashboard'
            case 'investments':
            case 'ims':
                return item.label === 'Investments'
            case 'securemessaging':
                return item.label === 'Secure Messaging'
            case 'tools':
                return item.label === 'Planning Tools'
            case 'research':
                return item.label === 'Research'
            case 'settings':
                return item.label === 'My Personal Info'
            default:
                return false;
        }
    }

    return {
      formatter,
      getYPos,
      goToInternalLink,
      goToTobLevelTab,
      isExpanded,
      navigateFromSecondLevelMenu,
      nestedMenu,
      showMenu,
      showSecondLevel,
      toggle,
      toggleItem,
      toggleSecondLevel,
      toggleSubItem,
      wideDrawer,
      isActive
    }
  },
}
</script>
<style scoped lang="scss">
.item {
  border-top: 1px solid var(--clarity-light-blue);
  border-bottom: 1px solid var(--clarity-light-blue);
  border-right: 1px solid var(--clarity-light-blue);
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  cursor: pointer;

  span:hover {
    color: var(--clarity-pure-white);
  }
}

.top.active {
  &::before {
    content: '';
    height: 20px;
    width: 10px;
    border-radius: 0px 50px 50px 0px;
    background-color: var(--clarity-pure-white);
    margin-right: 20px;
  }

  > div {
    margin-left: -15px !important;
  }
}

.sidebar-item {
  height: 60px;
  font-size: 22px;
  margin-top: 15px;
  color: var(--clarity-pure-white);

  span:nth-of-type(2),
  .menu-box span:nth-of-type(2) {
    text-transform: uppercase;
  }
  @media only screen and (max-width: 1366px)  {
    font-size: 14px;
    height: 44px;
    margin-top: 5px;
  }
}

.sidebar-item.wide > div {
  width: 336px;
}

.subitem {
  height: 54px;
  font-size: 18px;
  padding-left: 50px;

  a:visited {
    color: var(--clarity-pure-white);
  }
  @media only screen and (max-width: 1366px)  {
    font-size: 14px;
    height: 44px;
    margin-top: 5px;
  }
}

.menu-item-btn {
  color: var(--clarity-pure-white);
  border-color: var(--clarity-light-blue);
  max-height: 7vh;

  &.active {
    background-color: rgb(23, 155, 215, 0.5);
  }
}

.menu-box {
  width: 88px;
  color: var(--clarity-pure-white);
  font-size: 13px;
  cursor: pointer;
  padding-bottom: 10px;

  button {
    span {
      &:hover {
        color: var(--clarity-pure-white);
      }
    }
  }
}

.active {
  background-color: rgb(23, 155, 215, 0.3);
}

.submenu {
  position: fixed;
  left: 160px;

  @include sm {
    left: 140px;
  }
  @include tablet {
    left: 140px;
  }

  :deep(.p-menuitem) {
    width: 240px;
  }

  :deep(.p-tieredmenu) {
    width: fit-content;

    .p-menu {
      left: 420px;
      width: fit-content;
      padding: 0;
    }
  }
}

.wide {
  .submenu {
    :deep(.p-menu) {
      width: fit-content;
      padding: 15px;
    }
  }
}
.menu-label {
  text-align: center;
  text-transform: uppercase;
  font-size: 13px;
  @media only screen and (max-width: 1366px)  {
    display: none;
  }
}

.menu-box:hover .menu-label{
  @media only screen and (max-width: 1366px)  {
    display: block;
    animation: fadeIn 2s;
  }
}
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.p-menuitem:hover {
  .p-menuitem-link.router-link-active .p-menuitem-text {
    color: var(--clarity-blue) !important;
    font-weight: bold;
  }
}

.p-menu-list .p-menuitem:hover {
  .p-menuitem-link .p-menuitem-text {
    color: var(--clarity-blue) !important;
    font-weight: bold;
  }
}


</style>
