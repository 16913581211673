
export default {
    setOBAccounts: (state, data) => {
        state.obAccounts = data;
    },
    setOBProviders: (state, data) => {
        state.obProviders = data;
    },
    setOBProvidersLoadingState: (state, status) => {
      state.obProvidersLoading = status
    },
    setOBAccountsLoadingState: (state, status) => {
        state.obAccountsLoading = status
    },
    setOBAccountsAdding: (state, value) => {
        state.obAccountsAdding = value
    },
    setOBAccountsRenewing: (state, value) => {
        state.obAccountsRenewing = value
    },
    addOBAccounts: (state, data) => {
        data.forEach((account) => {
            state.obAccounts.push(account)
        })
    }
};
