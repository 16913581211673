<template>
  <div>
    <Toast>
      <template #message="slotProps">
        <div class="flex flex-column justify-content-between mr-6">
          <div class="flex">
            <span class="material-icons-round mr-3" :class="getSeverityColour(slotProps.message.severity)">{{ getIcon(slotProps.message.severity) }}</span>
            <h4>{{slotProps.message.summary}}</h4>
          </div>
          <div>
            <p class="ml-6">{{slotProps.message.detail}}</p>
          </div>
        </div>
      </template>
    </Toast>
  </div>
</template>

<script>
export default {
  name: "ToastError",
  setup() {
    const getIcon = (severity) => {
      switch (severity) {
        case 'success':
          return 'check_circle'
        case 'warn':
          return 'report_problem'
        case 'error':
          return 'info'
      }
    }

    const getSeverityColour = (severity) => {
      switch (severity) {
        case 'success':
          return 'green'
        case 'warn':
          return 'orange'
        case 'error':
          return 'red'
      }
    }

    return {
      // newError,
      // showError,
      getIcon,
      getSeverityColour
    }
  }
}
</script>

<style scoped>

</style>
