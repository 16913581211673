import api from '@/core/services/api'
import {redirect_uri} from "../../../core/services/openBanking";
import { toDrupalRequest } from '@/core/helpers';

export default {
    async loadOBAccounts({commit}) {
        commit('setOBAccountsLoadingState', true)
        if(process.env.VUE_APP_DELAY_BACKEND_API) {
            await new Promise(r => setTimeout(r, 2000));
        }
        const response = await api.get(`${toDrupalRequest('/openbanking/accounts')}`)

        if (response) {
            commit('setOBAccounts', response)
            commit('setOBAccountsLoadingState', false)
        }
    },
    async loadOBProviders({commit}) {
        commit('setOBProvidersLoadingState', true)
        if(process.env.VUE_APP_DELAY_BACKEND_API) {
            await new Promise(r => setTimeout(r, 2000));
        }
        const response = await api.get(`${toDrupalRequest('/openbanking/providers')}`)

        if (response) {
            commit('setOBProviders', response)
            commit('setOBProvidersLoadingState', false)
        }
    },
    async submitCode({commit, dispatch}, code) {
        if(process.env.VUE_APP_DELAY_BACKEND_API) {
            await new Promise(r => setTimeout(r, 5000));
        }
        const response = await api.get(`${toDrupalRequest('/openbanking/create?code='+code+'&redirecturi='+redirect_uri)}`)

        if (response) {
            if(response.success && response.accounts){
                // check for an error in the accounts. Else add these new accounts
                commit('addOBAccounts', response.accounts)
                // and invalidate the valuation data.
                commit('invalidateValuationData')
                commit('setOBAccountsAdding', false)
            }else{
                // record the error.
                dispatch('addNotification', {severity: 'error', summary: 'Add Bank Account or Credit Card', detail: 'An unexpected error occurred, if the problem persists please contact us.'})
                commit('setOBAccountsAdding', false)
            }
        }else{
            // record the error.
            dispatch('addNotification', {severity: 'error', summary: 'Add Bank Account or Credit Card', detail: 'An unexpected error occurred, if the problem persists please contact us.'})
            commit('setOBAccountsAdding', false)
        }
    },
    async revokeAccess({commit, dispatch}, id) {
        if(process.env.VUE_APP_DELAY_BACKEND_API) {
            await new Promise(r => setTimeout(r, 2000));
        }

        const response = await api.get(`${toDrupalRequest('/openbanking/revoke?credentials_id='+encodeURIComponent(id))}`)

        if (response) {
            //console.log('success revoking', id)
            commit('setPortfolioLoading', true);
            commit('setPolicyLoading', true);
            dispatch('getPortfolioOptions')
            dispatch('getPolicyOptions')
            dispatch('loadOBAccounts')
            return true
        } else {
            // @TODO: could the standard error message not be stored as a global const?
            dispatch('addNotification', {severity: 'error', summary: 'Open Banking - Revoke Access - Error', detail: 'An unexpected error occurred, if the problem persists please contact us.'})
        }
    },
    async renewAccess({commit, dispatch}, id) {
        if(process.env.VUE_APP_DELAY_BACKEND_API) {
            await new Promise(r => setTimeout(r, 2000));
        }

        const response = await api.get(`${toDrupalRequest('/openbanking/reconfirm?credentials_id='+encodeURIComponent(id)+'&redirecturi='+redirect_uri+'renew')}`)

        if (response) {
            console.log('response is ', response)
            if(response.success && response.url){
                //we need to go to the next stage
                return response.url;
            }else if(!response.success) {
                dispatch('addNotification', {severity: 'error', summary: 'Open Banking - Renew Access - Error', detail: 'An unexpected error occurred, if the problem persists please contact us.'})
                return false
            }else{
                //update is complete so we can reload stuff and the renewal has run.
                //console.log('success revoking', id)
                commit('setPortfolioLoading', true);
                commit('setPolicyLoading', true);
                dispatch('getPortfolioOptions')
                dispatch('getPolicyOptions')
                dispatch('loadOBAccounts')
                return true
            }
        } else {
            // @TODO: could the standard error message not be stored as a global const?
            dispatch('addNotification', {severity: 'error', summary: 'Open Banking - Renew Access - Error', detail: 'An unexpected error occurred, if the problem persists please contact us.'})
            return false
        }
    },
    async renewCode({commit, dispatch}, code) {
        if(process.env.VUE_APP_DELAY_BACKEND_API) {
            await new Promise(r => setTimeout(r, 5000));
        }
        const response = await api.get(`${toDrupalRequest('/openbanking/reconfirm?code='+code+'&redirecturi='+redirect_uri+'renew')}`)

        if (response) {
            if(response.success){
                commit('setPortfolioLoading', true);
                commit('setPolicyLoading', true);
                dispatch('getPortfolioOptions')
                dispatch('getPolicyOptions')
                dispatch('loadOBAccounts')
                commit('setOBAccountsRenewing', false)
            }else{
                // record the error.
                dispatch('addNotification', {severity: 'error', summary: 'Renew Bank Account or Credit Card', detail: 'An unexpected error occurred, if the problem persists please contact us.'})
                commit('setOBAccountsRenewing', false)
            }
        }else{
            // record the error.
            dispatch('addNotification', {severity: 'error', summary: 'Renew Bank Account or Credit Card', detail: 'An unexpected error occurred, if the problem persists please contact us.'})
            commit('setOBAccountsRenewing', false)
        }
    },
};
