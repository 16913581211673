import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";
import dayjs from "dayjs";

export default {
  state() {
    return {
      valuationSummaryChartData: [],
      valuationPolicyChartData: [],
      valuationDate: dayjs().toDate(),
      dateRangeFrom: dayjs().subtract(1, 'year').toDate(),
      dateRangeTo: dayjs().toDate(),
      pdfGraph1: false,
      pdfGraph2: false,
      currentISIN: {},
      fundAllocationDialogData: [],
      fundHistoryDialogData: [],
      fundInfoDialogData: []
    };
  },
  mutations,
  actions,
  getters,
};
