import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default {
    state() {
        return {
            dataFeeds: null,
            dataFeedsLoading: true
        }
    },
    mutations,
    actions,
    getters
}
