export default class Api {
    constructor(axios, apiUrl, baseUrl) {
        this.axios = axios
        this.apiUrl = apiUrl
        this.baseUrl = baseUrl
    }

    async get(...args) {
        const response = await this.axios.get(...args)
        return response.data;
    }

    async post(...args) {
        const response = await this.axios.post(...args)
        return response.data;
    }

    async put(...args) {
        const response = await this.axios.put(...args)
        return response.data;
    }

    async patch(...args) {
        const response = await this.axios.patch(...args)
        return response.data;
    }

    async delete(...args) {
        const response = await this.axios.delete(...args)
        return response.data;
    }
}