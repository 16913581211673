export default {
    setPreferences(state, payload) {
        for (const [key, value] of Object.entries(payload)) {
            let storeValue = value
            if(key === 'elementVisibility'){
                storeValue = JSON.parse(storeValue)
            }
            state[key] = storeValue
            if (key === 'defaultCurrency') {
                state.reportingCurrency = storeValue
            }
        }
    }
}
