import dayjs from "dayjs";

export default {
    // generate the filters need to make a D9 valuation call.
    valuationCallFilters(state, getters, rootState) {
        const returnObject = {};
        if (rootState.selections.portfolioSelected) {
            returnObject.portfolio = rootState.selections.portfolioSelected;
        } else {
            returnObject.portfolio = 'all';
        }
        if (rootState.selections.peopleSelected && rootState.selections.peopleSelected.length > 0) {
            returnObject.people = [];
            rootState.selections.peopleSelected.forEach((person) => {
                returnObject.people.push(person.uuid);
            })
        } else {
            returnObject.people = ['all']
        }
        return returnObject;
    },
    valuationDateCallFilters(state, getters) {
        // version of filters that includes the date.
        const returnObject = getters.valuationCallFilters;
        returnObject.date = new dayjs(state.valuationDate).format('YYYY-MM-DD');
        return returnObject;
    },
    valuationDate(state) {
        return new dayjs(state.valuationDate)
    },
    getCurrentISIN(state) {
      return state.currentISIN
    },
    getFundAllocationModalData(state) {
        return state.fundAllocationDialogData
    },
    getFundHistoryModalData(state) {
        return state.fundHistoryDialogData
    },
    // getHistoryModalName
    getFundInfoModalData(state) {
        return state.fundInfoDialogData
    },
}
