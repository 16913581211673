import api from '@/core/services/api'
import { toDrupalRequest } from '@/core/helpers';
import GoalSeekDataFormatter from '@/core/services/goalSeekDataFormatter'
// import goalSeek  from '@/core/services/goalseek'
import NewPerformanceCalc from '@/core/services/newPerfomanceCalc'
import dayjs from 'dayjs'

export default {
    async getPerformanceTransactionsForPolicy({ commit, dispatch, getters }, policy) {
        const endpoint = '/transaction/plan/alltransactions/'+policy.value+'/'+policy.from+'::'+policy.to
        const response = await api.get(`${toDrupalRequest(endpoint)}`)

        if(response.transactions){
            commit('setPerformanceTransactions', {'policy' : policy, 'response':response.transactions})
            commit('setMoneyInOut',  {'policy' : policy, 'response':response.transactions})
            setTimeout(() => {
                // time for commits to act
                dispatch('doGoalSeek', { uuid: policy.value, valuationDate: getters.valuationDate})
            }, 1000)
        }
    },
    setPerformanceTransactionTotal({ commit }, payload) {
        commit('setPerformanceTransactionTotal', payload)
    },
    async doGoalSeek({ getters, commit }, payload) {
        const moneyIn = getters.performanceTransactions(payload.uuid, 'in')
        const moneyOut = getters.performanceTransactions(payload.uuid, 'out')
        const vd = payload.valuationDate
        const f = new dayjs(vd).subtract(1, 'year')
        const t = new dayjs(vd)
        const past = getters.pastValue(payload.uuid)
        const present = getters.presentValue(payload.uuid)

        if (moneyIn, moneyOut, past, present) {
            const gsdf = new GoalSeekDataFormatter()
            const gsa = gsdf.getDateArray(f, t)
            const filteredIn = gsdf.filterTransactions(gsa, moneyIn)
            const i = gsdf.calculateMonthlyTotals(filteredIn)
            const filteredOut = gsdf.filterTransactions(gsa, moneyOut)
            const o = gsdf.calculateMonthlyTotals(filteredOut)
            const display = gsdf.cleanseForDisplay(gsa, i, o)

            const g = new NewPerformanceCalc({
                performance: 1,
                openingBalance: past,
                closingBalance: present,
                moneyIn: i,
                moneyOut: o,
            });
            const gsvalue = g.performanceValue()

            commit('setGoalSeek', {policy: payload.uuid, goalSeek: { value: gsvalue ? gsvalue  : 'NA', months: display } })
        }
    }
}
