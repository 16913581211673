<template>
    <Modal id="autoLogoutWarningModal" :width="`644px`">
        <template #body>
            <div class="flex flex-column">
                <div class="flex justify-content-between align-items-center title">
                    <h3 class="blue">YOUR SESSION WILL EXPIRE SOON!</h3>
                    <Button class="clarity-close-modal-btn" @click="closeModal"><span class="material-icons-round grey">close</span></Button>
                </div>
            </div>

            <div class="flex content">
                <div class="flex flex-column" style="width:440px;">
                    <p class="body-text dark-grey">If you don’t continue to use the clarity app, your session will expire in <span class="blue"><b>5 minutes</b></span>.</p>
                    <p class="body-text dark-grey">This logout is automatic and it helps you to keep your account safe and protected.</p>
                    <Button @click="closeModal" class="clarity-btn clarity-gradient-orange" label="Continue with your session"></Button>
                </div>
                <div class="flex flex-column">
                    <img src="@/assets/img/autologout.png" alt="Logout Warning">
                </div>
            </div>
        </template>
    </Modal>
</template>

<script>
import {inject} from 'vue'
import Modal from '@/components/common/Modal';
import { useStore } from 'vuex';

export default {
    setup() {
        const store = useStore();
        const bus = inject('bus');

        const closeModal = () => {
            bus.emit('closeAutoLogoutWarning');
            store.dispatch('setActiveModal');
        }

        return {
            closeModal
        }
    },
    components: {
        Modal
    }
}
</script>

<style scoped>
    div:deep(.modal-close) {
        display: none;
    }
    div:deep(.modal-body) {
        overflow-x: hidden !important;
    }
    .title {
        padding: 30px 30px 0px 30px;
    }
    .content {
        padding: 30px 30px 30px 30px;
    }
</style>
