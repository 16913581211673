export default {
    setCognitoUser (state, cognitoUser) {
        state.cognitoUser = cognitoUser;
        state.isAuthenticated = !!cognitoUser;
    },
    resetCognitoUser (state) {
        state.cognitoUser = false;
        state.isAuthenticated = false;
    },
    setPreferredMFA (state, mfa) {
        state.preferredMFA = mfa;
    },
    setTargetUUID (state, uuid) {
        state.targetUUID = uuid;
    },
    setNewUserEmail (state, email) {
        state.newUserEmail = email;
    },
    newUserStageOneData (state, data) {
        state.userStageOneData = data;
    },
    newUserStageTwoData (state, data) {
        state.userStageTwoData = data;
    },
    newUserStageThreeData (state, data) {
        state.userStageThreeData = data;
    },
    didAutoLogout (state) {
        state.userDidAutoLogout = true;
    },
    resetAutoLogout (state) {
        if(state.userDidAutoLogout === true) {
            state.userDidAutoLogout = false;
        }
    }
}
