import { useToastService } from '@/core/services/toastService'

export default {
    addNotification({ commit }, error) {
        const toast = useToastService()
        // console.log('error1: call mutation', error);
        // if error commit it
        if (error.severity === 'error') {
            commit('addNewError', error);
        }
        // pop notification with toast
        toast.showToast(error)
    }
}
