import getters from "./getters"
import mutations from "./mutations";
import actions from "./actions";

export default {
    state() {
        return {
            mode: 'asset',
            productTypes: [],
            commonChoices: [],
            selfManaged: [],
            selfManagedDetails: {
            },
            finalPayload: {},
            selectedNewSelfManagedType: null,
            selectedProduct: [],
            split: null,
            holdings: [],
            editing: null,
            fundlinks: [],
            isEditingExistingValuationHolding: false,
            isEditingExistingValuationHoldingIndex: null,
            // liability versions
            liabilityTypes: [],
            selfManagedLiabilities: [],
            selfManagedLiabilityDetails: {
            },
        }
    },
    actions,
    getters,
    mutations
}
