<template>
    <div class="p-0 bg-cover bg-center" :class="imageSelect" >
        <div class="clarity-logo">
            <img src="../assets/logos/clarity-logo.png">
        </div>
        <div style="height: 100vh;">
          <div class="flex justify-content-center flex-wrap" style="height: 100vh;">
            <div class="flex align-items-center justify-content-center">
              <img v-if="imageSelect == 'createAccountBackgroundImg'" class="login-people" style="width: 80%;" src="../assets/img/createAccountPeopleImg.png">
              <img v-if="imageSelect == 'loginBackgroundImg'" class="login-people" style="width: 80%;" src="../assets/img/StickyFamily.png">
              <img v-if="imageSelect == 'resetBackgroundImg'" class="login-people" style="width: 80%;" src="../assets/img/resetPwdPersonImg.png">
            </div>
          </div>
        </div>
        <div class="welcomeLinks">
            <p @click="loadWelcomeTour"><img src="../assets/icons/play-icon.png">Welcome Tour</p>
            <p><a :href="clarityURL+'/newfaqs'" target="_blank"><img src="../assets/icons/faqs-icon.png">FAQs</a></p>
        </div>
        <Modal v-if="showWelcomeTour" id="modal-welcome-tour">
          <template #body>
            <content-card-full>
              <template #title>
                <div class="flex justify-content-between title align-items-center clarity-margin-bottom-s">
                  <h2>Welcome Tour</h2>
                  <Button class="clarity-close-modal-btn" @click="closeModal"><span
                      class="material-icons-round grey">close</span></Button>
                </div>
                <div class="sub-heading">
                    Watch our welcome tour to familiarise yourself with the <span class="light-blue bold">clarity platform</span> and discover how to get the most
                  out of the tools available to you.
                </div>
              </template>
              <template #content-full>
                  <div class="welcome-video">
                      <div id="vimeoVideo" style='padding:56.25% 0 0 0;position:relative;'>
                          <iframe src='https://vimeo.com/showcase/10767744/embed' allowfullscreen frameborder='0' style='position:absolute;top:0;left:0;width:100%;height:100%;'></iframe>
                      </div>
                  </div>
              </template>
            </content-card-full>
          </template>
        </Modal>
    </div>
</template>

<script>
import { ref } from 'vue';
import Modal from '@/components/common/Modal'
import ContentCardFull from '@/components/common/ContentCardFull'

export default {
  props: {
    imageSelect: String
  },
  components: {
    ContentCardFull,
    Modal
  },
  setup() {
    const showWelcomeTour = ref(false);
    const clarityURL = process.env.VUE_APP_CLARITYURL;

    const loadWelcomeTour = () => {
      //console.log('show welcome tour');
      if(showWelcomeTour.value) {
        showWelcomeTour.value = false;
      } else {
        showWelcomeTour.value = true;
      }
    }

    const closeModal = () => {
      showWelcomeTour.value = false;
    }

    return {
      showWelcomeTour,
      loadWelcomeTour,
      closeModal,
      clarityURL
    }
  }
}
</script>

<style scoped>
    .clarity-logo {
        position: absolute;
        top: 30px;
        left: 30px;
    }
    .welcomeLinks {
        position: absolute;
        left: 30px;
        bottom: 30px;
        color: var(--clarity-light-grey);
        cursor: pointer;
    }
    .welcomeLinks a:visited,
    .welcomeLinks a:link,
    .welcomeLinks a:active,
    .welcomeLinks a {
        color: var(--clarity-light-grey);
        text-decoration:none;
        cursor: pointer;
    }
    .welcomeLinks img {
        margin-right: 10px;
        vertical-align: middle;
    }
    .welcomeLinks a:hover, .welcomeLinks a:visited:hover{
      color: var(--clarity-pure-white);
      text-decoration:none;
      cursor: pointer;
    }
    .loginBackgroundImg {
      /* background-image: url('../assets/img/login-image.png'); */
      background-image: url('../assets/img/createAccountImg.png');
      height: 100vh;
    }
    .resetBackgroundImg {
      background-image: url('../assets/img/createAccountImg.png');
      height: 100vh;
    }
    .createAccountBackgroundImg {
      background-image: url('../assets/img/createAccountImg.png');
      height: 100vh;
    }

    #modal-welcome-tour {
      /* needs to be high to sit above all other elements, including nav*/
      z-index: 3104;
    }

    #modal-welcome-tour:deep(.modal-body) {
      max-width: 50%;
    }

    #modal-welcome-tour:deep(.modal-close) {
      display: none !important;
    }

    #modal-welcome-tour:deep(.clarity-card-content) {
      width: unset !important;
    }

    #modal-welcome-tour:deep(.content-title) {
      padding: 30px;
    }
    #modal-welcome-tour:deep(.content-full) {
      padding: 0;
    }
    .sub-heading {
      font-size: 18px;
    }

    .welcome-video {
        width: 100%;
        height: auto;
        padding: 0px 30px 35px 30px;
    }
</style>
