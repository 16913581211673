/* istanbul ignore file */
import {faker} from "@faker-js/faker"

export const makeRecipients = (numToMake) => {
    const list = []
    for (let i = 0; i <= numToMake; i++) {
        list.push(makeRecipient())
    }

    return [...list, ...makeCoreRecipients()]
}

export const makeRecipient = () => {
    const first = faker.name.firstName()
    const last = faker.name.lastName()
    return {
        "uuid": faker.datatype.uuid(),
        "name": first + ' ' + last,
        "email": `${first}.${last}@clarityglobal.com`
    }
}

export const makeCoreRecipients = () => {
    return [
        {
            "uuid": 'c_24',
            "name": 'Glynn Bolton',
            "email": `glynn.bolton@clarityglobal.com`
        },
        {
            "uuid": "c_18",
            "name": "Daniel Smith",
            "email": "Daniel.Smith@clarityglobal.com"
        }
    ]
}