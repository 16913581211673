export default {
    state() {
        return {
            inflationRate: 2.5,
            growthRate: 4.5,
            savingsRate: 5.0,
            projectionYears: 1,
            startingValue: 0,
            regularSavings: 0,
            savingsTimeframe: 1,
            mortgageInterestRate: 4.00,
            mortgageDefaultTerm: 25
        }
    }
}
