<template>
    <div class="pad-x-30 flex-grow-1" data-cy="nav-header-search-bar">
        <span class="cl-input p-input-icon-left w-full">
          <i v-if="searchTerm !== ''" @click="runSearch" class="pi pi-search" style="color:#0058A9;cursor: pointer;"></i>
          <i v-if="searchTerm === ''" @click="runSearch" class="pi pi-search" style="color: #8b96a2;"></i>
          <InputText @keyup.enter="runSearch" v-model="searchTerm" type="text" placeholder="Search..." class="p-inputtext-lg w-full"/>
        </span>
    </div>
    <div v-if="mobile" class="clarity-gradient-orange clarity-btn flex align-content-center justify-content-center" @click="runSearch" style="width: 5px;" >
        <i class="pi pi-search" style="color: #ffffff; padding-top: 20px;"></i>
    </div>
</template>

<script>
import {inject, ref} from "vue";
import {useStore} from "vuex";

export default {
    name: "Search Input",
    props: {
        mobile: {
            type: Boolean,
            default: false
        }
    },
    setup() {

        const store = useStore()
        const bus = inject('bus')
        const searchTerm = ref('');
        const finalSearchTerm = ref('');

        const runSearch = () => {
            if(searchTerm.value !== '') {
                finalSearchTerm.value = searchTerm.value;
                bus.emit('clientSearchTerm',finalSearchTerm.value);
                store.dispatch('setActiveModal', {modal: 'loadSearchResults'});
                searchTerm.value = '';
            }
        }

        return {
            runSearch,
            searchTerm,
            finalSearchTerm,
        }
    }
}
</script>

<style scoped>

</style>
