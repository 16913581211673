<template>
  <Modal id="modal-welcome-tour">
    <template #body>
      <content-card-full>
        <template #title>
          <div class="flex justify-content-between title align-items-center clarity-margin-bottom-s">
            <h2>Welcome Tour</h2>
            <Button class="clarity-close-modal-btn" @click="closeModal"><span
                class="material-icons-round grey">close</span></Button>
          </div>
          <div class="sub-heading">
              Watch our welcome tour to familiarise yourself with the <span class="light-blue bold">clarity platform</span> and discover how to get the most
            out of the tools available to you.
          </div>
        </template>
        <template #content-full>
          <div class="welcome-video">
            <div id="vimeoVideo" style='padding:56.25% 0 0 0;position:relative;'>
              <iframe :src='videoURL' allowfullscreen frameborder='0' style='position:absolute;top:0;left:0;width:100%;height:100%;'></iframe>
            </div>
          </div>
        </template>
      </content-card-full>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/common/Modal'
import ContentCardFull from '@/components/common/ContentCardFull'
import {useStore} from "vuex";
import {ref} from 'vue';

export default {
  name: "WelcomeTourModal",
  components: {
    ContentCardFull,
    Modal
  },
  setup() {
    const store = useStore()
    const videoURL = ref('https://player.vimeo.com/video/878205056?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479%22');

    const closeModal = () => {
      var div = document.getElementById('vimeoVideo');
      var iframe = div.getElementsByTagName('iframe')[0].contentWindow;
      iframe.postMessage('{"method":"pause"}', '*');
      store.dispatch('setActiveModal');
    }

    return {
      closeModal,
      videoURL
    }
  }
}
</script>

<style scoped>
#modal-welcome-tour {
  /* needs to be high to sit above all other elements, including nav*/
  z-index: 3104;
}

#modal-welcome-tour:deep(.modal-body) {
  width: 950px;
}

#modal-welcome-tour:deep(.modal-close) {
  display: none !important;
}

#modal-welcome-tour:deep(.clarity-card-content) {
  width: unset !important;
}

#modal-welcome-tour:deep(.content-title) {
  padding: 30px;
}
#modal-welcome-tour:deep(.content-full) {
  padding: 0;
}
.sub-heading {
  font-size: 18px;
}

.welcome-video {
    width: 100%;
    height: auto;
    padding: 0px 30px 35px 30px;
}
</style>
