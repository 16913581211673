import dayjs from "dayjs";
import GoalSeekDataFormatter from '@/core/services/goalSeekDataFormatter'
// import goalSeek  from '@/core/services/goalseek'
import NewPerformanceCalc from '@/core/services/newPerfomanceCalc'

export default {
    performanceTransactions: (state, getters) => (policy, direction) => {
        let rtn = getters.findPolicyByID(policy)

        return rtn && rtn.transactions ? rtn.transactions[direction == 'in' ? 'credits' : 'debits' ] : 0
    },
    performanceTotal: (state, getters) => (policy, direction) => {
        let rtn = getters.findPolicyByID(policy)

        return rtn && rtn.totals ? rtn.totals[direction] : 0
    },
    pastValue: (state, getters) => (policy) => {
        let rtn = getters.findPolicyByID(policy)
        return rtn && rtn.valuations ? rtn.valuations['past'] : null

    },
    presentValue: (state, getters) => (policy) => {
        let rtn = getters.findPolicyByID(policy)

        return rtn && rtn.valuations ? rtn.valuations['present'] : null

    },
    getGoalSeek: (state, getters) => (policy) => {

        const moneyIn = getters.performanceTransactions(policy, 'in')
        const moneyOut = getters.performanceTransactions(policy, 'out')
        const vd = getters.valuationDate
        const f = new dayjs(vd).subtract(1, 'year')
        const t = new dayjs(vd)
        const past = getters.pastValue(policy)
        const present = getters.presentValue(policy)

        if (moneyIn, moneyOut, past, present) {
            const gsdf = new GoalSeekDataFormatter()
            const gsa = gsdf.getDateArray(f, t)
            const filteredIn = gsdf.filterTransactions(gsa, moneyIn)
            const i = gsdf.calculateMonthlyTotals(filteredIn)
            const filteredOut = gsdf.filterTransactions(gsa, moneyOut)
            const o = gsdf.calculateMonthlyTotals(filteredOut)
            const display = gsdf.cleanseForDisplay(gsa, i, o)

            const g = new NewPerformanceCalc({
                performance: 1,
                openingBalance: past,
                closingBalance: present,
                moneyIn: i,
                moneyOut: o,
            });
            const gsvalue = g.performanceValue()

            return { percent: gsvalue ? gsvalue  : 'NA', months: display }
        }else{
            return { percent: 'NA', months: [] }
        }

    },
    allPolicyPerformance: (state, getters, rootState) => {

        const policies = getters.policyOptions;
        const policySelected = rootState.selections.policySelected;
        let allPerformance = true
        let arr = []
        policies.forEach(parent => {
            parent.items.forEach(policy => {
                if ((policySelected && policySelected.value === policy.planstring) || !policySelected) {
                    arr.push(policy.performance)
                }
            })
        });

        allPerformance = !arr.includes(false)

        return allPerformance
    },
    findPolicyByID: (state) => (ID) => {
        return state.performanceTransactions.find(pol => {
            if(pol.key == ID) {
                return pol
            }
        })
    }
}
