export default {
    // currency
    currencySelected: (state) => {
        return state.currencySelected
    },
    currencySymbol: (state, getters) => {
        const c = getters.currencySelected
        if(state.currencyOptions) {
            const s = state.currencyOptions.filter(cur => {
                if (cur.currencyCode == c) {
                    return cur
                }
            })

            return s[0].currencySymbol
        }else{
            return '£';
        }
    },
    currencyFromCode: (state) => (code) => {
      return state.currencyOptions.find(cur => cur.currencyCode === code)
    },
    currencyOptions: (state) => {
      return state.currencyOptions
    },
    // portfolio
    portfolioSelected: (state) => {
        return state.portfolioSelected
    },
    fullPortfolioSelected: (state) => {
        if(state.portfolioOptions && state.portfolioOptions.length) {
            return state.portfolioOptions.find(obj => {
                return obj.uuid === state.portfolioSelected
            })
        }else{
            return false;
        }
    },
    fullEditPortfolioSelected: (state) => {
        if(state.portfolioOptions && state.portfolioOptions.length) {
            return state.portfolioOptions.find(obj => {
                return obj.uuid === state.editPortfolioSelected
            })
        }else{
            return false;
        }
    },
    portfolioOptions (state) {
        return state.portfolioOptions
    },
    // policy
    policySelected : (state) => {
        return state.policySelected
    },
    performancePolicySelected : (state) => {
        return state.performancePolicySelected
    },
    policyBasicInfo: (state) => (planstring) => {
        return state.policyOptions.find(policy => policy.planstring === planstring)
    },
    policyIsIMS: (state) => (planstring) => {
      return state.imsPortfolioList.includes(planstring)
    },
    policyIsDFM: (state) => (planstring) => {
        return state.dfmPortfolioList.includes(planstring)
    },
    policyUUIDArray: (state, getters) => {
        const uuidArray = [];
        getters.policyOptions.forEach((policyGroup) => {
            policyGroup.items.forEach((policy) => {
                uuidArray.push(policy.value);
            });
        })
        return uuidArray;
    },
    policyOptions: (state, getters) => {

        //policy options needs to be calculated based on the data stored and the other filters selected.
        const policyOptions = [];
        const pens = [];
        const isa = [];
        const nonpens = [];

        let peopleFilter = false;
        let policyFilter = false;

        if(state.peopleSelected && state.peopleSelected.length > 0){
            peopleFilter = state.peopleSelected;
        }
        const fullPortfolioSelected = getters.fullPortfolioSelected;
        if(fullPortfolioSelected){
            policyFilter = fullPortfolioSelected.portfolioplans;
        }

        if(state.policyOptions && state.policyOptions.length) {
            state.policyOptions.forEach((policy) => {
                let peopleFilterPass = false;
                let portfolioFilterPass = false;
                if (peopleFilter) {
                    peopleFilter.forEach((person) => {
                        if (policy.ownership.includes(person.uuid)) {
                            peopleFilterPass = true;
                        }
                    })
                } else {
                    peopleFilterPass = true;
                }
                if (policyFilter) {
                    if (policyFilter.includes(policy.planstring)) {
                        portfolioFilterPass = true;
                    }
                } else {
                    portfolioFilterPass = true;
                }

                if (policy.productType === 'pens' && peopleFilterPass && portfolioFilterPass) {
                    pens.push({label: policy.name, value: policy.planstring, performance: policy.performance});
                } else if (policy.productType === 'isa' && peopleFilterPass && portfolioFilterPass) {
                    isa.push({label: policy.name, value: policy.planstring, performance: policy.performance});
                } else if (policy.productType === 'nonpens' && peopleFilterPass && portfolioFilterPass) {
                    nonpens.push({label: policy.name, value: policy.planstring, performance: policy.performance});
                }
            })
        }

        if(pens.length > 0){
            policyOptions.push({
                label: "Pension",
                items: pens
            })
        }
        if(isa.length > 0){
            policyOptions.push({
                label: "ISA",
                items: isa
            })
        }
        if(nonpens.length > 0){
            policyOptions.push({
                label: "Non Pension",
                items: nonpens
            })
        }
        return policyOptions
    },
    // similar to policyOptions but not filtered by the selected policy
    fullPolicyOptions: (state) => {
        const policyOptions = [];
        const pens = [];
        const isa = [];
        const nonpens = [];

        let peopleFilter = false;

        if(state.peopleSelected && state.peopleSelected.length > 0){
            peopleFilter = state.peopleSelected;
        }

        if(state.policyOptions && state.policyOptions.length) {
            state.policyOptions.forEach((policy) => {
                let peopleFilterPass = false;
                if (peopleFilter) {
                    peopleFilter.forEach((person) => {
                        if (policy.ownership.includes(person.uuid)) {
                            peopleFilterPass = true;
                        }
                    })
                } else {
                    peopleFilterPass = true;
                }

                if (policy.productType === 'pens' && peopleFilterPass) {
                    pens.push({label: policy.name, value: policy.planstring});
                } else if (policy.productType === 'isa' && peopleFilterPass) {
                    isa.push({label: policy.name, value: policy.planstring});
                } else if (policy.productType === 'nonpens' && peopleFilterPass) {
                    nonpens.push({label: policy.name, value: policy.planstring});
                }
            })
        }

        if(pens.length > 0){
            policyOptions.push({
                label: "Pension",
                items: pens
            })
        }
        if(isa.length > 0){
            policyOptions.push({
                label: "ISA",
                items: isa
            })
        }
        if(nonpens.length > 0){
            policyOptions.push({
                label: "Non Pension",
                items: nonpens
            })
        }
        return policyOptions
    },
    // people
    peopleSelected: (state) => {
        return state.peopleSelected
    },
    personFromUuid: (state) => (uuid) => {
        if(state.peopleOptions) {
            const found = state.peopleOptions.find(person => person.uuid == uuid);
            if(found){
                return found
            }else{
                return {
                    uuid: uuid,
                    name: uuid
                }
            }
        }else{
            return {
                uuid: uuid,
                name: uuid
            }
        }
    },
    peopleOptions : (state) => {
        return state.peopleOptions
    },
    // Misc
    filterPanelOpen: (state) => {
        return state.filterPanelOpen
    },
    periodSelected: (state) => {
        return state.periodSelected
    }
}
