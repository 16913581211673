import api from '@/core/services/api'
import { toDrupalRequest } from '@/core/helpers';
import bus from "@/core/services/bus";

export default {
    async getTransactionsForPolicy({ commit }, policy) {
        const endpoint = '/transaction/plan/alltransactions/'+policy.value+'/'+policy.from+'::'+policy.to
        const response = await api.get(`${toDrupalRequest(endpoint)}`)

        if(response.transactions){
            commit('setPolicyTransactions', {'policy' : policy, 'response':response.transactions})
            commit('setPerformanceTransactions', {'policy' : policy, 'response':response.transactions})
            commit('setMoneyInOut',  {'policy' : policy, 'response':response.transactions})
            bus.emit('transactionsLoaded')
        }
    }
}
