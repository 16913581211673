import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
    state() {
        return {
            approvalRecords: [],
            approvedRecords: [],
            recentApprovals: [],
            markAll: false
        }
    },
    mutations,
    actions,
    getters
}
