import api from '@/core/services/api'
import { toDrupalRequest } from '@/core/helpers';
import { loadPresentValues } from '@/core/services/documentExport/ValuationDataExtractor';
import dayjs from "dayjs";

export default {
    async getDashboardChartTotals({ getters, commit }, filter) {
      const chartTotalsEndpoint = '/valuation/summary/charttotals';
      const response = await api.get(`${toDrupalRequest(chartTotalsEndpoint, filter)}`)

      const filterString = JSON.stringify(filter);
      const filters = getters.valuationDateCallFilters;
      const comparisonString = JSON.stringify(filters);

      if(filterString === comparisonString) {
          if (response.error) {
              commit('setDashboardAssets', response);
              commit('setDashboardLiabilities', response);
          } else {
              commit('setDashboardAssets', response.chartTotalsData[0].assets);
              commit('setDashboardLiabilities', response.chartTotalsData[0].liabilities);
          }
      }
    },
    async getDashboardChartData({ getters, commit }, filter) {
        const chartDataEndpoint = '/valuation/summary/chart';

        const response = await api.get(`${toDrupalRequest(chartDataEndpoint, filter)}`)

        const filterString = JSON.stringify(filter);
        const filters = getters.valuationDateCallFilters;
        filters.period = getters.periodSelected;
        const comparisonString = JSON.stringify(filters);

        if(filterString === comparisonString) {
            // only commit when the match is correct.
            commit('setDashboardChartData', response.chartData);
        }
    },
    async getDashboardValuationTotals({ commit }, fullPortfolio) {
        // do something here.
        //console.log('full portfolio is  ', fullPortfolio);
        let clarityTotal = 0;
        let bankTotal = 0;
        let selfManagedTotal = 0;
        if(fullPortfolio && fullPortfolio.portfolioplans){
            await Promise.all(fullPortfolio.portfolioplans.map(async (planstring) => {
                const planValue = await loadPresentValues(planstring, dayjs());
                // check if it is self managed or bank account
                if(planstring.startsWith("OB::")){
                    bankTotal += planValue
                }else if(planstring.startsWith("SM::")){
                    selfManagedTotal += planValue
                }else{
                    clarityTotal += planValue
                }
            }));
        }
        commit('setDashboardClarityAssets', clarityTotal)
        commit('setDashboardBankAccounts', bankTotal)
        commit('setDashboardSelfManaged', selfManagedTotal)
    },
    async getNewsletter({commit}) {
        //console.log('getting newsletter')
        const response = await api.get(`${toDrupalRequest('/newsletter')}`)
        if (response) {
            commit('setNewsletterSubscriptionState', response.status)
        }
    },
    async subscribeNewsletter({commit, dispatch}) {
        const response = await api.post(`${toDrupalRequest('/newsletter')}`, JSON.stringify({}), {headers: {'Content-Type': 'application/json'}})
        if (response) {
            dispatch('setLoading', {id: 'newsletterSignup', state: false})
            commit('setNewsletterSubscriptionState', response.status)
            dispatch('addNotification',{severity: 'success', summary: 'Newsletter Signup', detail: `You are now subscribed to the clarity newsletter`, life: 4000})
        }
    }
}
