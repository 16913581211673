import mutations from "./mutations"
import actions from "./actions"
import getters from "./getters"

export default {
    state() {
        return {
            tabHeaders: {
                investments: [
                    {
                        'label': 'Valuation',
                        'active': true,
                        'key': 'valuation',
                        'to': 'valuation'
                    },
                    {
                        'label': 'Asset Allocation',
                        'active': false,
                        'key': 'assetallocation',
                        'to': 'assetallocation'
                    },
                    {
                        'label': 'Transactions',
                        'active': false,
                        'key': 'transactions',
                        'to': 'transactions'
                    }
                ],
                ims: [
                    {
                        'label': 'IMS HOME',
                        'active': true,
                        'key': 'imshome',
                        'to': 'imshome'
                    },
                    {
                        'label': 'IMS FAQS AND INFO',
                        'active': false,
                        'key': 'imsfaqs',
                        'to': 'imsfaqs'
                    }
                ],
                securemessaging: [
                    {
                        'label': 'Inbox',
                        'active': true,
                        'key': 'inbox',
                        'to': 'inbox'
                    },
                    {
                        'label': 'Drafts',
                        'active': false,
                        'key': 'drafts',
                        'to': 'drafts'
                    },
                    {
                        'label': 'Sent',
                        'active': false,
                        'key': 'sent',
                        'to': 'sent'
                    },
                    {
                        'label': 'Archive',
                        'active': false,
                        'key': 'archived',
                        'to': 'archived'
                    }
                ],
                adminsecuremessaging: [
                    {
                        'label': 'Inbox',
                        'active': true,
                        'key': 'inbox',
                        'to': 'inbox'
                    },
                    {
                        'label': 'Drafts',
                        'active': false,
                        'key': 'drafts',
                        'to': 'drafts'
                    },
                    {
                        'label': 'Sent',
                        'active': false,
                        'key': 'sent',
                        'to': 'sent'
                    },
                    {
                        'label': 'Archive',
                        'active': false,
                        'key': 'archived',
                        'to': 'archived'
                    }
                ],
                research: [
                    {
                        'label': 'Research Library',
                        'active': true,
                        'key': 'researchlibrary',
                        'to': 'library'
                    },
                    {
                        'label': 'Investment Buy List',
                        'active': false,
                        'key': 'investmentbuylist',
                        'to': 'buylist'
                    }
                ],
                tools: [
                    {
                        'label': 'WealthPlan',
                        'active': false,
                        'key': 'wealthplan',
                        'to': 'wealthplan'
                    },
                    {
                        'label': 'Calculators',
                        'active': true,
                        'key': 'calculators',
                        'to': 'calculators'
                    }
                    /*
                    {
                        'label': 'Budgeting',
                        'active': false,
                        'key': 'budgeting',
                        'to': 'budgeting'
                    }
                     */
                ],
                settings: [
                    {
                        'label': 'Personal',
                        'active': true,
                        'key': 'personal',
                        'to': 'personal'
                    },
                    {
                        'label': 'Website',
                        'active': true,
                        'key': 'website',
                        'to': 'websettings'
                    },
                    {
                        'label': 'Risk Profiler',
                        'active': true,
                        'key': 'riskprofiler',
                        'to': 'riskprofiler'
                    },

                ]
            },
            tabView: 'headers'
        }
    },
    mutations,
    actions,
    getters
}
