import {createRouter, createWebHistory} from 'vue-router'
import store from '@/store'
import bus from "@/core/services/bus";

const mailbox = import(/* webpackChunkName: "mailbox" */ '@/components/tabbed_components/Mailbox.vue')
const routes = [
    {
        path: '/',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
        meta: {
            layout: 'AppLayoutMain',
            requiresAuth: true
        }
    },
    {
        path: '/investments',
        name: 'Investments',
        component: () => import(/* webpackChunkName: "investments" */ '../views/Investments.vue'),
        // CHILD ROUTES ARE FOR TABBED COMPONENTS THAT APPEAR TOGETHER, NOT INDIVIDUALLY
        children: [
            {
                path: 'valuation',
                component: () => import(/* webpackChunkName: "valuation" */ '@/components/tabbed_components/TabValuation'),
                meta: {
                    isTab: true
                }
            },
            {
                path: 'assetallocation',
                component: () => import(/* webpackChunkName: "valuation" */ '@/components/tabbed_components/TabAssetAllocation'),
                meta: {
                    isTab: true
                }
            },
            {
                path: 'transactions',
                component: () => import(/* webpackChunkName: "valuation" */ '@/components/tabbed_components/TabTransactions'),
                meta: {
                    isTab: true
                }
            },
        ],
        meta: {
            layout: 'AppLayoutMain',
            requiresAuth: true
        },
    },
    {
        path: '/investments/permissions',
        component: () => import(/* webpackChunkName: "permissions" */ '../views/Permissions.vue'),
        meta: {
            layout: 'AppLayoutMain',
            requiresAuth: true
        }
    },
    {
        path: '/investments/portfolios',
        name: 'Portfolios',
        component: () => import(/* webpackChunkName: "investments" */ '../views/Portfolios.vue'),
        meta: {
            layout: 'AppLayoutMain',
            requiresAuth: true
        }
    },
    {
        path: '/investments/openbanking',
        name: 'Open Banking',
        component: () => import(/* webpackChunkName: "openbanking" */ '../views/OpenBanking.vue'),
        meta: {
            layout: 'AppLayoutMain',
            requiresAuth: true
        }
    },
    {
        path: '/investments/openbankingrenew',
        name: 'Open Banking Renewal',
        component: () => import(/* webpackChunkName: "openbankingrenew" */ '../views/OpenBanking.vue'),
        meta: {
            layout: 'AppLayoutMain',
            requiresAuth: true
        }
    },
    {
        path: '/investments/datafeeds',
        name: 'Data Feeds',
        component: () => import(/* webpackChunkName: "datafeeds" */ '../views/Datafeeds.vue'),
        meta: {
            layout: 'AppLayoutMain',
            requiresAuth: true
        }
    },
    {
        path: '/investments/add',
        name: 'Add Investment',
        component: () => import(/* webpackChunkName: "addInvestment" */ '../views/AddInvestment.vue'),
        // CHILD ROUTES ARE FOR TABBED COMPONENTS THAT APPEAR TOGETHER, NOT INDIVIDUALLY
        children: [
            {
                path: '',
                name: 'add_investment_default',
                redirect: '/investments/add/provider'
            },
            {
                path: 'provider',
                component: () => import(/*  webpackChunkName: "addInvestmentProvider" */ '../components/addInvestment/StepChooseProvider'),
            },
            {
                path: 'terms',
                component: () => import(/*  webpackChunkName: "addInvestmentTerms" */ '../components/addInvestment/StepTermsConditions'),
            },
            {
                path: 'complete',
                component: () => import(/*  webpackChunkName: "addInvestmentComplete" */ '../components/addInvestment/StepComplete'),
            }
        ],
        meta: {
            layout: 'AppLayoutMain',
            requiresAuth: true
        }
    },
    {
        path: '/investments/selfmanaged',
        name: 'Self Managed',
        component: () => import(/* webpackChunkName: "selfManaged" */ '../views/SelfManaged.vue'),
        children: [
            {
                path: 'add',
                name: 'add_self_managed_default',
                redirect: '/investments/selfmanaged/add/product'
            },
            {
                path: 'add/product',
                component: () => import(/*  webpackChunkName: "addSelfManagedHoldingChooseType" */ '../components/selfManaged/StepOneChooseType'),
            },
            {
                path: 'add/details',
                component: () => import(/*  webpackChunkName: "addSelfManagedHoldingDetails" */ '../components/selfManaged/StepTwoDetails'),
            },
            {
                path: 'add/confirmation',
                component: () => import(/*  webpackChunkName: "addSelfManagedHoldingConfirmation" */ '../components/selfManaged/StepThreeConfirmation'),
            },
        ],
        meta : {
            layout: 'AppLayoutMain',
            requiresAuth: true
        }
    },
    {
        path: '/ims',
        name:'IMS',
        component: () => import(/* webpackChunkName: "ims" */ '../views/IMS.vue'),
        children: [
            {
                path: 'imshome',
                component: () => import(/* webpackChunkName: "ims" */ '@/components/tabbed_components/IMSHome'),
                meta: {
                    isTab: true
                }
            },
            {
                path: 'imsfaqs',
                component: () => import(/* webpackChunkName: "ims" */ '@/components/tabbed_components/IMSFAQs'),
                meta: {
                    isTab: true
                }
            }
        ],
        meta: {
            layout: 'AppLayoutMain',
            requiresAuth: true
        }
    },
    // {
    //     path: '/myinfo',
    //     name: 'My Info',
    //     component: () => import(/* webpackChunkName: "myinfo" */ '../views/MyInfo.vue'),
    //     meta: {
    //         layout: 'AppLayoutMain',
    //         requiresAuth: true
    //     },
    //     children: [
            // {
            //     path: 'riskprofiler',
            //     component: () => import(/* webpackChunkName: "riskprofiler" */ '@/components/tabbed_components/TabRiskProfiler'),
            //     meta: {
            //         isTab: true
            //     }
            // },
        // ],
    // },
    {
        path: '/securemessaging',
        name: 'Secure Messaging',
        component: () => import(/* webpackChunkName: "securemessaging" */ '../views/SecureMessaging.vue'),
        children: [
            {
                path: 'inbox',
                name: 'inbox',
                component: () => mailbox,
                meta: {
                    isTab: true
                }
            },
            {
                path: 'sent',
                name: 'sent',
                component: () => mailbox,
                meta: {
                    isTab: true
                }
            },
            {
                path: 'drafts',
                name: 'drafts',
                component: () => mailbox,
                meta: {
                    isTab: true
                }
            },
            {
                path: 'archived',
                name: 'archived',
                component: () => mailbox,
                meta: {
                    isTab: true
                }
            },
            {
                path: 'message/:message_id',
                name: 'Inbound Message',
                component: () => import(/* webpackChunkName: "secureinboundmessage" */ '../components/secure_messages/InboundMessage')
            },
            {
                path: 'message',
                name: 'Outbound Message',
                component: () => import(/* webpackChunkName: "secureoutboundmessage" */ '../components/secure_messages/OutboundMessage')
            }
        ],
        meta: {
            layout: 'AppLayoutMain',
            requiresAuth: true
        }
    },
    {
        path: '/research',
        name: 'Research',
        component: () => import(/* webpackChunkName: "research" */ '../views/Research.vue'),
        meta: {
            layout: 'AppLayoutMain',
            requiresAuth: true
        },
        children: [
            {
                path: '',
                name: 'research_default',
                redirect: '/research/library'
            },
            {
                path: 'library',
                component: () => import(/* webpackChunkName: "library" */ '@/components/tabbed_components/TabResearchLibrary'),
                meta: {
                    isTab: true
                }
            },
            {
                path: 'buylist',
                component: () => import(/* webpackChunkName: "buylist" */ '@/components/tabbed_components/TabBuyList'),
                meta: {
                    isTab: true
                }
            }
        ],
    },
    {
        path: '/settings',
        name: 'Settings',
        component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue'),
        meta: {
            layout: 'AppLayoutMain',
            requiresAuth: true
        },
        children: [
            {
                path: 'personal',
                component: () => import(/* webpackChunkName: "personalsettings" */ '@/components/tabbed_components/TabPersonalSettings'),
                meta: {
                    isTab: true
                }
            },
            {
                path: 'websettings',
                component: () => import(/* webpackChunkName: "websettings" */ '@/components/tabbed_components/TabWebSettings'),
                meta: {
                    isTab: true
                }
            },
            {
                path: 'riskprofiler',
                component: () => import(/* webpackChunkName: "riskprofiler" */ '@/components/tabbed_components/TabRiskProfiler'),
                meta: {
                    isTab: true
                }
            },
        ],
    },
    {
        path: '/tools',
        name: 'Planning Tools',
        component: () => import(/* webpackChunkName: "tools" */ '../views/Tools.vue'),
        meta: {
            layout: 'AppLayoutMain',
            requiresAuth: true
        },
        children: [
            {
                path: '',
                name: 'planning_default',
                redirect: '/tools/calculators'
            },
            {
                path: 'wealthplan',
                component: () => import(/* webpackChunkName: "wealthplan" */ '@/components/tabbed_components/TabWealthPlan'),
                meta: {
                    isTab: true
                }
            },
            {
                path: 'calculators',
                component: () => import(/* webpackChunkName: "calculators" */ '@/components/tabbed_components/TabCalculators'),
                meta: {
                    isTab: true
                }
            },
            /*
            {
                path: 'budgeting',
                component: () => import('@/components/tabbed_components/TabBudgeting'),
                meta: {
                    isTab: true
                }
            },
            */
            {
                path: 'investmentcalculator',
                component: () => import(/* webpackChunkName: "investmentcalculator" */ '@/components/calculators/InvestmentCalculator'),
            },
            {
                path: 'mortgagecalculator',
                component: () => import(/* webpackChunkName: "mortgagecalculator" */ '@/components/calculators/MortgageCalculator'),
            },
            {
                path: 'educationcalculator',
                component: () => import(/* webpackChunkName: "educationcalculator" */ '@/components/calculators/education/EducationCalculator'),
            }
        ],
    },
    {
        path: '/theme',
        name: 'Example Theme',
        component: () => import(/* webpackChunkName: "exampletheme" */ '../views/ExampleTheme.vue'),
    },
    {
        path: '/forgottenpassword',
        name: 'Forgotten Password',
        component: () => import(/* webpackChunkName: "forgottenpassword" */ '../views/ForgottenPassword.vue'),
        meta: {
            layout: 'AppLayoutLogin',
            imageClass: 'resetBackgroundImg' // used to set a different image to default in the login welcome image component (used in the applayoutlogin
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
        meta: {
            layout: 'AppLayoutLogin'
        }
    },
    {
        path: '/logout',
        name: 'Logout',
        component: () => import(/* webpackChunkName: "login" */ '../views/Logout.vue'),
        meta: {
            layout: 'AppLayoutLogin'
        }
    },
    {
        path: '/resetpassword',
        name: 'Reset Password',
        component: () => import(/* webpackChunkName: "resetpassword" */ '../views/ResetPassword.vue'),
        meta: {
            layout: 'AppLayoutLogin',
            imageClass: 'resetBackgroundImg' // used to set a different image to default in the login welcome image component (used in the applayoutlogin
        }
    },
    {
        path: '/createaccount',
        name: 'Create Account',
        component: () => import(/* webpackChunkName: "createAccount" */ '../views/CreateAccount.vue'),
        meta: {
            layout: 'AppLayoutLogin',
            imageClass: 'createAccountBackgroundImg'
        }
    },
    {
        path: '/createaccountdetail',
        name: 'Create Account Detail',
        component: () => import(/* webpackChunkName: "createAccountDetail" */ '../views/CreateAccountDetail.vue'),
        meta: {
            layout: 'AppLayoutLogin',
            imageClass: 'createAccountBackgroundImg'
        }
    },
    {
        path: '/verifynewuser',
        name: 'Verify New User',
        component: () => import(/* webpackChunkName: "verifynewuser" */ '../views/VerifyNewUser.vue'),
        meta: {
            layout: 'AppLayoutLogin',
            imageClass: 'createAccountBackgroundImg'
        }
    },
    {
        path: '/:notFound(.*)',
        name: 'not-found',
        component: () => import(/* webpackChunkName: "notFound" */ '../views/NotFound.vue'),
        meta: {
            layout: 'AppLayoutMain',
            requiresAuth: true
        }
    },
    {
        path: '/error',
        name: 'Error',
        component: () => import(/* webpackChunkName: "error" */ '../views/Error.vue'),
        meta: {
            layout: 'AppLayoutMain',
            requiresAuth: true
        }
    },
    {
        path: '/adminsecuremessaging',
        name: 'Admin Secure Messaging',
        component: () => import(/* webpackChunkName: "adminsecuremessaging" */ '../views/AdminSecureMessaging.vue'),
        children: [
            {
                path: 'inbox',
                name: 'admininbox',
                component: () => mailbox,
                meta: {
                    isTab: true
                }
            },
            {
                path: 'sent',
                name: 'adminsent',
                component: () => mailbox,
                meta: {
                    isTab: true
                }
            },
            {
                path: 'drafts',
                name: 'admindrafts',
                component: () => mailbox,
                meta: {
                    isTab: true
                }
            },
            {
                path: 'archived',
                name: 'adminarchived',
                component: () => mailbox,
                meta: {
                    isTab: true
                }
            },
            {
                path: 'message/:message_id',
                name: 'Admin Inbound Message',
                component: () => import(/* webpackChunkName: "secureinboundmessage" */ '../components/secure_messages/InboundMessage')
            },
            {
                path: 'message',
                name: 'Admin Outbound Message',
                component: () => import(/* webpackChunkName: "secureoutboundmessage" */ '../components/secure_messages/OutboundMessage')
            }
        ],
        meta: {
            requiresAuth: true
        }
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            return new Promise((resolve) => {
                const element = document.getElementById(to.hash.replace(to.hash,to.hash.substr(1)));
                if (element) {
                    resolve({left: element.offsetLeft, top: element.offsetTop - 130, behavior: 'smooth'})
                } else {
                    resolve({left: 0, top: 0, behavior: 'smooth'})
                }
            })
        } else {
            return new Promise((resolve) => {
                const element = document.getElementById("rd3-scrollto");
                const vh = Math.round(window.innerHeight / 10)
                if (element) {
                    resolve({left: element.offsetLeft, top: element.offsetTop - vh, behavior: 'smooth'})
                } else {
                    resolve({left: 0, top: 0, behavior: 'smooth'})
                }
            })
        }
    }
})

router.beforeEach(async (to, from, next) => {
    let nextPath;
    const authRoutes = ['/login', '/resetpassword', '/forgottenpassword', '/verifynewuser']

    // @TODO: there needs to be some sort of garbage collection to done on changing route
    store.commit('setCurrentMessage', null)
    // catch for hitting back on browsers in stepper
    if (window.event && window.event.type === 'popstate') {
        if (window.event.state.forward === "/investments/add/terms" && window.event.state.current === "/investments/add/provider") {
            store.commit('resetStepper', ['investnow'])
        }
        if (window.event.state.current === "/investments/add/terms" && window.event.state.forward === "/investments/add/complete") {
            store.dispatch('setSelectedStep', {stepper: 'investnow', step: 2, completed: 1})
        }
    }
    if (!to.path.includes('/investments/add') && !to.path.includes('/securemessaging/message')) {
        store.commit('resetStepper', ['investnow', 'investnowredirect'])
    }

    nextPath = await guardRoute(to)
    if (authRoutes.includes(nextPath)) {
        next(nextPath)
        return
    }

    //console.log('got here!', to);
    if(['inbox', 'sent', 'drafts', 'archived', 'admininbox', 'adminsent', 'admindrafts', "adminarchived"].includes(to.name)){
        bus.emit('resetMailFilters')
        await store.dispatch('unsetCurrentMessage')
        bus.emit('unsetMessageForm')
        await store.dispatch('setMailboxType', to.name.replace('admin', ''))
    }
    if ('message' === to.name) {
        await store.dispatch('unsetCurrentMessage')
        bus.emit('unsetMessageForm')
        await store.dispatch('unsetActiveHeader', {page: 'securemessaging'})
    }

    nextPath ? next(nextPath) : next()

})

const guardRoute = async (to) => {
    if (!to.matched.some(record => record.meta.requiresAuth)) {
        return
    }
    const user = await store.dispatch('checkUserAuth')

    if (user) {
        // if manual url entry occurs - this will catch it and ensure the correct active tab is set
        if (to.meta.isTab) {
            const split = to.path.charAt(0) === '/' ? to.path.substring(1).split('/') : to.path.split('/')
            let h = store.getters.getTabHeaders(split[0])
            h = h.find(tab => tab.to === split[1])
            // set tab header via action in tabsModule
            store.dispatch('setActiveHeaderFromMenu', {
                page: split[0],
                header: h.key
            })
        }

        setTimeout(async function(){
            if(window.Appcues) {
                window.Appcues.identify(
                    user.attributes['custom:clarityUUID'], // unique, required
                    {
                        email: user.username, // Current user's email
                    }
                );
            }else{
                console.log('no appcues');
            }
        }, 500);


        return user && store.getters.isAuthenticated ? to.nextPath : '/login?path='+to.path
    }

    return '/login?path='+to.path
}

export default router
