export default {
    // selfManagedHoldings: (state) => (id) => {
    selfManagedHoldings: (state) => {
        return state.selfManaged
    },
    selfManagedLiabilities: (state) => {
        return state.selfManagedLiabilities
    },
    productTypes: (state) => {
        return state.productTypes
    },
    liabilityTypes: (state) => {
        return state.liabilityTypes
    },
    productTypeFromField: (state) => (field, value) => {
        if(state.mode === 'liability'){
            return state.liabilityTypes.find(product => product[field] === value)
        }
      return state.productTypes.find(product => product[field] === value)
    },
    productFromLabel: (state) => (parentId, productLabel) => {
        if(state.mode === 'liability'){
            const type = state.liabilityTypes.find(type => type.id === parentId)
            return type.products.find(product => product.label === productLabel)
        }
        const type = state.productTypes.find(type => type.id === parentId)
        return type.products.find(product => product.label === productLabel)
    },
    fundlinks: (state) => {
      return state.fundlinks
    },
    commonChoices: (state) => {
        return state.commonChoices
    },
    productsForType: (state) => (id) => {
        if(state.mode === 'liability'){
            const match = state.liabilityTypes.find(type => type.id === id)
            return match ? match.products : []
        }
        const match = state.productTypes.find(type => type.id === id)
        return match ? match.products : []
    },
    selectedNewSelfManagedType: (state) => {
        return state.selectedNewSelfManagedType
    },
    selectedProduct: (state) => {
        return state.selectedProduct
    },
    getParentOfProduct: (state) => (productId) => {
        if(state.mode === 'liability'){
            const parent = state.liabilityTypes.find(type => type.products.find(product => product.id === productId))
            return parent ?? undefined
        }
        const parent = state.productTypes.find(type => type.products.find(product => product.id === productId))
        return parent ?? undefined
    },
    getSelectedProductObject: (state) => {
        if(state.mode === 'liability'){
            let product = undefined
            const productType = state.liabilityTypes.find(type => type.id === state.selectedNewSelfManagedType)
            if (productType) {
                product = Object.values(productType.products).find(product => product.id === state.selectedProduct[0])
            }
            return product
        }
        let product = undefined
        const productType = state.productTypes.find(type => type.id === state.selectedNewSelfManagedType)
        if (productType) {
           product = Object.values(productType.products).find(product => product.id === state.selectedProduct[0])
        }
        return product
    },
    getPercentageSplit: (state) => {
        return state.split
    },
    getSelfManagedDetails: (state) => {
        return state.selfManagedDetails
    },
    getHoldings: (state) => {
        return state.holdings
    },
    getEditSMHoldingRecordIndex: (state) => {
        return state.editing
    },
    getEditSMHoldingRecord: (state) => {
        if (state.editing !== 'undefined') {
            return state.holdings[state.editing]
        }
    },
    isEditingExistingValuationHolding: (state) => {
        return state.isEditingExistingValuationHolding
    },
    isEditingExistingValuationHoldingIndex: (state) => {
        return state.isEditingExistingValuationHoldingIndex
    },
    selfManagedFinalPayload: (state) => {
        return state.finalPayload
    }
}
