<template>
  <div :class="[getGroupClass, getGroupClass === 'pwc' ? 'clarity-gradient-dark-grey' : '']" class="group-bar"
       id="group-bar">
    <div class="flex flex-row align-items-center">
      <span class="material-icons-round white icon">account_circle</span>
      <div class="user">
        <div class="user-full">Logged in as <span>{{ name }}</span>.&nbsp;</div>
        <div class="user-condensed">Account &nbsp;</div>
        associated with &nbsp;<span>{{ group.name }}</span>. &nbsp;
      </div>
      <div class="checkpoint-wrapper">
        <div>Checkpoint reporting is &nbsp;</div>
        <div class="checkpoint-long">
          <!-- for screens larger tablet -->
          <div v-if="checkpointEnabled" class="checkpoint">
            <span>enabled for You</span>
          </div>
          <div v-if="!checkpointEnabled" class="checkpoint">
            <span class="underline">disabled</span>
          </div>
          <div v-if="checkpointEnabled" class="checkpoint">
            <span v-if="checkpointOthers === 1">&nbsp; and your Partner</span>
            <span v-if="checkpointOthers > 1">&nbsp; and {{ checkpointOthers }} others</span>
          </div>
        </div>
        <!-- for screens to tablet -->
        <div class="checkpoint-condensed">
          <div v-if="checkpointEnabled" class="checkpoint">
            <span>enabled</span>
          </div>
          <div v-if="!checkpointEnabled" class="checkpoint">
            <span class="underline">disabled</span>
          </div>
        </div>
        .
      </div>
    </div>
    <Button class="clarity-btn clarity-gradient-light-grey preferences" @click="$router.push('/settings/personal')">
      <div class="preferences-full">Change your preferences</div>
      <div class="preferences-condensed">Change preferences</div>
      <div class="preferences-mobile">Preferences</div>
    </Button>
  </div>
</template>

<script>
import {useStore} from "vuex";
import {computed} from "vue";

export default {
  name: "GroupHeader",
  setup() {
    const store = useStore()
    const group = computed(() => store.getters.getGroup)
    const getGroupClass = computed(() => {
      return group.value.name
    })

    return {
      group,
      getGroupClass,
      name: computed(() => store.getters.getPreferenceName),
      checkpointEnabled: computed(() => store.getters.getCheckpointEnabled),
      checkpointOthers: computed(() => {
          const additional = store.getters.getCheckpointAdditional;
          if(additional){
              let count = 0;
              additional.forEach((partner) => {
                  if(partner.enabled){
                      count++;
                  }
              })
              return count;
          }else{
              return 0;
          }
      })
    }
  }
}
</script>

<style scoped lang="scss">
#group-bar {
  position: sticky;
  top: 0px;
  z-index: 100;
}

.group-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  height: 50px;
  background: transparent linear-gradient(180deg, #666666 0%, var(--clarity-dark-grey) 100%) 0% 0% no-repeat padding-box;
  color: var(--clarity-pure-white);
}


.icon {
  margin-right: 10px;
}

.user {
  display: flex;
  flex-direction: row;

  span {
    font-weight: bold;
  }

  @include mob {
    display: none;
    .user-full {
      display: none;
    }
    .user-condensed {
      display: none;
    }
  }
  @include tablet {
    .user-full {
      display: none;
    }
    .user-condensed {
      display: flex;
    }
  }

  @include sm {
    .user-full {
      display: none;
    }
    .user-condensed {
      display: flex;
    }
  }
}

.checkpoint-wrapper {
  display: flex;
  flex-direction: row;

  .checkpoint-long {
    display: flex;
    flex-direction: row;
  }
  .checkpoint-condensed {
    display: none;
  }

  @include mob-sm {
    .checkpoint-condensed {
      display: flex;
      flex-direction: row;
    }
    .checkpoint-long {
      display: none;
    }
  }
}

.checkpoint span {
  text-decoration: underline;
}

button {
  border: unset !important;
  font-size: 12px;
  height: 30px;
}

.pwc {
  /*background: var(--clarity-gradient-dark-grey);*/
  color: var(--clarity-pure-white);
}
.preferences {
  color: var(--clarity-dark-grey);
  .preferences-full {
    display: flex;
  }
  .preferences-condensed {
    display: none;
  }
  .preferences-mobile {
    display: none;
  }
  @include sm  {
    .preferences-condensed {
      display: none;
    }
    .preferences-mobile {
      display: none;
    }
  }
  @include tablet {
    .preferences-full {
      display: none;
    }
    .preferences-condensed {
      display: flex;
    }
    .preferences-mobile {
      display: none;
    }
  }
  @include mob {
    .preferences-full {
      display: none;
    }
    .preferences-condensed {
      display: none;
    }
    .preferences-mobile {
      display: flex;
    }
  }
}
</style>
