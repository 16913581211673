<template>
  <div>
    <Sidebar
        id="menu_wide" :visible="showDrawer" :baseZIndex="1000" :modal="false" class="sidebar" @click="mouseClick" @touchstart="touchTrigger">
      <div class="logo mt-3 -mb-2" >
        <img src="@/assets/logos/clarity-logo.png">
      </div>
      <div>
        <ScrollPanel style="width: 100%; height: 70vh" class="pr-1">
          <menu-item
              v-for="item in items"
              :key="item.label"
              :item="item"
              :selectedItem="selectedItem"
              :selectedSubItem="activeSubMenuItem"
              :wide="true"
              @setActiveItem="toggleMenuItem"
              @setActiveSubItem="setActiveSubItem"
              :data-cy="`menu-item-wide-${formatter.case(item.label, 'kebab')}`"
          />
        </ScrollPanel>
        <MoreLinks wide/>
      </div>
    </Sidebar>
    <Sidebar
        id="menu_narrow" :visible="showNarrow" :baseZIndex="1000" :modal="false" class="sidebar" @click="mouseClick">
      <div class="flex justify-content-center my-5 logo">
        <img src="@/assets/logos/clarity-logo-C-white.svg" alt="">
      </div>
      <div class="flex flex-column align-items-center">
        <menu-item
            v-for="item in items"
            :key="item.label"
            :item="item"
            :selectedItem="selectedItem"
            :submenu="submenu"
            :wide="false"
            @setActiveItem="toggleMenuItem"
            @setSubmenu="toggleSubmenu"
            @setActiveSubItem="setActiveSubItem"
            :data-cy="`menu-item-collapsed-${formatter.case(item.label, 'kebab')}`"
            class="large-menu-item"
        />
      </div>
      <div class="flex flex-column align-items-center">
        <MoreLinks @setSubmenu="toggleSubmenu"/>
      </div>
    </Sidebar>
  </div>
</template>
<script>
import {onMounted, ref, toRef, inject} from 'vue'
import {useRoute} from 'vue-router'
import MenuItem from './MenuItem.vue'
import MoreLinks from './MoreLinks.vue'
import {formatter} from '@/core/services/formatter'

export default {
  name: 'SideBarMenu',
  props: {
    open: {
      type: Boolean,
    },
    closed: {
      type: Boolean,
    }
  },
  setup(props) {
    const showDrawer = toRef(props, 'open')
    const showNarrow = toRef(props, 'closed')
    const selectedItem = ref()
    const submenu = ref('')
    const activeSubMenuItem = ref('')
    const clarityURL = process.env.VUE_APP_CLARITYURL;

    const bus = inject('bus');


    onMounted(() => {
      const currentRoute = useRoute().name
      selectedItem.value = currentRoute ?? 'Dashboard'
    })

    const toggleMenuItem = (label) => {
      selectedItem.value === label ? selectedItem.value = '' : selectedItem.value = label
      // reset submenu item to unselected until defaults functionality is determined
      activeSubMenuItem.value = ''
    }

    const toggleSubmenu = (label) => {
      submenu.value === label ? submenu.value = '' : submenu.value = label
    }

    const setActiveSubItem = (label) => {
      activeSubMenuItem.value = label
    }

    const items = ref([
      {
        label: 'Dashboard',
        icon: 'home',
        to: '/',
      },
      {
        label: 'Investments',
        icon: 'bar_chart',
        to: '/investments',
        items: [
          {
            label: 'My investments',
            to: '/investments',
            items: [
              {
                label: 'Valuation',
                to: '/investments/valuation'
              },
              {
                label: 'Asset Allocation',
                to: '/investments/assetallocation'
              },
              {
                label: 'Transactions',
                to: '/investments/transactions'
              },
            ]
          },
          {
            label: 'IMS',
            to: '/ims/imshome',
            items: [
              {
                label: 'IMS Home',
                to: '/ims/imshome'
              },
              {
                label: 'IMS FAQs and Info',
                to: '/ims/imsfaqs'
              }
            ]
          },
          {
              label: 'Self Managed Holdings',
              to: '/investments/selfmanaged'
          },
          {
            label: 'Add New Holding',
            to: '/investments',
            items: [
              {
                label: 'Invest Now',
                to: '/investments/add'
              },
              {
                label: 'Add Bank Account or Credit Card',
                to: '/investments/openbanking'
              }
            ]
          },
          {
            label: 'Portfolio Management',
            to: '/investments/portfolios'
          },
          {
            label: 'Fund Provider Data Feeds',
            to: '/investments/datafeeds'
          },
          {
            label: 'Permissions',
            to: '/investments/permissions'
          },
        ]
      },
      {
        label: 'Secure Messaging',
        shortlabel: 'Messaging',
        icon: 'mail',
        items: [
          {
            label: 'Inbox',
            to: '/securemessaging/inbox'
          },
          {
            label: 'Drafts',
            to: '/securemessaging/drafts'
          },
          {
            label: 'Sent Items',
            to: '/securemessaging/sent'
          },
          {
            label: 'Archive',
            to: '/securemessaging/archived'
          },
        ]
      },
      {
        label: 'Planning Tools',
        shortlabel: 'Tools',
        icon: 'fact_check',
        // to: '/planningtools',
        items: [
          {
            label: 'Wealthplan',
            to: '/tools/wealthplan'
          },
          {
            label: 'Calculators',
            to: '/tools/calculators'
          }/*,
          {
            label: 'Budgeting',
            to: '/tools/budgeting'
          },*/
        ]
      },
      /*
      {
        label: 'Documents',
        icon: 'description',
        items: [
          {
            label: 'My documents',
            to: '/'
          },
          {
            label: 'clarity Documents',
            to: '/',
            items: [
              {
                label: 'Share A Document',
                to: '/'
              }
            ]
          },
          {
            label: 'Uploaded Documents',
            to: '/',
            items: [
              {
                label: 'Upload A Document',
                to: '/'
              }
            ]
          },
        ]
      },
      */
      {
        label: 'Research',
        icon: 'search',
        // to: '/research',
        items: [
          {
            label: 'Research Library',
            to: '/research/library',
            isTab: true
          },
          {
            label: 'Investment Buy List',
            to: '/research/buylist',
            isTab: true
          },
          {
            label: 'Fund Focus',
            url: clarityURL+'/newfundfocus'
          },
          {
            label: 'Glossary',
            url: clarityURL+'/newglossary'
          },
          {
            label: 'Videos & Webinars',
            url: clarityURL+'/toolsvideos'
          }
        ]
      },
      {
        label: 'My Personal Info',
        shortlabel: 'My Info',
        icon: 'account_circle',
        to: '/settings',
        items: [
          {
              label: 'Personal Details',
              to: '/settings/personal'
          },
          {
              label: 'Website Settings',
              to: '/settings/websettings'
          },
          {
              label: 'Risk Profiler',
              to: '/settings/riskprofiler'
          }
        ]
      }
    ]);

    const mouseClick = () => {
      bus.emit('sidebarmouseclick');
    }

    const touchTrigger = () => {
      bus.emit('sidebarmouseclick');
    }

    return {
      activeSubMenuItem,
      formatter,
      items,
      showDrawer,
      showNarrow,
      selectedItem,
      submenu,
      toggleMenuItem,
      toggleSubmenu,
      setActiveSubItem,
      mouseClick,
      touchTrigger
    }
  },
  components: {
    MenuItem,
    MoreLinks
  }
}
</script>

<style scoped lang="scss">
.p-disabled {
  opacity: unset;
}

.logo {
  padding: 18px;
}
</style>
