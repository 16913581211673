<template>
  <div class="p-5 footer-wrapper" data-cy="nav-footer">
    <div class="footer">
      <div class="footer-content">
        <div class="top-row" data-cy="footer-top-row">
          <div class="footer-logo flex">
            <img src="@/assets/logos/clarity-logo-small-grey.svg">
            <img class="cii-logo" src="@/assets/icons/chartered-planners-footer-logo.png">
            <img src="@/assets/icons/iso270012022footerlogo.png">
          </div>
          <div class="footer-links" data-cy="footer-links">
            <div class="link">
              <a :href="clarityURL+'/aboutintro'" target="_blank">
                About Us
              </a>
            </div>
            <div class="link">
              <a :href="clarityURL+'/newethics'" target="_blank">
                Ethics
              </a>
            </div>
            <div class="link">
              <a :href="clarityURL+'/newglossary'" target="_blank">
                Glossary
              </a>
            </div>
            <div class="link">
              <a :href="clarityURL+'/newfaqs'" target="_blank">
                FAQs
              </a>
            </div>
            <div class="link">
              <a :href="clarityURL+'/newcomplaints'" target="_blank">
                Complaints
              </a>
            </div>
            <div class="link">
              <a :href="clarityURL+'/newprivacy'" target="_blank">
                Privacy & Cookies
              </a>
            </div>
            <div class="link">
              <a href="https://website-public-assets.clarityglobal.com/saleswebsite/pdf/claritytermsofbusiness.pdf" target="_blank">
                Terms of Business
              </a>
            </div>
            <div class="link">
              <a href="https://website-public-assets.clarityglobal.com/saleswebsite/pdf/clarityfeeschedule.pdf" target="_blank">
                Fees
              </a>
            </div>
          </div>
          <div class="footer-dropdown" data-cy="footer-dropdown">
            <Dropdown class="link" v-model="menuOptSelected" :options="linkOptions" optionLabel="label"
                      @change="goTo($event)"/>
          </div>
        </div>
        <div class="bottom-row" data-cy="footer-bottom-row">
          <div class="extra-small-text grey"><strong>Contact us:</strong> 1 Crown Square, Woking - Surrey, GU21 6HR -
            United Kingdom |
            <a href="tel:+448003687511" class="grey">+44 800 368 7511</a>|
            <a href="mailto:enquiries@clarityglobal.com" class="extra-small-text grey underline">enquiries@clarityglobal.com |&nbsp;</a>
            © 2003 - {{ getYear() }} clarity Ltd. clarity is authorised and regulated by the Financial
            Conduct Authority. VAT No 164 1741 18. Reg No in England and Wales: 3466459.
          </div>

        </div>
        <div class="mobile-bottom-row" data-cy="footer-bottom-row-mobile">
          <div class="extra-small-text grey"><strong>Contact us:</strong> 1 Crown Square, Woking - Surrey, GU21 6HR -
            United Kingdom | <a href="tel:+448003687511" class="grey">+44 800 368 7511</a> <a
                href="mailto:enquiries@clarityglobal.com" class="extra-small-text grey underline">|
              enquiries@clarityglobal.com</a>
            © 2003 - {{ getYear() }} clarity Ltd. clarity is authorised and regulated by the Financial Conduct
            Authority. VAT No
            164 1741 18.
          </div>
          <div class="extra-small-text grey">Reg No in England and Wales: 3466459.</div>
        </div>
      </div>
      <div class="icon-tray flex" data-cy="footer-icon-tray">
        <!-- <i class="pi pi-facebook"></i> -->
        <a href="https://twitter.com/clarityglobal" target="_blank"><img class="clarity-margin-right-s" src="@/assets/icons/twittericon.png"></a>
        <a href="https://www.instagram.com/clarity_global/" target="_blank"><i class="pi pi-instagram grey"></i></a>
        <a href="http://www.linkedin.com/company/1242754" target="_blank"><i class="pi pi-linkedin grey"></i></a>
      </div>
    </div>
    <HelpLauncher/>
  </div>
</template>

<script>
import {ref} from 'vue'
import HelpLauncher from '@/components/common/HelpLauncher'

export default {
  components: {
    HelpLauncher
  },
  setup() {
    const getYear = () => {
      let date = new Date()
      return date.getFullYear()
    }

    const clarityURL = process.env.VUE_APP_CLARITYURL;


      const linkOptions = ref([
      {
        label: 'About Us',
        link: clarityURL+"/aboutintro"
      },
      {
        label: 'Ethics',
        link: clarityURL+"/newethics"
      },
      {
        label: 'Glossary',
        link: clarityURL+"/newglossary"
      },
      {
        label: 'FAQs',
        link: clarityURL+"/newfaqs"
      },
      {
        label: 'Complaints',
        link: clarityURL+"/newcomplaints"
      },
      {
        label: 'Privacy & Cookies',
        link: clarityURL+'/newprivacy'
      },
      {
        label: 'Terms of Business',
        link: 'https://website-public-assets.clarityglobal.com/saleswebsite/pdf/claritytermsofbusiness.pdf'
      },
      {
        label: 'Fees',
        link: 'https://website-public-assets.clarityglobal.com/saleswebsite/pdf/clarityfeeschedule.pdf'
      },
    ])

    const menuOptSelected = ref(linkOptions.value[0])

    const goTo = (link) => {
      window.open(link.value.link, "_blank");
    }

    return {
      getYear,
      goTo,
      linkOptions,
      menuOptSelected,
      clarityURL
    }
  }
}
</script>

<style lang="scss">
.footer-wrapper {
  display: flex;
  position: relative;
  width: 100%;
  bottom: 0;
  margin-top: 30px;
  color: var(--clarity-grey);
}

.footer {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  @include mob-sm {
    flex-direction: column;
    align-items: start;
  }
}

.top-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;

  @include md {
    flex-direction: column;
    align-items: flex-start;
  }

  @include mob {
    flex-direction: column;
    align-items: flex-start;
  }
}

.bottom-row {
  color: var(--clarity-grey);
  display: flex;
  flex-direction: column;
  margin-right: 10%;

  @include mob {
    display: none;
  }

  .extra-small-text {
    color: var(--clarity-grey);
  }
}

.footer-logo {
  color: var(--clarity-grey);
  justify-content: flex-end;
  margin-bottom: 13px;
}
.footer-logo img {
  margin-right: 30px;
}
.cii-logo {
  width: 50px;
  height: 54px;
}

.footer-links {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  @include mob-sm {
    display: none
  }
}

.footer-dropdown {
  display: none;
  @include mob-sm {
    display: flex;
  }
}

.link {
  margin-right: 30px;

  a {
    color: var(--clarity-grey);

    &:hover {
      color: var(--clarity-blue);
    }
  }
}

.icon-tray {
  justify-content: flex-end;
  margin-right: 80px;

  i {
    font-size: 24px;
    margin-right: 30px;

    &:hover {
      color: var(--clarity-blue);
    }
  }

  @include mob-md {
    padding-top: 20px
  }
}

.mobile-bottom-row {
  display: flex;
  flex-direction: column;
  width: 80%;

  @include tablet-up {
    display: none;
  }

  .extra-small-text {
    color: var(--clarity-grey);
  }
}

.p-dropdown {
  &.link {
    background: transparent;
    border: none;

    .p-dropdown-label {
      color: var(--clarity-grey);
      font-weight: normal;
      font-size: 14px;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .footer-logo img {
    margin-right: 20px;
  }
}
@media only screen and (max-width: 375px) {
  .footer-logo img {
    margin-right: 10px;
  }
}
</style>
