export default {
    setSelectedStep(state, payload) {
        state.steppers[payload.stepper] = state.steppers[payload.stepper].map(step => {
            return {...step, completed: step.value <= payload.completed, selected: step.value === payload.step}
        })
    },
    setInvestNowProvider(state, provider) {
        state.investWith = provider
    },
    resetStepper(state, steppers) {
        steppers.forEach(stepper => {
            state.steppers[stepper] = state.steppers[stepper].map(step => {
                return {...step, completed: false, selected: step.value === 1}
            })
        })
    }
}