import api from '@/core/services/api'
import {toDrupalRequest} from '@/core/helpers';

export default {
    async getIMSApprovalRecords({commit}) {
        const endpoint = '/ims/approvalrecords';
        const response = await api.get(`${toDrupalRequest(endpoint)}`);
        //console.log('response is ', response);

        let rtn = []
        // loop through rebalances
        if (response && response.rebalances) {
            //console.log('ims approval rebalances is ', response.rebalances)
            // loop rebalance documents
            response.rebalances.forEach(rebal => {
                // check to see if any documents contain cash
                let cash = rebal.documents.filter(doc => {
                        return doc.type.includes(' Cash Instruction')
                    })
                // if cash is true - loop cash
                if (cash.length) {

                    // find matching non-cash entry based on type string match
                    for (let i = 0; i < cash.length; i++) {
                        let found = rebal.documents.find(match => {
                            let type = cash[i].type
                            return match.type+match.policy === type.replace(' Cash Instruction', '')+cash[i].policy
                        })

                        if(found) {

                            // add sibling key with id of cash instruction
                            found['sibling'] = cash[i].id

                            let cashExtract = JSON.parse(cash[i].transactions).sales[0]
                            let transactions = JSON.parse(found.transactions)

                            // push non cash with sibling key to returning rebalance
                            found.transactions = JSON.stringify({
                                purchases: transactions.purchases,
                                sales: [...transactions.sales, cashExtract]
                            })

                            // remove duplicate cash document
                            rebal.documents = rebal.documents.filter(doc => {
                                return doc.id !== cash[i].id
                            })
                        }
                    }
                    // push to new return array
                    rtn = [...rtn, rebal]
                }else{
                    rtn = [...rtn, rebal]
                }
            })

        }

        //console.log('returning', rtn)
        // commit('setApprovalRecords', response.rebalances);
        commit('setApprovalRecords', rtn);

        let recent = [];
        if(response.recentApprovals){
            recent = response.recentApprovals;
        }
        commit('setRecentApprovals', recent);
    },

    async selectedForApproval({commit}, value) {
        commit('selectedApprovalRecords', value);
    },

    async approveApplications({commit, dispatch}, ids) {

        if (process.env.VUE_APP_DELAY_BACKEND_API) {
            await new Promise(r => setTimeout(r, 3000));
        }

        const approvals = {"approve": ids};
        const endpoint = '/ims/approvalrecords';
        const response = await api.patch(
            `${toDrupalRequest(endpoint)}`,
            JSON.stringify(approvals),
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );
        if (response && response.success) {
            // now mark these as approved.
            ids.forEach(id => {
                commit('updateApprovalDocument', id)
            });
        } else {
            // throw toast error here.
            dispatch('addNotification', {
                severity: 'error',
                summary: 'Approve Applications',
                detail: `An unexpected error occurred when submitting your application approval.`
            })
        }
        dispatch('setLoading', {id: 'IMSApproval', state: false})
    }
}
