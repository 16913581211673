<template>
  <div class="modal" id="modal">
    <div class="modal-body" :style="width ? `width: ${width}` :''">
      <div class="modal-close">
        <Button icon="pi pi-times" class="p-button-rounded p-button-outlined" @click="$emit('close')"/>
      </div>
      <slot name="body"></slot>
    </div>
  </div>
</template>
<script>
export default {
  emits: ['close'],
  props: ['width']
}
</script>

<style scoped>
.modal {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* var(--clarity-dark-grey); */
  background: rgba(40, 43, 47, 0.8);
  /* z-index needs to sit higher than nav now at 2149.....primevue */
  z-index: 2150;
  justify-content: center;
  align-items: center;
  cursor: not-allowed;
}
.modal-close {
  display: inline-flex;
  justify-content: flex-end;
  color: var(--clarity-dark-grey);
  background: var(--clarity-pure-white);
  padding: 20px;
  border-top-left-radius: 27px;
  border-top-right-radius: 27px;
}
.modal-body {
  display: flex;
  max-width: 95%;
  max-height: 95%;
  flex-direction: column;
  border-radius: 27px;
  background: rgba(255, 255, 255, 1);
  cursor: default;
}
</style>