export default {
    setApprovalRecords(state, value) {
        state.approvalRecords = value;
    },
    setRecentApprovals(state, value) {
        state.recentApprovals = value;
    },
    selectedApprovalRecords(state, value) {
        state.approvedRecords = value;
    },
    updateApprovalDocument(state, id) {

        // get index
        const idx = state.approvalRecords.findIndex((e) => {
            const found = e.documents.find(x => x.id === id);
            return found;
        })

        if (idx !== -1) {
            // update with new args (not uuid!)
            const original = state.approvalRecords[idx];
            const clone = JSON.parse(JSON.stringify(original));

            // update the stage of the record.
            const obj = clone.documents.find(x => x.id === id);
            if (obj) {
                obj['stage'] = 1;
            }
            state.approvalRecords[idx] = clone;

            // we also need to push new recent approval records to the table to record that these have been marked as approved.
            state.recentApprovals.unshift(
            {
                "policy": obj.policy,
                "approvedBy": obj.approvalBy[0],
                "approvalDate": new Date(),
                "rebalance": original.description
            }
            )
        } else {
            //console.log('check for sibling relationship', id)
        }

    }
}
