import getters from "./getters"
import mutations from "./mutations";
import actions from "./actions";

export default {
    state() {
        return {
            steppers: {
                investnow : [
                    {name: 'Step 01', title: 'Choose Fund Provider', value: 1, selected: true, completed: false},
                    {name: 'Step 02', title: 'Terms and Conditions', value: 2, selected: false, completed: false},
                    {name: 'Step 03', title: 'Confirmation', value: 3, selected: false, completed: false},
                ],
                investnowredirect : [
                    {name: 'Step 01', title: 'Choose Fund Provider', value: 1, selected: true, completed: false},
                    {name: 'Step 02', title: 'Contact Your Adviser', value: 2, selected: false, completed: false},
                ],
                selfManagedOptions: [
                    {name: 'Step 01', title: 'Choose A Product', value: 1, selected: true, completed: false},
                    {name: 'Step 02', title: 'Entering Details', value: 2, selected: false, completed: false},
                    {name: 'Step 03', title: 'Confirmation', value: 3, selected: false, completed: false},
                ]
            },
            investWith: null
        }
    },
    mutations,
    actions,
    getters
}