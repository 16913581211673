export default {
    setPolicyTransactions(state , payload) {
        const key = payload.policy.value
        const value = payload.response
        const k = state.transactions.find(plan => plan.key == key)
        
        if (k) {
            state.transactions[state.transactions.indexOf(k)]['transactions'] = value
        } else {
            state.transactions.push({key : key, transactions : value})
        }
    }
}