<template>
  <div>
      <div v-if="messageBody">{{ messageBody.substring(0, 80) + '...' }}</div>
      <Skeleton class="mb-2" height="3rem" borderRadius="16px" v-else></Skeleton>
  </div>
</template>

<script>
import {onMounted, ref} from "vue";
import {useStore} from "vuex";

export default {
    name: "TopBarSecureMessageBody",
    props: ["messageID"],
    setup(props) {

        const store = useStore()
        const messageBody = ref(false);

        onMounted(async () => {

            const theMessage = await store.dispatch('loadMessageWithID', {id: props.messageID});
            messageBody.value = theMessage.messagebody;

        })

        return {
            messageBody
        }
    }
}
</script>

<style scoped>

</style>
