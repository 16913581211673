export default {
    mailboxLoadingState: (state) => {
      return state.mailboxLoading
    },
    mailboxType: (state) => {
      return state.mailboxType
    },
    messageCategoryOptions: (state) => {
      return state.messageCategoryOptions
    },
    dateOptions: (state) => {
        return state.dateOptions
    },
    senderOptions: (state) => {
        return state.senderOptions
    },
    recipientOptions: (state) => {
        return state.recipientOptions
    },
    additionalRecipients: (state) => {
        return state.additionalRecipients
    },
    recipientList: (state) => {
        return state.recipientList
    },
    senderList: (state) => {
      return state.senderList
    },
    messages: (state) => {
        return state.messages
    },
    newMessages: (state) => {
        return state.newMessages
    },
    newMessagesLoading: (state) => {
        return state.newMessagesLoading
    },
    getCurrentMessage: (state) => {
      return state.currentMessage
    },
    sentMessages: (state) => {
        return state.sentMessages
    },
    getCorrespondentFilter: (state) => {
        return state.correspondentFilter
    },
    getCategoryFilter: (state) => {
        return state.categoryFilter
    },
    mailDateRangeFrom: (state) => {
        return state.mailDateRangeFrom
    },
    mailDateRangeTo: (state) => {
        return state.mailDateRangeTo
    },
    hasGloballyTriggeredMessage: state => {
        return state.globalTriggerMessage
    },
    messageMeta: state => {
        return state.messageMeta
    }

}

