<template>
  <Dialog :visible="show" position="topright" appendTo="body" :showCloseIcon="false" id="overlay_panel" style="width: 450px;"
                :breakpoints="{'960px': '75vw'}" class="notification-panel message">
    <div class="flex flex-column title" style="padding-bottom: 30px;">
      <div class="flex justify-content-between align-items-center">
        <div class="flex">
          <span class="material-icons-round blue mr-2">email</span>
          <h3 class="blue">Secure Messaging</h3>
        </div>
        <Button class="clarity-close-modal-btn" @click="toggle(false)"><span
            class="material-icons-round grey">close</span></Button>
      </div>
    </div>

    <div v-if="loading">
      <Skeleton class="mb-2" height="2rem" width="10rem" borderRadius="16px"></Skeleton>
      <Skeleton class="mb-2" height="1rem" width="6rem" borderRadius="16px"></Skeleton>
      <Skeleton class="mb-2" height="3rem" borderRadius="16px"></Skeleton>
    </div>

    <div v-if="!loading">
      <div v-if="!messages.length">
        No new Messages
      </div>
      <div v-else>
      <div v-for="message in messages" :key="message.message_id" class="messagebox"
           style="border-top: 2px solid var(--clarity-light-grey)">
        <div class="flex align-items-center">
          <span class="material-icons-round grey mr-2">email</span>
          <h3 class="grey mt-1">New Message</h3>
        </div>
        <div class="pl-6" style="overflow-wrap: anywhere">
          <div class="bold my-2" style="font-size: 18px">{{ message.subject }}</div>
          <div class="grey my-2" style="font-size: 14px">{{ $date(message.date_sent, 'DD/MM/YYYY') }}</div>
          <div class="dark-grey my-2" style="font-size: 14px">
              <top-bar-secure-message-body :messageID="message.message_id"></top-bar-secure-message-body>
          </div>
          <div class="light-blue my-2 pb-3 cursor-pointer link-thru" style="font-size: 18px"
               @click="goToMessage(message.message_id)">Read more
          </div>
        </div>
      </div>
    </div>
    </div>
  </Dialog>
</template>

<script>
import {computed, inject, onBeforeUnmount, ref} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import TopBarSecureMessageBody from "./TopBarSecureMessageBody.vue";

export default {
  name: "TopBarSecureMessagesNotificationCard",
  components: {TopBarSecureMessageBody},
  setup() {
    const bus = inject('bus')
    const store = useStore()
    const router = useRouter()
    const show = ref(false)
    const loading = computed(() => store.getters.newMessagesLoading)

    bus.on('showMessageNotification', () => {
      toggle(true)
    })

    bus.on('showSystemNotification', () => {
      toggle(false)
    })

    const toggle = (event) => {
      show.value =  event !== null ? event : !show.value
    }

    const goToMessage = async (messageID) => {
      toggle(false)
      store.dispatch('confirmOpenMessage', {messageID: messageID, open: true, send: false})
      store.dispatch('openNewMessage')
      router.push('/securemessaging/message/' + messageID)
      store.dispatch('loadMessageWithID', {id: messageID})
    }

    const messages = computed(() => store.getters.newMessages)

    onBeforeUnmount(() => {
        bus.off('showMessageNotification')
        bus.off('showSystemNotification')
    });

    return {
      loading,
      messages,
      show,
      goToMessage,
      toggle
    }
  }
}
</script>

<style scoped>
.messagebox {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}
.link-thru:hover {
  color: var(--clarity-blue) !important;
}
</style>
