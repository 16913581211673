export default {
    currentActiveModal (state) {
        return state.currentActiveModal
    },
    activeModalOrigin (state) {
        return state.activeModalOrigin
    },
    activeModalMode (state) {
        return state.activeModalMode
    }
}