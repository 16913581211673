/* istanbul ignore file */

import axios from 'axios'
import store from "@/store";
import xhrAdapter from './axiosAdapter';
import router from "../../router";
import RD3Error from "../classes/RD3Error";

export const makeAxiosInstance = (baseApiURL) => {
    let instance = axios.create({
        baseURL: baseApiURL,
        adapter: xhrAdapter
    })

    instance.interceptors.request.use(async (config) => {

        //check user auth before submitting a back end request.
        await store.dispatch('checkUserAuth')

        if(store.state.auth.cognitoUser.signInUserSession) {
            config.headers.common.Authorization = 'Bearer ' + store.state.auth.cognitoUser.signInUserSession.accessToken.jwtToken
            config.headers.common.Clarityjwt = 'Bearer ' + store.state.auth.cognitoUser.signInUserSession.idToken.jwtToken
        }


        // when a target uuid is set, pass it as a header.
        if(store.state.auth.targetUUID){
            config.headers.common["x-clarity-targetuuid"] = store.state.auth.targetUUID;
        }

        return config;
    }, (error) => {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            // push to unexpected error page.
            router.push('/error');
            if(process.env.VUE_APP_SENTRY_REPORTING === "true") {
                throw new RD3Error('Error with request: ' + error.config.url, 'RD3 Failed Request');
            }
    });

    instance.interceptors.response.use((response) => {

        return response;
    }, (error) => {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // return the response. should be in format {message: '', success: false, error: true}
            return error.response.data;
          } else {
            // Something happened in setting up the request that triggered an Error
            // push to unexpected error page.
            router.push('/error');
            if(process.env.VUE_APP_SENTRY_REPORTING === "true") {
                throw new RD3Error('Error with request: ' + error.config.url, 'RD3 Failed Request - 2');
            }
          }
    });

    return instance;
}
