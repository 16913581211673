<template>
  <component :is="layout">
    <router-view/>
  </component>
</template>

<script>
import AppLayoutDefault from "./layouts/AppLayoutDefault";
import AppLayoutLogin from "./layouts/AppLayoutLogin";
import AppLayoutMain from "./layouts/AppLayoutMain";
import {onMounted} from "vue";

export default {
  components: {
    AppLayoutMain,
    AppLayoutLogin,
    AppLayoutDefault
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || AppLayoutDefault)
    },
  },
  setup() {

      let Script = document.createElement("script");
      Script.setAttribute("src", "//fast.appcues.com/33926.js");
      document.head.appendChild(Script);

      onMounted(() => {
          window.AppcuesSettings = {
              enableURLDetection: true
          };
      })
  }
}
</script>

<style lang="scss">
  @import './App.scss';

  #nav {
    padding: 30px;

    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }
  body {
    background-color: var(--clarity-snow-grey);
  }
</style>
