import api from '@/core/services/api'
import { toDrupalRequest } from '@/core/helpers';

export default {
    // currency
    async getCurrencyOptions({ commit, state }) {
        if(state.currencyLoading) {
            const endpoint = '/rest/api/get/currencies';
            const response = await api.get(`${toDrupalRequest(endpoint)}`)
            commit('setCurrencyOptions', response.data)
            commit('setCurrencyLoading', false);
        }
    },
    setCurrencySelected({ commit }, value) {
        commit('setCurrencySelected', value)
    },
    // portfolio
    async getPortfolioOptions({ commit, state }) {
        if(state.portfolioLoading) {
            const endpoint = '/valuation/portfolios';
            const response = await api.get(`${toDrupalRequest(endpoint)}`)
            commit('setPortfolioOptions', response)
            commit('setPortfolioIMSList', response)
            commit('setPortfolioLoading', false);
        }
    },
    async getPortfolioTotal(_, portfolioUuid) {
        const endpoint = `valuation/summary/total/${portfolioUuid}`
        const res = await api.get(`${toDrupalRequest(endpoint)}`)

        return res
    },
    setPortfolioSelected({ commit }, value) {
        commit('setPortfolioSelected', value)
    },
    setEditPortfolioSelected({ commit }, value) {
        commit('setEditPortfolioSelected', value)
    },
    addCustomPortfolio({ commit }, payload) {
        commit('addCustomPortfolio', payload)
    },
    deleteCustomPortfolio({ commit }, payload) {
        commit('deleteCustomPortfolio', payload)
    },
    updateCustomPortfolio({ commit }, payload) {
        commit('updateCustomPortfolio', payload)
    },
    // policy
    async getPolicyOptions({ commit, state }) {
        if(state.policyLoading) {
            const endpoint = '/valuation/plans';
            const plans = await api.get(`${toDrupalRequest(endpoint)}`)
            commit('setPolicyOptions', plans)
            commit('setPolicyLoading', false);
        }
    },
    setPolicySelected({ commit }, value) {
        commit('setPolicySelected', value)
    },
    setPerformancePolicySelected({ commit }, value) {
        commit('setPerformancePolicySelected', value)
    },
    // people
    async getPeopleOptions({ commit, state }) {
        if(state.peopleLoading) {
            const endpoint = '/permissions/client/people';
            const response = await api.get(`${toDrupalRequest(endpoint)}`)
            commit('setPeopleOptions', response)
            commit('setPeopleLoading', false);
        }
    },
    setPeopleSelected({ commit }, value) {
        commit('setPeopleSelected', value)
    },
    // Misc
    toggleFilterPanel({ commit }) {
        commit('toggleFilterPanel')
    },
    setPeriodSelected({ commit }, value) {
        commit('setPeriodSelected', value)
    },
}
