import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default {
    state() {
        return {
            portfolioOptions: null,
            portfolioSelected: 'all',
            editPortfolioSelected: 'all',
            portfolioLoading: true,
            policyOptions: [],
            policySelected: null,
            performancePolicySelected: null,
            policyLoading: true,
            imsPortfolioList: [],
            dfmPortfolioList: [],
            peopleLoading: true,
            peopleOptions: null,
            peopleSelected: null,
            currencyOptions: null,
            currencySelected: 'GBP',
            currencyLoading: true,
            filterPanelOpen: true,
            periodOptions: [
                { label: "1 Month", value: 1},
                { label: "3 Months", value: 3},
                { label: "6 Months", value: 6},
                { label: "12 Months", value: 12},
                { label: "18 Months", value: 18},
                { label: "2 Years", value: 24},
                { label: "3 Years", value: 36},
                { label: "4 Years", value: 48},
                { label: "5 Years", value: 60},
            ],
            periodSelected: 12
        }
    },
    mutations,
    actions,
    getters
}
