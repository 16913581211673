<template>
    <div class="help-prompt">
        <a :href="clarityURL+'/newfaqs'" target="_blank">
         <div class="flex justify-content-center align-items-center mr-2 clarity-gradient-orange clarity-btn help-btn" >
             <div class="flex flex-column align-items-center">
                <span class="material-icons-round white">help</span>
                <div class="small-text white">Help</div>
             </div>
         </div>
        </a>
    </div>
</template>
<script>
export default {
    name: "HelpLauncher",
    setup() {
        const clarityURL = process.env.VUE_APP_CLARITYURL;
        return {
            clarityURL
        }
    }
}
</script>
<style scoped lang="scss">
.help-btn {
  border: 2px solid var(--clarity-pure-white) !important;
  color: var(--clarity-pure-white);
  height: 70px;
  width: 70px;
  border-radius: 50px;
  position: fixed;
  right: 30px;
  bottom: 30px;

  &:hover {
    height: 100px;
    width: 100px;
    right: 15px;
    bottom: 15px;
  }
}
.help-prompt {
    position: fixed;
    right: 30px;
    bottom: 30px;
    z-index: 1002;
}
</style>
<script setup>
</script>
