<template>
    <Modal id="searchResultsModal">
        <template #body>
            <div class="flex flex-column">
                <div class="flex justify-content-between align-items-center title">
                    <h3 class="blue"><img src="../../assets/icons/search-icon-blue.svg">YOUR RESULTS FOR: {{ searchTerm }}</h3>
                    <Button class="clarity-close-modal-btn" @click="closeModal"><span class="material-icons-round grey">close</span></Button>
                </div>
                <div class="search-title-divide">
                    <hr/>
                </div>
            </div>

            <div class="flex search-padding">
                <search-input :mobile="true"></search-input>
            </div>

            <div class="content" >
                <div v-if="searchReaults">
                    <div id="cta-results" v-if="cta.length > 0"  class="flex flex-column" style="width: 100%">
                        <div class="flex">
                            <h3 class="grey"><img src="../../assets/icons/investments-icon.svg">{{ ctaCategory }}</h3>
                        </div>
                        <div class="flex">
                            <p class="body-text"><b>Main suggested actions available:</b></p>
                        </div>
                        <div class="flex cta-btns flex-wrap">
                            <div v-for="btn in cta" :key="btn.id">
                                <Button class="clarity-btn clarity-margin-top-s" :class="btn.color" @click="openLink(btn.link)">{{ btn.name }}</Button>
                            </div>
                        </div>
                        <hr/>
                    </div>

                    <div id="faq-results" v-if="faqs != undefined" class="flex flex-column clarity-margin-top-xs" style="width: 100%">
                        <div class="flex">
                            <h3 class="grey"><img src="../../assets/icons/help-icon.svg">FAQs</h3>
                        </div>
                        <div class="flex">
                            <p class="body-text"><b>{{ faqs.title }}</b></p>
                        </div>
                        <div class="flex">
                            <p class="small-text" v-html="faqs.text"></p>
                        </div>
                        <div class="flex clarity-margin-bottom-xs">
                            <a :href="clarityURL+'/newfaqs'" target="_blank">Read more</a>
                        </div>
                        <div v-if="faqCount > 1" class="flex load-more-btn clarity-margin-bottom-xs">
                            <Button class="clarity-btn clarity-gradient-grey" @click="loadMoreFaq"><span class="material-icons-outlined">autorenew</span>Load more FAQs</Button>
                        </div>
                        <hr/>
                    </div>

                    <div id="news-results" v-if="news != undefined" class="flex flex-column clarity-margin-top-xs" style="width: 100%">
                    <div class="flex">
                        <h3 class="grey"><img src="../../assets/icons/article-icon.svg">CLARITY NEWS</h3>
                    </div>
                    <div class="flex">
                        <p class="body-text"><b>{{  news.title }}</b></p>
                    </div>
                    <div class="flex">
                        <p class="small-text grey">{{ news.date_published }}</p>
                    </div>
                    <div class="flex">
                        <p class="small-text">{{ news.summary }}</p>
                    </div>
                    <div class="flex clarity-margin-bottom-xs">
                        <a @click="loadNews(news.id)">Read more</a>
                    </div>
                    <div v-if="newsCount > 1" class="flex load-more-btn clarity-margin-bottom-xs">
                        <Button class="clarity-btn clarity-gradient-grey" @click="loadMoreNews"><span class="material-icons-outlined">autorenew</span>Load more news</Button>
                    </div>
                    <hr/>
                </div>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script>
import api from '@/core/services/api';
import { toDrupalRequest } from '@/core/helpers';
import Modal from '@/components/common/Modal';
import {ref, inject, onBeforeUnmount} from "vue";
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import SearchInput from "../common/SearchInput.vue";

export default {
    setup() {
        const store = useStore();
        const router = useRouter();
        const bus = inject('bus');
        const clarityURL = process.env.VUE_APP_CLARITYURL;

        const searchTerm = ref('');
        const searchReaults = ref(false);
        const news = ref([]);
        const cta = ref([]);
        const faqs = ref([]);
        const ctaCategory = ref('');
        const faqCounter = ref(0);
        const newsCounter = ref(0);
        const faqCount = ref(0);
        const newsCount = ref(0);

        bus.on('clientSearchTerm', (term) => {
            getSearchData(term);
        })

        const getSearchData = async (term) => {
            searchTerm.value = term;
            const endpoint = '/rdsearch/' + searchTerm.value;
            const response = await api.get(`${toDrupalRequest(endpoint)}`)
            searchReaults.value = response;
            news.value = response.news[0];
            faqs.value = response.faq[0];
            newsCount.value = response.news.length;
            faqCount.value = response.faq.length;
            if(response.cta.length > 0) {
                cta.value = response.cta;
                ctaCategory.value = response.cta[0].category;
            }
        }

        const closeModal = () => {
            searchTerm.value = '';
            searchReaults.value = false;
            news.value = [];
            cta.value = [];
            faqs.value = [];
            ctaCategory.value = '';
            store.dispatch('setActiveModal');
        }

        const loadMoreFaq = () => {
            const faqLength = searchReaults.value.faq.length - 1;
            if(faqLength > faqCounter.value) {
                faqCounter.value++;
                faqs.value = searchReaults.value.faq[faqCounter.value];
            } else {
                faqCounter.value = 0;
                faqs.value = searchReaults.value.faq[faqCounter.value];
            }
        }

        const loadMoreNews = () => {
            const newsLength = searchReaults.value.news.length - 1;
            if(newsLength > newsCounter.value) {
                newsCounter.value++;
                news.value = searchReaults.value.news[newsCounter.value];
            } else {
                newsCounter.value = 0;
                news.value = searchReaults.value.news[newsCounter.value];
            }
        }

        const openLink = (link) => {
            switch(link) {
                case clarityURL+'/newsviews':
                    window.open(link);
                    break;
                case clarityURL+'/newfundfocus':
                    window.open(link);
                    break;
                case clarityURL+'/toolsvideos':
                    window.open(link);
                    break;
                default:
                    router.push(link);
            }
            closeModal();
        }

        const loadNews = (id) => {
            const link = clarityURL+'/newsarticle?id=' + id;
            window.open(link);
        }

        onBeforeUnmount(() => {
            bus.off('clientSearchTerm')
        });

        return {
            searchTerm,
            news,
            cta,
            ctaCategory,
            faqs,
            faqCount,
            newsCount,
            closeModal,
            loadMoreNews,
            loadMoreFaq,
            loadNews,
            openLink,
            clarityURL,
            searchReaults
        }
    },
    components: {
        Modal,
        SearchInput
    }
}
</script>

<style scoped>
    hr {
      width: 100%;
      color: var(--clarity-light-grey);
    }
    h3 img {
        margin-right: 12px;
        vertical-align: top;
    }
    #news-results a, #faq-results a {
        color: var(--clarity-light-blue);
        margin-left: 38px;
        cursor: pointer;
    }
    .title {
        padding: 30px 30px 0px 30px;
    }
    .search-padding {
        padding: 30px 30px 0px 0px;
    }
    .search-title-divide {
        margin-left: 30px;
        margin-right: 30px;
    }
    .content {
        padding: 30px 30px 30px 30px;
    }
    .content p {
        margin-left: 38px;
    }
    div:deep(.modal-close) {
        display: none;
    }
    .cta-btns {
        margin-left: 38px;
        margin-bottom: 35px;
    }
    .cta-btns button {
        margin-right: 20px;
    }
    .load-more-btn {
        margin-left: 33px;
    }
    .material-icons-outlined {
        margin-right: 10px;
        vertical-align: top;
    }
    div:deep(.modal-body) {
        overflow-x: hidden !important;
    }
    #searchResultsModal:deep(.modal-body) {
        width: 60%;
        @media only screen and (max-width: 1366px) {
            width: 85%;
        }
        @media only screen and (max-width: 768px) {
            width: 95%;
        }
    }
</style>
