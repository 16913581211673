import api from '@/core/services/api'
import {toDrupalRequest} from '@/core/helpers';

export default {
    async getValuationSummaryChartData({commit, getters}, filter) {
        let chartDataEndpoint = '/valuation/summary/plantotals';

        const uuidArray = getters.policyUUIDArray;
        // we need to add the current policy string
        chartDataEndpoint += '/' + uuidArray.join(",");

        // add the date on if provided.
        if (filter.date) {
            chartDataEndpoint += '/' + filter.date;
        }

        const response = await api.get(`${toDrupalRequest(chartDataEndpoint)}`)
        commit('setValuationSummaryChartData', response);
    },
    async getPolicyValuationSummaryChartData({commit, getters}, filter) {
        let chartDataEndpoint = '/valuation/summary/policychart';

        chartDataEndpoint += '/' + getters.policySelected.value

        if(filter.period){
            chartDataEndpoint += '/'+filter.period;
        }

        // add the date on if provided.
        if (filter.date) {
            chartDataEndpoint += '/' + filter.date;
        }

        const response = await api.get(`${toDrupalRequest(chartDataEndpoint)}`)
        commit('setPolicyChartData', response.chartData);
    },
    setValuationDate({commit}, value) {
        commit('setValuationDate', value)
    },
    // dateRange
    setDateRangeFrom({commit}, value) {
        commit('setDateRangeFrom', value)
    },
    setDateRangeTo({commit}, value) {
        commit('setDateRangeTo', value)
    },
    // Policy Holdings Dialogs
    setCurrentISIN({commit, dispatch, state}, isin) {
        if(state.currentISIN !== isin) {
            commit('setCurrentISIN', isin)
            // reset data
            commit('resetFundDialogData')
            // fire 3x actions
            dispatch('fundHistory', isin)
            dispatch('getFundAllocationDialogData', isin)
            dispatch('fundInfo', isin)
        }
    },
    async getFundAllocationDialogData({commit, getters}, data) {
        let valuationDate = getters.valuationDate
        const response = await api.get(`${toDrupalRequest('assetallocation/fund/' + data.isin + '/' + valuationDate.format('YYYY-MM-DD'))}`)
        if (response) {
            commit('setFundAllocationDialogData', response)
        }else{
            commit('setFundAllocationDialogData', {"error": true})
        }
    },
    async fundHistory({commit}, data) {
        const response = await api.get(toDrupalRequest('/performance/fund/' + data.isin))
        if (response.fundData) {
            commit('setFundHistoryDialogData', response.fundData)
        } else {
            commit('setFundHistoryDialogData', response)
        }
    },
    async fundInfo({commit}, data) {
        const response = await api.get('/morningstar/fundinfotooltipdata?isin=' + data.isin)
        if (response.theData) {
            commit('setFundInfoDialogData', {...response.theData, name: data.name})
        } else {
            commit('setFundInfoDialogData', {...response, name: data.name})
        }
    },
    // Benchmarks
    async setNewBenchmark({ dispatch, commit }, data) {
        const response = await api.post(
            `${toDrupalRequest('valuation/portfolios/benchmark/' + data.portfolioId)}`,
            JSON.stringify(data.benchmark),
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        )
        if (response.benchmarkID) {
            // returns newly created benchmark id as a string
            commit('setPortfolioLoading', true);
            dispatch('getPortfolioOptions')
            return 'true'
        }
    },
    async updateBenchmark({ dispatch, commit }, data) {
        const response = await api.patch(
            `${toDrupalRequest('valuation/portfolios/benchmark/' + data.portfolioId)}`,
            JSON.stringify(data.benchmark),
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        )
        if (response.success) {
            commit('setPortfolioLoading', true);
            dispatch('getPortfolioOptions')
            return 'true'
        }
    }
}
