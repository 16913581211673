<template>
  <Dialog :visible="show" position="topright" appendTo="body" :showCloseIcon="false" id="overlay_panel"
          style="width: 450px"
          :breakpoints="{'960px': '75vw'}" class="notification-panel system">

    <div class="flex flex-column title" style="padding-bottom: 30px;">
      <div class="flex justify-content-between align-items-center">
        <div class="flex">
          <span class="material-icons-round blue mr-2">notifications</span>
          <h3 class="blue">Notifications</h3>
        </div>
        <Button class="clarity-close-modal-btn" @click="toggle(false)"><span
            class="material-icons-round grey">close</span></Button>
      </div>
    </div>
    <!--    <div v-if="loading">-->
    <!--      <Skeleton class="mb-2" height="2rem" width="10rem" borderRadius="16px"></Skeleton>-->
    <!--      <Skeleton class="mb-2" height="1rem" width="6rem" borderRadius="16px"></Skeleton>-->
    <!--      <Skeleton class="mb-2" height="3rem" borderRadius="16px"></Skeleton>-->
    <!--    </div>-->

    <div v-if="!loading && !approvals.length">
      No pending IMS Approvals
    </div>

    <div v-else>
      <div class="messagebox" style="border-top: 2px solid var(--clarity-light-grey)">
        <div class="flex align-items-center">
          <span class="material-icons-round grey mr-2">bar_chart</span>
          <h3 class="grey mt-1">IMS Approval Centre</h3>
        </div>
        <div class="pl-6" style="overflow-wrap: anywhere">
          <div class="bold my-2" style="font-size: 18px">You have {{ approvals.length }}
            {{ approvals.length > 1 ? 'applications' : 'application' }} not approved yet. Please check the list of
            applications and submit everything for approval.
          </div>
          <div class="light-blue my-2 pb-3 cursor-pointer link-thru" style="font-size: 18px"
               @click="goToPage('ims/imshome')">Continue Now
          </div>
        </div>
      </div>
    </div>

  </Dialog>
</template>

<script>
import {computed, inject, onBeforeUnmount, ref,} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";

export default {
  name: "TopBarSystemNotificationsCard",
  components: {},
  setup() {
    const bus = inject('bus')
    const store = useStore()
    const router = useRouter()
    const show = ref(false)
    const loading = ref(false)

    bus.on('showSystemNotification', () => {
      toggle(true)
      // @TODO: ims approvals loaded on app load/approval. Loading state preserved in preparation for open banking or news updates in this card/panel
      // loading.value = true
    })

    bus.on('showMessageNotification', () => {
      toggle(false)
    })


    const toggle = (event) => {
      show.value = event !== null ? event : !show.value
    }

    const goToPage = async (page) => {
      toggle(false)
      router.push(`/${page}`)
    }

    const approvals = computed(() => store.getters.getApprovalRecords)

    onBeforeUnmount(() => {
        bus.off('showMessageNotification')
        bus.off('showSystemNotification')
    });

    return {
      loading,
      approvals,
      show,
      goToPage,
      toggle
    }
  }
}
</script>

<style scoped>
.messagebox {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}

.link-thru:hover {
  color: var(--clarity-blue);
}
</style>
