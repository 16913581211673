export default {
    user (state) {
        return state.cognitoUser
    },

    isAuthenticated (state) {
        return !!state.isAuthenticated
    },

    clarityUUID (state) {
        return state.cognitoUser ? state.cognitoUser.attributes['custom:clarityUUID'] : false
    },

    targetUUID (state) {
        return state.targetUUID
    },

    getNewUserEmail (state) {
        return state.newUserEmail
    },

    getStageOneAuthData (state) {
        return state.userStageOneData
    },

    getStageTwoAuthData (state) {
        return state.userStageTwoData
    },

    getStageThreeAuthData (state) {
        return state.userStageThreeData
    },
    getAutoLogoutState (state) {
        return state.userDidAutoLogout
    }
}
