import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default {
    state() {
        return {
            currentActiveModal: null,
            activeModalOrigin: null,
            activeModalMode: null,
        }
    },
    mutations,
    actions,
    getters
}