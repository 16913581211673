import {kebabCase, startCase, camelCase} from "lodash";
import dayjs from "dayjs";
import {computed} from "vue";
import store from '../../store/index';

class Formatter {
    constructor(config) {
        this.config = config
    }
    setConfig(config) {
        this.config = config
        this.numberFormatter = new Intl.NumberFormat(this.config.locale)
    }
    // defaults to 2dp
    number(number, type, dp = 2) {
        let numVal;
        switch (type) {
            case 'number':
                numVal = Number(number).toFixed(dp)
                if (numVal < 0) {
                    return `(${Math.abs(numVal)})`
                }
                return `${numVal}`
            case 'percentage':
                numVal = Number(number).toFixed(dp)
                return `${numVal}%`
            default:
                return this.numberFormatter.format(number);
        }
    }

    case(value, casing) {
        switch (casing) {
            case 'kebab':
                return kebabCase(value);
            case 'title':
                return startCase(value);
            case 'camel':
                return camelCase(value);
            default:
                return value
        }
    }

    date(date, format) {
        if(date) {
            return dayjs(date).format(format)
        }
    }

    currency(currencyCode, value, decimalPlaces, asNumber = false) {
        let dp = decimalPlaces ?? 2
        if(typeof currencyCode === 'string' && currencyCode !== 'GBP'){
            // if display is not in GBP we need to convert the currency.
            const currencyOptions = computed(() => store.state.selections.currencyOptions);
            if(currencyOptions.value) {
                const conversionCurrency = currencyOptions.value.find(obj => {
                    return obj.currencyCode === currencyCode
                });
                if (conversionCurrency) {
                    value = value * conversionCurrency.conversionrate;
                }
            }
        }

        if(asNumber) {
            const pow = Math.pow(10, decimalPlaces);
            return Math.round(value*pow) / pow;
        }else{
            return new Intl.NumberFormat('en-GB', {
                minimumFractionDigits: dp,
                maximumFractionDigits: dp,
                style: 'currency',
                currency: currencyCode
            }).format(value)
        }
    }
}
export const formatter = new Formatter({locale: 'en-GB'})
export const $f = formatter
