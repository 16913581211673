import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default {
    state() {
        return {
            adviserName: false,
            buylist: false,
            checkpoint: false,
            clientImageBase64: false,
            clientName: false,
            clientServices: false,
            collapsedLeftNav: true,
            group: '',
            homepage: false,
            mfaEnabled: false,
            newNotification: 0,
            newSecureMessage: 0,
            reportingCurrency: 'GBP', // yet to be implemented??
            userIDHash: false,
            method: '',
            elementVisibility: false
        }
    },
    mutations,
    actions,
    getters
}
