<template>
  <div>
    <div class="grid">
      <LoginImage :imageSelect="backgroundImageString" class="login-image col-4"/>
      <div class="col-8 p-0 login-panel-right">
        <div class="flex flex-column card-container blue-container overflow-auto" style="height: 100vh">
          <div class="clarity-logo">
            <img src="../assets/logos/clarity-logo-blue.svg">
          </div>
          <div class="flex-grow-1 flex align-items-center justify-content-center px-5 py-3">

            <div class="login-content">
              <slot/>
            </div>
          </div>
          <div class="flex-none flex align-items-center justify-content-center m-2 px-5 py-3" id="footer">
            <LoginFooter/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginImage from '@/components/LoginWelcomeImage.vue';
import LoginFooter from "@/components/nav/LoginFooter";

export default {
  name: "AppLayoutLogin",
  components: {
    LoginFooter,
    LoginImage,
  },
  computed: {
    backgroundImageString() {
      if (this.$route.meta.imageClass) {
        return this.$route.meta.imageClass
      }
      return 'loginBackgroundImg'
    }
  }
}
</script>

<style scoped lang="scss">
.login-image {
  @include mob {
    display: none;
  }
}
.clarity-logo {
  display: none;
  @include mob {
    display: flex;
  }
  background-color: var(--clarity-snow-grey);
  width: 100%;
  top: unset;
  left: unset;
  padding: 30px 20px 0px 30px;
  z-index: 5;
}

.login-panel-right {
  @include mob {
    width: 100%;
  }
}

.login-content {
  max-width: 610px;
  margin: 90px auto;
  @include mob {
    margin: 90px 0 0 0;
  }
}

hr {
  height: 1px;
  border-width: 0;
  color: #DCE1E6;
  background-color: #DCE1E6;
}

#footer .footer-wrapper {
  position: relative;
}
.grid {
  margin-top: 0px;
}
</style>
