<template>
  <div class="min-h-screen flex relative lg:static surface-ground" @click="mouseClick" @keyup="keyboardUse" @touchstart="touchTrigger">
    <Drawer :open="open" :closed="closed" v-if="showMenu"/>
    <MobileMenu :group-bar="checkpoint.checkpoint"/>
    <div class="min-h-screen flex flex-column flex-auto contentdiv"
         :class="{'wide': open, 'narrow': closed, 'showMenu': showMenu} ">
      <GroupHeader v-if="checkpoint.checkpoint"/>
      <Header @toggleDrawer="toggleDrawer" :drawer="open" :group-bar="checkpoint.checkpoint"
              :showMobileMenu="showMobile"/>
      <div class="flex flex-column flex-auto main-body" @wheel="onWheel">
        <div>
          <slot />
        </div>
        <Footer />
      </div>
    </div>
    <ToastError />
    <WelcomeTourModal v-show="showModal === 'WelcomeTour'"/>
    <AutoLogoutWarningModal v-show="showModal === 'AutoLogout'"/>
  </div>
</template>

<script>
import {computed, inject, onBeforeUnmount, onMounted, ref, watch} from 'vue'
import Drawer from '@/components/nav/SidebarMenu.vue'
import Header from '@/components/nav/Header.vue'
import Footer from '../components/nav/Footer.vue'
import ToastError from "../components/common/ToastError";
import GroupHeader from "@/components/nav/GroupHeader";
import MobileMenu from "@/components/nav/MobileMenu";
import {useStore} from "vuex";
import WelcomeTourModal from "@/components/modals/WelcomeTourModal";
import AutoLogoutWarningModal from '../components/modals/AutoLogoutWarningModal.vue';
import { useRoute } from 'vue-router'
import { useRouter } from 'vue-router';

export default {
  name: "AppLayoutMain",
  setup() {
    const store = useStore()
    const bus = inject('bus')
    const preference = computed(() => store.getters.getCollapsedNav);
    const showModal = computed(() => store.getters.currentActiveModal)
    const open = ref(false);
    const closed = ref(true);
    const screenWidth = ref(0)
    const showMenu = ref(true)
    const showMobile = ref(false)
    const logoutTimer = ref();
    const finalLogoutTimer = ref();

    const route = useRoute();
    const router = useRouter();

    if(route.query.targetuuid) {
        store.dispatch('setTargetUUID',route.query.targetuuid);
    }

    watch(preference, val => {
      if (val === true) {
        closed.value = true
        open.value = false
      } else  {
        closed.value = false
        open.value = true
      }
    })

    if(store.getters.getCollapsedNav !== true){
        closed.value = false
        open.value = true
    }

    // show/hide menu on tablet and mobile screens
    watch(screenWidth, val => {
      //@TODO: pull this into switch that dispatches events for each screen size
      // collapse menu on tablets and lower
      if (val > 1366) {
        showMenu.value = true
        bus.emit('medium')
        store.dispatch('setTabView', 'headers')
      }

      if (1365 > val && val > 1024) {
        showMenu.value = true
        showMobile.value = false
        bus.emit('small')
        store.dispatch('setTabView', 'headers')
      }

      if (1024 > val && val > 768) {
        showMenu.value = true
        showMobile.value = false
        open.value = false
        closed.value = true
        bus.emit('tablet')
        store.dispatch('setTabView', 'mobile')
      }
      // hide menu on phones
      if (val < 768) {
        showMenu.value = false
        showMobile.value = true
        bus.emit('mobile')
        store.dispatch('setTabView', 'mobile')
      }
    })

    // window.onresize = () => {
    window.onresize = () => {
      getScreen()
    }

    const getScreen = () => {
      screenWidth.value = document.documentElement.clientWidth
    }

    onMounted(() => {
      store.dispatch('getPreferences')
      store.dispatch('getWebSettings')
      store.dispatch('getPersonalDetails')
      store.dispatch('getImsSendMethodOption')
      getScreen()
      startLogoutTimer()
    })

    const toggleDrawer = () => {
      closed.value = !closed.value
      open.value = !open.value
    }

    const stopScrolling = ref(false)
    bus.on('stopScroll', () => {
      stopScrolling.value = true
    })
    bus.on('allowScroll', () => {
      stopScrolling.value = false
    })
    const onWheel = (e) => {
      return stopScrolling.value === true ? e.preventDefault() : true
    }

    const startingTime = () => {
        if(process.env.VUE_APP_TARGET_OVERRIDE === 'true') {
            return 6900000;
        }
        return 1500000;
    }

    const finalTime = () => {
        if(process.env.VUE_APP_TARGET_OVERRIDE === 'true') {
            return 300000;
        }
        return 300000;
    }

    const startLogoutTimer = () => {
      // Auto logout after 25 minutes.  First timer is 25 minutes (1500000 milliseconds).
      logoutTimer.value = setTimeout(() => {
        store.dispatch('setActiveModal', {modal: 'AutoLogout'});
        // check the user auth to check session is still valid and renew tokens.
        store.dispatch('checkUserAuth')
        startFinalLogoutTimer();
      }, startingTime());
    }

    const startFinalLogoutTimer = () => {
      // Final 5 minute warning for auto logout. (300000 milliseconds).
      finalLogoutTimer.value = setTimeout(() => {
          bus.emit('autoLogoutPreserveMessage')
          store.dispatch('setActiveModal');
          router.push('/logout?s=autologout');
      }, finalTime())
    }

    const mouseClick = () => {
      clearTimeout(logoutTimer.value);
      clearTimeout(finalLogoutTimer.value);
      startLogoutTimer();
    }

    const keyboardUse = () => {
      clearTimeout(logoutTimer.value);
      clearTimeout(finalLogoutTimer.value);
      startLogoutTimer();
    }

    const touchTrigger = () => {
      clearTimeout(logoutTimer.value);
      clearTimeout(finalLogoutTimer.value);
      startLogoutTimer();
    }

    bus.on('sidebarmouseclick', () => {
      // Catch mouse clicks from the side menu bar.
      clearTimeout(logoutTimer.value);
      clearTimeout(finalLogoutTimer.value);
      startLogoutTimer();
    });

    bus.on('closeAutoLogoutWarning', () => {
      // Catch auto logout warning close click.
      clearTimeout(logoutTimer.value);
      clearTimeout(finalLogoutTimer.value);
      startLogoutTimer();
    });

    onMounted(() => {
        // check for secure message recovery data. If there is recovery data we need to start a message
        const recovery = store.state.messages.messageRecovery;
        if(recovery){
            if(recovery.uuid === store.getters.clarityUUID) {
                store.dispatch('globalMessageTrigger', {
                    subject: recovery.message.subject,
                    category: recovery.message.category,
                    recipient: recovery.message.recipient.name,
                    messagebody: recovery.message.messagebody
                })
                store.dispatch('resetMessageRecovery');
                router.push('/securemessaging/message');
            }else{
                //uuid mismatch, clear out the recovery data.
                store.dispatch('resetMessageRecovery');
            }
        }
    })

    onBeforeUnmount(() => {
        bus.off('stopScrol')
        bus.off('allowScroll')
        bus.off('sidebarmouseclick')
        bus.off('closeAutoLogoutWarning')
        clearTimeout(logoutTimer.value);
        clearTimeout(finalLogoutTimer.value);
    });

    return {
      open,
      closed,
      toggleDrawer,
      checkpoint: computed(() => store.getters.getClientServices),
      showMenu,
      showMobile: computed(() => showMobile.value),
      showModal,
      onWheel,
      mouseClick,
      keyboardUse,
      touchTrigger
    }
  },
  components: {
    WelcomeTourModal,
    AutoLogoutWarningModal,
    GroupHeader,
    ToastError,
    Header,
    Drawer,
    Footer,
    MobileMenu
  }
}
</script>

<style scoped lang="scss">
.wide {
  margin-left: 0;

  &.showMenu {
    margin-left: 465px;
  }
  @media only screen and (max-width: 1366px) {
    &.showMenu {
      margin-left: 320px;
    }
  }
}
.contentdiv {
  width: calc(100% - 465px);
}
.narrow {
  margin-left: 0;

  &.showMenu {
    margin-left: 145px;
    @include sm {
      margin-left: 120px;
    }
    @include tablet {
      margin-left: 120px;
    }
  }
}
.main-body {
  top: 120px;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  justify-content: space-between;
}
</style>
