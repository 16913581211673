export default {
    setSelectedStep({commit}, payload) {
        commit('setSelectedStep', payload)
    },
    setInvestNowProvider({ commit }, provider) {
        commit('setInvestNowProvider', provider)
    },
    resetStepper({ commit }, steppers) {
        commit('resetStepper', steppers)
    }
}