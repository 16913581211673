<template>
  <div class="flex flex-row align-items-center justify-content-between" data-cy="header-profile-card">
    <Avatar :image="getAvatar()" class="avatar" shape="circle"
            size="xlarge" data-cy="profile-avatar"/>
    <div class="flex flex-column pl-2 align-items-start">
      <div class="flex align-items-center full-width justify-content-between">
        <div class="name" data-cy="profile-name" v-if="name === 'undefined undefined'">
            <Skeleton height="1rem" width="10rem" borderRadius="16px"></Skeleton>
        </div>
        <div class="name" data-cy="profile-name" v-else>{{ name }}</div>
        <Button class="clarity-btn menu-toggle-btn" @click="toggle" aria-haspopup="true"
                aria-controls="profile_card_menu" id="profile_menu_btn" data-cy="profile-card-menu-toggle">
          <span class="material-icons-round grey">expand_more</span>
        </Button>
        <Menu id="profile_card_menu" ref="menu" :model="items" :popup="true" class="profile-menu"
              style="border-radius: 27px; margin-top: 20px;">

          <template #item="{item}">
            <div v-if="item.label === 'profile'" class="flex-column m-2" :data-cy="`profile-menu-item-${item.cyp}`">
              <div class="px-2">{{ name }}</div>
              <div class="flex flex-wrap justify-content-between align-content-center p-2">
                <Pill v-if="services.investment" :label="services.investment" class="pill mr-2 mb-2"/>
                <Pill v-if="services.advice" :label="services.advice" colour="clarity-gradient-light-blue mb-2" class="pill"/>
              </div>
            </div>
            <div v-else :data-cy="`profile-menu-item-${item.cyp}`" class="clarity-menu-item">
              <a @click="(item.command) ? item.command():false; (item.to) ? $router.push(item.to):false;" class="flex align-items-center" :style="item.style"><span
                  class="material-icons-round" :style="item.style">{{ item.icon }}</span>{{ item.label }}</a>
            </div>
          </template>
        </Menu>
      </div>
      <div class="services" data-cy="services-pills">
        <Pill v-if="services.investment" :label="services.investment" class="pill"/>
        <Pill v-if="services.advice" :label="services.advice" colour="clarity-gradient-light-blue" class="pill"/>
      </div>
    </div>
  </div>
</template>
<script>
import {computed, ref, onMounted, watch} from 'vue'
import {useStore} from 'vuex'
import Pill from '../nav/Pill.vue'

export default {
  components: {
    Pill
  },
  setup() {
    const menu = ref();
    const store = useStore();
    const screenWidth = ref(0)
    const isMobile = ref(false)
    const name = computed(() => store.getters.getClientName)
    const services = computed(() => store.getters.getClientServices)

    onMounted(() => {
      getScreen()
      isMobile.value = screenWidth.value < 1366
    })

    window.onresize = () => {
      getScreen()
    }

    const getScreen = () => {
      screenWidth.value = document.documentElement.clientWidth
    }

    watch(screenWidth, (val) => {
      isMobile.value = val < 1366
    })

    const items = ref([
      {
        label: '',
        items: [
          {
            label: 'profile',
            icon: '',
            cyp: 'profile',
            visible: computed(() => isMobile.value)
          },
          {
            label: '',
            icon: '',
            separator: true,
            visible: computed(() => isMobile.value)
          },
          {
            label: 'Personal Settings',
            icon: 'manage_accounts',
            to: '/settings/personal',
            cyp: 'edit-profile',
            style: {'color': `var(--clarity-dark-grey)`}
          },
          {
            label: 'Website Settings',
            icon: 'tune',
            to: '/settings/websettings',
            cyp: 'web-settings',
            style: {'color': `var(--clarity-dark-grey)`}
          },
          {
            label: 'Refer a friend',
            icon: 'person_add',
            to: '/securemessaging/message',
            cyp: 'refer',
            style: {'color': `var(--clarity-grey)`, 'font-size': '14px;'},
            command: () => {
                store.dispatch('globalMessageTrigger', {
                    subject: 'Refer a Friend',
                    category: 'Correspondence',
                    recipient: 'Refer a Friend',
                    messagebody: 'Dear Admin Team,\n\n' +
                        'I would like to refer a friend to clarity\n\nPlease confirm your referrals contact details below\n\n' +
                        'Many thanks'
                })
            }
          },
          {
            label: '',
            icon: '',
            separator: true
          },
          {
            label: 'Logout',
            icon: 'logout',
            cyp: 'logout',
            style: {'padding': '4px'},
            command: () => {
              store.dispatch('signOut')
            }
          }
        ]
      }
    ]);

    const toggle = (event) => {
      menu.value.toggle(event);
    };

    const image = computed(() => store.getters.getProfileAvatar)

    const getAvatar = () => {
      if (image.value) {
        return `${image.value}`
      } else {
        return 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iNTYiIGhlaWdodD0iNTYiIHZpZXdCb3g9IjAgMCA1NiA1NiI+CiAgPGRlZnM+CiAgICA8bGluZWFyR3JhZGllbnQgaWQ9ImxpbmVhci1ncmFkaWVudCIgeDE9IjAuNSIgeDI9IjAuNSIgeTI9IjEiIGdyYWRpZW50VW5pdHM9Im9iamVjdEJvdW5kaW5nQm94Ij4KICAgICAgPHN0b3Agb2Zmc2V0PSIwIiBzdG9wLWNvbG9yPSIjN2NjMmUyIi8+CiAgICAgIDxzdG9wIG9mZnNldD0iMSIgc3RvcC1jb2xvcj0iIzE3OWJkNyIvPgogICAgPC9saW5lYXJHcmFkaWVudD4KICA8L2RlZnM+CiAgPHBhdGggaWQ9IlVzZXItSWNvbiIgZD0iTTMwLDJBMjgsMjgsMCwxLDAsNTgsMzAsMjguMDEsMjguMDEsMCwwLDAsMzAsMlptMCw4LjRhOC40LDguNCwwLDEsMS04LjQsOC40QTguMzg5LDguMzg5LDAsMCwxLDMwLDEwLjRabTAsMzkuNzZhMjAuMTYyLDIwLjE2MiwwLDAsMS0xNi44LTkuMDE2Yy4wODQtNS41NzIsMTEuMi04LjYyNCwxNi44LTguNjI0LDUuNTcyLDAsMTYuNzE2LDMuMDUyLDE2LjgsOC42MjRBMjAuMTYxLDIwLjE2MSwwLDAsMSwzMCw1MC4xNloiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yIC0yKSIgZmlsbD0idXJsKCNsaW5lYXItZ3JhZGllbnQpIi8+Cjwvc3ZnPgo='
      }
    }

    return {
      items,
      menu,
      getAvatar,
      toggle,
      name,
      services,
      image,
      isMobile
    }
  },
}
</script>
<style scoped lang="scss">
.avatar {

  &.p-avatar {
    background: linear-gradient(#179BD7, #0058A9);
  }

  &.p-avatar-circle {
    padding: 3px;
    box-shadow: 0 5px 10px #0000001A;
  }

  :deep(img) {
    background: var(--clarity-pure-white);
    padding: 2px;
  }

}

.name {
  color: var(--clarity-dark-grey);
  padding: 10px 15px 10px 5px;
  font-size: 16px;
  display: flex;

  @include md {
    white-space: nowrap;
  }

  @include mob-sm {
    display: none;
  }
}

span.material-icons-round {
  padding: 10px;
}

#profile_card_menu li a {
  color: var(--clarity-dark-grey);

  &:visited {
    color: var(--clarity-dark-grey);
  }

  &:hover {
    cursor: pointer;
    color: var(--clarity-blue) !important;
  }
}

.services {
  display: flex;

  .pill:first-of-type {
    margin-right: 5px;
  }

  @include mob-sm {
    display: none;
  }
}

button.menu-toggle-btn {
  padding: 0;
  font-size: 16px;
  background: transparent;
  color: var(--clarity-grey);
  border: none;
  box-shadow: none;
  height: 24px;
  width: 24px;
  min-width: 24px;

  span{
    &.material-icons-round {
      padding: 0 !important;
    }
    color: var(--clarity-grey);
  }

  &:hover {
    background: transparent;

    span {
      color: var(--clarity-blue);
    }
  }

  &:focus {
    box-shadow: none;
  }
}
</style>
