export default {
    setMoneyInOut(state, payload) {
        const key = payload.policy.value
        const k = state.performanceTransactions.find(plan => plan.key == key)

        let totalIn = 0
        let totalOut = 0

        payload.response.forEach(transaction => {
            if (transaction.performance == true && transaction.value > 0) {
                totalIn += transaction.value
            } else if(transaction.performance == true && transaction.value < 0) {
                totalOut += Math.abs(transaction.value)
            }
        })

        if (k) {
            state.performanceTransactions[state.performanceTransactions.indexOf(k)]['totals'] = {in: totalIn, out: totalOut}
        } else {
            state.performanceTransactions.push({key: key, transactions: { credits: [], debits: [] }, totals: {in: totalIn, out: totalOut}})
        }
    },
    setPerformanceTransactions(state, payload) {
        //  set the performance transactions here
        const key = payload.policy.value
        const k = state.performanceTransactions.find(plan => plan.key == key)

        const moneyIn = []
        const moneyOut = []

        payload.response.forEach(transaction => {
            if (transaction.performance == true && transaction.value > 0) {
                moneyIn.push(transaction)
            } else if(transaction.performance == true && transaction.value < 0) {
                moneyOut.push(transaction)
            }
        });

        if (k) {
            state.performanceTransactions[state.performanceTransactions.indexOf(k)]['transactions'] = { credits: moneyIn, debits: moneyOut }
        } else {
            state.performanceTransactions.push({key : key, transactions : { credits: moneyIn, debits: moneyOut }})
        }
    },
    setPerformanceTransactionTotal(state, payload) {
        const key = payload.policy
        const value = payload.value
        const point = payload.point
        const k = state.performanceTransactions.find(plan => plan.key == key)

        if (k) {
            if(state.performanceTransactions[state.performanceTransactions.indexOf(k)]['valuations']) {
                state.performanceTransactions[state.performanceTransactions.indexOf(k)]['valuations'][point] = value
            } else {
                state.performanceTransactions[state.performanceTransactions.indexOf(k)]['valuations'] = []
                state.performanceTransactions[state.performanceTransactions.indexOf(k)]['valuations'][point] = value
            }
        } else {
            state.performanceTransactions.push({
                key: key,
                transactions: { credits: [], debits: [] },
                totals: {in: 0, out: 0},
                valuations: point == 'past' ? {past: value} : {present: value}
            })
        }
    },
    setGoalSeek(state, payload) {
        const key = payload.policy
        const value = parseFloat(payload.goalSeek.value)
        const display = payload.goalSeek.months
        const k = state.performanceTransactions.find(plan => plan.key == key)

        if (k) {
            state.performanceTransactions[state.performanceTransactions.indexOf(k)]['goalSeek'] = { percent: value, months: display}
        } else {
            state.performanceTransactions.push({
                key: key,
                transactions: { credits: [], debits: [] },
                totals: {in: 0, out: 0},
                valuations: {past: 0, present: 0},
                goalSeek: { percent: value, months: display}
            })
        }
    }
}
