import dayjs from 'dayjs'

export default class GoalSeekDataFormatter {

    // get months from date ranges
    // returns [['2021-02-15', '2021-03-14']]
    getDateArray(startDate, endDate) {
        let allMonthsInPeriod = [];

        for (let i = 12; i > 0; i--) {
            const s = endDate.clone().subtract(i, "month").add(1, "day").format('YYYY-MM-DD');
            const s1 = endDate.clone().subtract(i-1, "month").format('YYYY-MM-DD');
            allMonthsInPeriod.push([s, s1]);
        }

        // console.log('allMonthsInPeriod', allMonthsInPeriod)
        return allMonthsInPeriod
    }
    // filter each transaction to get transactions into date/month buckets
    // [[start, end], [start, end]]
    filterTransactions(dateArray, transactions) {
        let result = []
        dateArray.forEach(month => {
            let t = []
            transactions.forEach(transaction => {
                // console.log('end',month[1])
                // console.log('t date', transaction.transactionDate)
                // console.log('t >= start ', transaction.transactionDate >= month[0])
                // console.log('t <= end ', transaction.transactionDate)
                if(transaction.transactionDate >= month[0] && transaction.transactionDate <= month[1]) {
                    t.push(transaction)
                }
            })
            // console.log('t', t)
            result.push({month: month, transactions: t})
        });
        // console.log('resulting array', result)
        return result
    }

    calculateMonthlyTotals(filteredTransactions) {
        let rtn = []
        filteredTransactions.forEach(month => {
            let total = 0.00
            month.transactions.forEach(transaction => {
                total += Math.abs(transaction.value)
            })
            // rtn.push([`${new dayjs(month.month[0]).format('DD-MMM-YY')} - ${new dayjs(month.month[1]).format('DD-MMM-YY')}`, total])
            rtn.push(total)
        })
        // console.log('returning', rtn)
        return rtn
    }

    cleanseForDisplay(months, transactionsIn, transactionsOut) {
        const pretty = []
        const l = months.length
        for(let i = 0; i < l; i++) {
            pretty.push({
                month: `${new dayjs(months[i][0]).format('DD MMM YY')} - ${new dayjs(months[i][1]).format('DD MMM YY')}`,
                money_in: transactionsIn[i],
                money_out: transactionsOut[i]
            })
        }
        // console.log('pretty', pretty)
        return pretty
    }
}
