<template>
    <div class="flex flex-column justify-content-center loader">
        <ProgressSpinner strokeWidth="4"  animationDuration="1s" class="size"/>
    </div>
</template>
<style scoped>
.loader {
    height: 100%;
}
.size {
    min-height: 50px;
    min-width: 50px;
    max-height: 100px;
    max-width: 100px;
    height: 50%;
    width: 50%;
}
</style>