import api from '@/core/services/api'
import { toDrupalRequest } from '@/core/helpers';

export default {
    async getDataFeeds({ commit, state }) {
        // only needs to be called if loading is true. Otherwise loading has already occurred and completed.
        if(state.dataFeedsLoading) {
            const endpoint = '/valuation/datafeeds';
            const response = await api.get(`${toDrupalRequest(endpoint)}`)
            // commit mutation to update the store accordingly.
            commit('setDataFeeds', response)
            commit('setDataFeedsLoading', false);
        }
    },
    addDataFeed({ commit }, payload) {
        commit('addDataFeed', payload)
    },
    deleteDataFeed({ commit }, payload) {
        commit('deleteDataFeed', payload)
    },
    updateDataFeed({ commit }, payload) {
        commit('updateDataFeed', payload)
    }
}
