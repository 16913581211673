export default {
    providerImageForID: (state) => (providerID) => {
        const found = state.obProviders.find((provider) => {
            return provider.provider_id === providerID
        });
        if(found){
            return found.logo_url
        }else{
            return ''
        }
    }
}

