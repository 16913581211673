<template>
  <div v-if="targetUUID" class="targetuuid">TARGET UUID: {{ targetUUID }} - <span @click="resetTarget">Click here to clear Target</span></div>
  <div
      class="header-container surface-section border-bottom-1 surface-border pad-30"
      :id="[ groupBar ? 'barHeader':'header']"
      data-cy="nav-header"
  >
    <div class="cluster-left">
      <!-- collapse menu -->
      <Button v-if="drawer" class="menu-toggle clarity-btn round clarity-gradient-blue" @click="toggleDrawer"><span
          class="material-icons-round white" data-cy="toggle-sidebar-closed">navigate_before</span></Button>
      <Button v-if="!drawer" class="menu-toggle clarity-btn round grey-border" data-cy="toggle-sidebar-open"
              @click="toggleDrawer"><span
          class="material-icons-round grey">navigate_next</span></Button>
      <!-- Mobile Menu Button -->
      <div v-if="showMobileMenu">
        <Button class="clarity-btn p-button-outlined round grey-border" icon="pi pi-bars" @click="toggle"
                v-if="!expandMobileMenu" data-cy="toggle-mobile-menu-open"/>
        <Button class="clarity-btn clarity-gradient-blue white round" icon="pi pi-times" @click="toggle"
                v-if="expandMobileMenu" data-cy="toggle-mobile-menu-closed"/>
      </div>

      <!-- search -->
      <div class="search">
        <search-input></search-input>
      </div>
      <search-results-modal v-show="showModal === 'loadSearchResults'"></search-results-modal>

      <!-- Add funds -->
      <div class="nav-action-buttons" data-cy="nav-header-action-buttons">
        <Button label="Invest Now" class="clarity-gradient-orange clarity-btn"
                @click="$router.push('/investments/add/');"/>
      </div>
    </div>
    <div class="cluster-right">
      <!-- notification buttons -->
      <div class="notification-buttons" data-cy="nav-header-notifications">
        <!-- FOR MOBILE VIEW ONLY  -->
        <Button class="mobile-search-button p-button-text grey" v-if="showMobileMenu" @click="openSearch">
          <span class="material-icons-round grey">search</span>
        </Button>
        <separator-v class="flex separator" v-if="showMobileMenu"/>
        <Button class="notification-btn" @click="toggleNotificationPanel">
            <span v-if="hasNotifications === true" class="material-icons-round light-blue"
                  data-cy="nav-header-notifications-notifications"
                  v-badge.danger>notifications</span>
          <span v-else class="material-icons-round grey">notifications</span>
          <div class="button-label" :class="[ hasNotifications === true ? 'light-blue' : '']" data-cy="nav-header-notifications-notifications">Notifications</div>
        </Button>
        <separator-v class="flex separator mx-2"/>
        <Button class="notification-btn" @click="toggleMessagePanel">
          <span v-if="messages >= 1" class="material-icons-round light-blue" v-badge.danger>mail</span>
          <span v-else class="material-icons-round grey">mail</span>
          <div class="button-label" :class="[ messages >= 1 ? 'light-blue' : '']"
               data-cy="nav-header-notifications-messages">Messages
          </div>
        </Button>
      </div>
      <!-- Profile Card -->
      <ProfileCard/>
    </div>
  </div>
  <top-bar-messages-card/>
  <top-bar-notifications-card/>
</template>
<script>
import {useStore} from "vuex";
import {computed, inject, onBeforeUnmount, ref} from "vue";
import ProfileCard from '@/components/nav/ProfileCard.vue'
import SeparatorV from '@/components/common/layout/SeparatorV.vue'
import TopBarMessagesCard from "@/components/common/TopBarSecureMessagesNotificationCard";
import TopBarNotificationsCard from "@/components/common/TopBarSystemNotificationsCard";
import SearchResultsModal from "../modals/SearchResultsModal";
import SearchInput from "../common/SearchInput.vue";

export default {
  emits: ['toggleDrawer'],
  props: {
    drawer: {
      type: Boolean
    },
    groupBar: {
      type: Boolean
    },
    showMobileMenu: {
      type: Boolean
    }
  },
  setup(props, context) {
    const store = useStore()
    const bus = inject('bus')
    const expandMobileMenu = ref(false)


    const toggleDrawer = () => {
      context.emit('toggleDrawer')
    }

    const toggle = () => {
      bus.emit('toggleMobileMenu')
      expandMobileMenu.value = !expandMobileMenu.value
    };

    bus.on('tablet', () => {
      expandMobileMenu.value = false
    })

    const toggleMessagePanel = (event) => {
      bus.emit('showMessageNotification', event)
      store.commit('setNewMessagesLoadingState', true)
      store.dispatch('loadNewMessages')
    }

    const toggleNotificationPanel = (event) => {
      bus.emit('showSystemNotification', event)
      store.dispatch('getIMSApprovalRecords')
    }

    const notifications = computed(() => store.getters.getNewNotifications)
    const pendingApprovals = computed(() => store.getters.getApprovalRecords)

    const targetUUID = computed(() => store.getters.targetUUID)

    const resetTarget = () => {
        store.dispatch('setTargetUUID',false);
        window.location = window.location.href.split("?")[0];
    }

    const hasNotifications = computed(() => {
      // @TODO: this may need refactoring to switch or other computed prop on checking for open banking or news updates
      return !!(notifications.value > 0 || pendingApprovals.value && pendingApprovals.value.length)
    })

    const showModal = computed(() => store.getters.currentActiveModal);

    onBeforeUnmount(() => {
        bus.off('tablet')
    });

    const openSearch = () => {
        store.dispatch('setActiveModal', {modal: 'loadSearchResults'});
    }

    return {
      toggleDrawer,
      hasNotifications,
      messages: computed(() => store.getters.getNewMessages),
      toggle,
      toggleMessagePanel,
      toggleNotificationPanel,
      expandMobileMenu,
      targetUUID,
      showModal,
      resetTarget,
      openSearch
    }
  },
  components: {
    ProfileCard,
    SeparatorV,
    TopBarMessagesCard,
    TopBarNotificationsCard,
    SearchResultsModal,
    SearchInput
  }
}
</script>
<style scoped lang="scss">
#header {
  position: sticky;
  top: 0;
  z-index: 1002;
}

#barHeader {
  position: sticky;
  top: 50px;
  z-index: 1002;
}

.separator {
  border-color: var(--clarity-light-grey);
  height: 30px;

  @include mob-md {
    margin-right: 10px;
    padding-left: 10px;
  }
}

i.pi :deep(.p-badge) {
  min-width: 0.5rem;
  height: 0.5rem;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.targetuuid {
  position: sticky;
  top: 0px;
  line-height: 30px;
  height: 30px;
  text-align: center;
  z-index: 50000;
  background: var(--clarity-dark-grey);
  color: var(--clarity-pure-white);
}

.cluster-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 50%;

  @include mob {
    min-width: unset;
  }

  .menu-toggle {

    &.grey-border {
      border: 1px solid var(--clarity-light-grey);
      background: transparent;
    }

    &.grey-border:hover {
      border: 1px solid var(--clarity-grey);

      span {
        color: var(--clarity-blue) !important;
      }
    }

    @include mob {
      display: none;
    }
  }

  .search {
    display: flex;
    min-width: 40%;

    .cl-input {
      width: 100%
    }

    @include mob {
      display: none;
    }
  }

  .nav-action-buttons {
    white-space: nowrap;

    button {
      padding: 15px 45px;
    }

    @include mob-tablet {
      button {
        padding: 10px 30px;
        min-width: fit-content;
      }
    }
    @include mob {
      display: none;
    }
  }
}

.cluster-right {
  display: flex;

  .notification-buttons {
    display: flex;
    align-items: center;
    padding: 10px;

    .mobile-search-button,
    .mobile-search-button-separator {
      display: none;
    }

    button {
      padding: 5px;
      font-size: 16px;
      background: transparent;
      color: var(--clarity-grey);
      border: none;

      &:hover {
        color: var(--clarity-blue);
      }
    }

    .notification-btn {
      .span {
        color: var(--clarity-grey);
      }

      &:hover {
        span {
          color: var(--clarity-blue);
        }

        .button-label {
          color: var(--clarity-blue);
        }
      }

      &:focus {
        box-shadow: none;
      }
    }

    @include mob-md {
      .button-label {
        display: none;
      }
    }

    @include mob {
      .mobile-search-button,
      .mobile-search-button-separator {
        display: flex;
      }
    }
  }
}
</style>
