import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default {
    state() {
        return {
            isAuthenticated: false,
            cognitoUser: false,
            preferredMFA: '',
            targetUUID: false,
            newUserEmail: '',
            userStageOneData: null,
            userStageTwoData: null,
            userStageThreeData: null,
            userDidAutoLogout: false,
        }
    },
    mutations,
    actions,
    getters
}
