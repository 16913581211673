import api from "@/core/services/api";
import {toDrupalRequest} from "@/core/helpers";

export default {
    async getPreferences({commit, dispatch}) {
        const endpoint = '/clientdirectory/marketinginfo'
        const response = await api.get(`${toDrupalRequest(endpoint)}`)

        if (response) {
            commit('setPreferences', response)
            dispatch('setAdviser')
        }
    },
    async getWebSettings({commit}) {
        const endpoint = '/clientdirectory/websitesettings'
        const response = await api.get(`${toDrupalRequest(endpoint)}`)

        if (response) {
            commit('setPreferences', response);
            //console.log('response is ', response)
            if(response['defaultCurrency']){
                //console.log('updating default currency!', response['defaultCurrency']);
                commit('setCurrencySelected', response['defaultCurrency']);
            }
        }
    },
    async getPersonalDetails({commit}) {
        const endpoint = '/clientdirectory/clientpersonaldetails/summary'
        const response = await api.get(`${toDrupalRequest(endpoint)}`)
        //console.log(response);
        if (response) {
            commit('setPreferences', response)
        }
    },
    async updateSettings({commit, dispatch}, payload) {

        if(process.env.VUE_APP_DELAY_BACKEND_API) {
            await new Promise(r => setTimeout(r, 2000));
        }

        let endpoint, response;
        switch (payload.type) {
            case 'personal':
                endpoint = '/clientdirectory/clientpersonaldetails/summary'

                response = await api.patch(
                    `${toDrupalRequest(endpoint)}`,
                    JSON.stringify(payload.payload),
                    {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                )
                if (response) {
                    commit('setPreferences', payload.payload)
                    dispatch('addNotification',{
                        severity: 'success',
                        summary: payload.title,
                        detail: `Your changes have been saved.`,
                        life: 4000
                    })
                }else{
                    dispatch('addNotification', {severity: 'error',
                        summary: 'Failed to save changes',
                        detail: 'An unexpected error occurred, changes to your '+payload.title+' have not been saved.'
                    })
                }
                dispatch('setLoading', {id: payload.loading, state: false})
                break;
            case 'settings':
                endpoint = '/clientdirectory/websitesettings'
                response = await api.patch(
                    `${toDrupalRequest(endpoint)}`,
                    JSON.stringify(payload.payload),
                    {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                )
                if (response) {
                    commit('setPreferences', payload.payload)
                    if(payload.title !== 'MFA Options') {
                        dispatch('addNotification',{
                            severity: 'success',
                            summary: payload.title,
                            detail: `Your changes have been saved.`,
                            life: 4000
                        })
                    }
                }else{
                    dispatch('addNotification', {severity: 'error',
                        summary: 'Failed to save changes',
                        detail: 'An unexpected error occurred, changes to your '+payload.title+' have not been saved.'
                    })
                }
                dispatch('setLoading', {id: payload.loading, state: false})
                break;
        }

    },
    async getCountryOptions() {
        const endpoint = '/clientdirectory/countryoptions'
        const response = await api.get(`${toDrupalRequest(endpoint)}`)
        if (response) {
            return response
        }
    },
    async getGroupOptions() {
        const endpoint = '/clientdirectory/groupoptions'
        const response = await api.get(`${toDrupalRequest(endpoint)}`)
        if (response) {
            return response
        }
    },
    async getImsSendMethodOption({commit}) {
        //console.log('get current IMS Send Method');
        const endpoint = '/ims/sendmethod';
        const response = await api.get(`${toDrupalRequest(endpoint)}`);

        if(response) {
            //console.log(response);
            commit('setPreferences', response);
        }
    },
    async setImsSendMethodOption({commit, dispatch}, payload) {
        //console.log('update IMS Send Method');
        //console.log(payload);

        if(process.env.VUE_APP_DELAY_BACKEND_API) {
            await new Promise(r => setTimeout(r, 2000));
        }

        const endpoint = '/ims/sendmethod';
        const response = await api.patch(
            `${toDrupalRequest(endpoint)}`,
            JSON.stringify(payload.payload),
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        )
        if (response) {
            commit('setPreferences',payload.payload);
            dispatch('addNotification',{
                severity: 'success',
                summary: payload.title,
                detail: `Your changes have been saved.`,
                life: 4000
            });
        } else {
            dispatch('addNotification', {severity: 'error',
                summary: 'Failed to save changes',
                detail: 'An unexpected error occurred, changes to your '+payload.title+' have not been saved.'
            });
        }
    },
    async openNewMessage({commit, state}) {
        commit('setPreferences', {
            "newSecureMessage": state.newSecureMessage-1
        });
    },
    async updateElementVisibility({commit, state }, {key, value}) {

        const currentVisibility = state.elementVisibility ? state.elementVisibility : {};
        currentVisibility[key] = value;
        commit('setPreferences', { "elementVisibility": JSON.stringify(currentVisibility) })

        if(process.env.VUE_APP_DELAY_BACKEND_API) {
            await new Promise(r => setTimeout(r, 2000));
        }

        let endpoint;
        endpoint = '/clientdirectory/websitesettings'
        await api.patch(
            `${toDrupalRequest(endpoint)}`,
            JSON.stringify({ "elementVisibility": JSON.stringify(currentVisibility) }),
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        )

    },
}
