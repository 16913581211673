import { Amplify, Auth } from 'aws-amplify'
import { Promise } from 'core-js';
import config from '../../../aws-exports'

Amplify.configure({ ...config, "authenticationFlowType": 'USER_PASSWORD_AUTH' });

const domain = process.env.VUE_APP_COOKIE_DOMAIN;

if(domain && window.location.href.indexOf(domain) > -1) {
    Amplify.configure({
        Auth: {
            cookieStorage: {
                domain: domain,
                secure: false,
            },
        }
    });
}

export default {
    async login ({ commit }, {email, password}) {
        /**
         * The authenticationFlowType must be changed back to USER_SRP_AUTH
         * in the AWS console and this below code deleted once all users
         * have been migrated
         */
        Auth.configure({
          authenticationFlowType: 'USER_PASSWORD_AUTH'
        });
        const cognitoUser = await Auth.signIn(email,password);
        //console.log(cognitoUser);
        if(cognitoUser.challengeName == 'SOFTWARE_TOKEN_MFA') {
          const response = {code:-1,value:cognitoUser};
          return response;
        } else {
          commit('setCognitoUser', cognitoUser);
          return cognitoUser;
        }
    },
    async validateUserMFA({ commit }, {mfaCode,cognitoUser}) {
        try {
          const mfaPassedUser = await Auth.confirmSignIn(cognitoUser,mfaCode,'SOFTWARE_TOKEN_MFA');
          //console.log(mfaPassedUser);
          commit('setCognitoUser', mfaPassedUser);
        } catch (error) {
          //console.log(error);
          return error;
        }
    },
    async signOut({ commit }) {
        try {
            await Auth.signOut();
            commit('resetCognitoUser');
            window.location.href = '/login';
            const host = window.location.hostname;
            let domain = '';
            if(host.includes("clarityglobal")) {
              domain = host.slice(-17);
            } else {
              domain = host;
            }
            document.cookie = "rd_authenticated=false; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain="+domain+";";
            return true
        } catch (error) {
          console.log('error signing out: ', error)
          return false
        }
    },
    async checkUserAuth( { commit } ) {
        try {
          const cognitoUser = await Auth.currentAuthenticatedUser();
          commit('setCognitoUser', cognitoUser);
          return Promise.resolve(cognitoUser);
        } catch {
          commit('setCognitoUser',false);
          return Promise.resolve(false);
        }
    },
    async storeNewUserEmail( { commit }, {email}) {
      commit('setNewUserEmail', email);
      return true;
    },
    async checkUserMFA( { commit} ) {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      try {
        const mfaValue = await Auth.getPreferredMFA(cognitoUser, {bypassCache: true});
        console.log(mfaValue);
        commit('setPreferredMFA',mfaValue);
        return mfaValue;
      } catch (error) {
        console.log('error:'+ error);
      }
    },
    async setupUserMFA({commit},{code}) {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      try {
        const mfaSetup = await Auth.verifyTotpToken(cognitoUser,code);
        if(!mfaSetup){
          console.log('Error: '+mfaSetup);
        }
        const mfaValue = await Auth.setPreferredMFA(cognitoUser,'TOTP');
        commit('setPreferredMFA',mfaValue);
        return mfaValue;
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    async updateUserMFA({commit},{type}) {
      console.log(type);
      const cognitoUser = await Auth.currentAuthenticatedUser();
      try {
        const mfaValue = await Auth.setPreferredMFA(cognitoUser,type);
        commit('setPreferredMFA',mfaValue);
      } catch (error) {
        console.log(error);
      }
    },
    async registrationStoreStageOne({commit},{data}) {
      commit('newUserStageOneData',data);
      return true;
    },
    async registrationStoreStageTwo({commit},{data}) {
      commit('newUserStageTwoData',data);
      return true;
    },
    async registrationStoreStageThree({commit},{data}) {
      commit('newUserStageThreeData',data);
      return true;
    },
    // async signupUser({commit}) {
    //   //commit('newUserID');
    //   return true;
    // }
    setTargetUUID({ commit }, uuid) {
        if(process.env.VUE_APP_TARGET_OVERRIDE === 'true') {
            commit('setTargetUUID', uuid)
        }
    },
    autoLogout({ commit }) {
      commit('didAutoLogout');
    },
    resetAutoLogout({ commit }) {
      commit('resetAutoLogout');
    }
}
