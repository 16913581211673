import * as helpers from './helpers'
export default {
    setAssetAllocation(state, payload) {
        // need to sort total allocation by key
        state.totalAllocation = helpers.sortDataForDisplay(payload.totalAllocation)
        state.assetAllocation = payload
    },

    setAllocationSummary (state, payload) {
        const benchmark = payload.benchmark ?? null
        const current = payload.totalAllocation.all

        const allocations = [
            {
                'allocation': 'current',
                'cash': parseFloat(current.cash_percent).toFixed(2),
                'fixed': parseFloat(current.fixed_interest_percent).toFixed(2),
                'equity': parseFloat(current.equity_percent).toFixed(2),
                'property': parseFloat(current.property_percent).toFixed(2)
            }
        ]
        if(benchmark) {
            allocations.push(
            {
                'allocation':'benchmark',
                'cash': parseFloat(benchmark.cash).toFixed(2),
                'fixed': parseFloat(benchmark.fixed_interest).toFixed(2),
                'equity': parseFloat(benchmark.equity).toFixed(2),
                'property' : parseFloat(benchmark.property).toFixed(2)
            })

            if (!helpers.totalChecker(allocations[0]) || !helpers.totalChecker(allocations[1])) {
                // Need so sort of error catch/logging
                console.error('Allocations do not tally 100')
            }

            allocations.push(helpers.getVariance(allocations[0], allocations[1]))
        }

        state.allocationSummary = allocations
    },

    setEquityBreakdown (state, payload) {
        const benchmark = payload.benchmark ?? null
        const current = payload.totalAllocation.all
        const currentEquity = helpers.getEquity(current, '_equity_percent')

        // need to make keys match
        for (const [key, val] of Object.entries(currentEquity['equity'])) {
            currentEquity['equity'][key.replace('_percent', '')] = val
            delete currentEquity['equity'][key]
        }

        state.equityBreakdown = []
        state.equityBreakdown.push({'allocation': 'current', ...currentEquity['equity']})

        if (benchmark) {
            const benchmarkEquity = helpers.getEquity(benchmark, '_equity')
            state.equityBreakdown.push(
                {'allocation': 'benchmark', ...benchmarkEquity['equity']},
                helpers.getVariance(currentEquity['equity'], benchmarkEquity['equity'])
            )
        }
    },

    setFullAllocation (state, payload) {
        const policies = payload.policyArray
        const sortOrder = ['pens', 'nonpens']
        const flattened = []
        const fundTotals = []
        const missingFunds = []

        for(let order of sortOrder){
            for (let policy in policies) {
                let pol = policies[policy]
                pol.id = policy;

                if(pol.type.toLowerCase() == order){
                    // iterate through funds
                    // add policy as common key to group
                    let newFunds = []
                    for (let fund in pol.funds) {
                        if(pol.funds[fund].allocation != false) {
                            newFunds.push({...pol.funds[fund], 'policy' : pol['name']})
                            // need to get fund totals for collapsed view on asset allocation table
                            fundTotals[pol.name] ? fundTotals[pol.name].push(pol.funds[fund]) : fundTotals[pol.name] = [pol.funds[fund]]
                        } else {
                            missingFunds.push([pol.name, pol.funds[fund]])
                        }
                    }
                    pol.funds = newFunds
                    const totals = helpers.calculatePolicyTotalsForCollapsedView(fundTotals)
                    pol.totals = totals[pol.name]
                    flattened.push(pol)
                }
            }
        }
        flattened.push({
            'type': 'Totals',
            'totalAllocation': helpers.sortDataForDisplay(payload.totalAllocation, false),
            'benchmark': payload.benchmark
        })
        state.missingFunds = missingFunds
        state.fullAllocation = flattened
    },

    setAssetAllocationTableTotals (state, payload) {
        let assets = []
        let equity = []
        helpers.sortDataForDisplay(payload.totalAllocation)
        for(let type in payload.totalAllocation) {
            // Assets = get percents for cash, fixed interest, equity and property
            assets.push({
                'type': type,
                'cash_percent' : payload.totalAllocation[type].cash_percent,
                'fixed_interest_percent' : payload.totalAllocation[type].fixed_interest_percent,
                'equity_percent' : payload.totalAllocation[type].equity_percent,
                'property_percent' : payload.totalAllocation[type].property_percent
            })
            // Equity = regional
            equity.push({
                'type': type,
                "uk_equity_percent": payload.totalAllocation[type].uk_equity_percent,
                "us_equity_percent": payload.totalAllocation[type].us_equity_percent,
                "european_equity_percent": payload.totalAllocation[type].european_equity_percent,
                "fareast_equity_percent": payload.totalAllocation[type].fareast_equity_percent,
                "japan_equity_percent": payload.totalAllocation[type].japan_equity_percent,
                "other_equity_percent": payload.totalAllocation[type].other_equity_percent,
                "specialist_equity_percent": payload.totalAllocation[type].specialist_equity_percent
            })
        }
        // add the benchmark data
        assets.push({
            'type': 'benchmark',
            'cash_percent' : payload.benchmark.cash,
            'fixed_interest_percent' : payload.benchmark.fixed_interest,
            'equity_percent' : payload.benchmark.equity,
            'property_percent' : payload.benchmark.property
        })
        equity.push({
            'type': 'benchmark',
            "uk_equity_percent": payload.benchmark.uk_equity,
            "us_equity_percent": payload.benchmark.us_equity,
            "european_equity_percent": payload.benchmark.european_equity,
            "fareast_equity_percent": payload.benchmark.fareast_equity,
            "japan_equity_percent": payload.benchmark.japan_equity,
            "other_equity_percent": payload.benchmark.other_equity,
            "specialist_equity_percent": payload.benchmark.specialist_equity
        })
        // state.assetAllocationTotals = {'assets' : assets, 'equity' : equity}
        state.assetAllocationTotals = [assets, equity]
    }
}
